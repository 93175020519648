import {apolloClient} from '@/vue-apollo'
import allBookingSettings from "../graphql/allBookingSettings.gql";
import createBookingSettings from "../graphql/createBookingSettings.gql";
import Vue from "vue";
import updateBookingSettings from "../graphql/updateBookingSettings.gql";

export default {
    state: {
        BookingSettings:[]
    },
    mutations: {
        AddBookingSettings(state,data){
            if(state.BookingSettings.find(({unique_id}) => unique_id===data.unique_id)){
                state.BookingSettings.find(({unique_id}) => unique_id===data.unique_id).max_book_time=data.max_book_time
                state.BookingSettings.find(({unique_id}) => unique_id===data.unique_id).max_cancel_time=data.max_cancel_time
            }else{
                state.BookingSettings.push(data)
            }

        },
        clearBookingSettings(state){
            state.BookingSettings=[]
        }
    },
    actions: {
        async LoadBookingSettings(context){
            context.commit('clearBookingSettings')

            apolloClient.query({
                fetchPolicy:"no-cache",
                query:allBookingSettings,
            }).then((response)=>{
                response.data.allBookingSettings.edges.forEach(element=>{
                    let data={
                        'max_book_time':element.node.settingsMaximumBookingTime,
                        'max_cancel_time':element.node.settingsMaximumCancelationTime,
                        'unique_id':element.node.settingsUniqueId
                    }
                    context.commit('AddBookingSettings',data)
                })
                console.log(response.data.allBookingSettings.edges)
            })
        },
        async addBookingSetting(context,data){
            apolloClient.mutate({
                mutation:createBookingSettings,
                variables:data
            }).then((response)=>{
                let bookingSettings={
                    'max_book_time':response.data.createBookingSettings.settings.settingsMaximumBookingTime,
                    'max_cancel_time':response.data.createBookingSettings.settings.settingsMaximumCancelationTime,
                    'unique_id':response.data.createBookingSettings.settings.settingsUniqueId
                }
                context.commit('AddBookingSettings',bookingSettings)
                if(response.data){
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Booking Setting Added Successfully!',
                        timerProgressBar:true,
                        timer:10000,
                        icon:'success',
                        showConfirmButton:false,
                        animation:false,
                    })
                }else{
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Oops!, Looks like an error occured',
                        timerProgressBar:true,
                        timer:10000,
                        icon:'success',
                        showConfirmButton:false,
                        animation:false,
                    })
                }
            })
        },
        async EditBookingSetting(context,data){
            apolloClient.mutate({
                mutation:updateBookingSettings,
                variables:data
            }).then((response)=>{
                let bookingSettings={
                    'max_book_time':response.data.updateBookingSettings.settings.settingsMaximumBookingTime,
                    'max_cancel_time':response.data.updateBookingSettings.settings.settingsMaximumCancelationTime,
                    'unique_id':response.data.updateBookingSettings.settings.settingsUniqueId
                }
                context.commit('AddBookingSettings',bookingSettings)
                if(response.data){
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Booking Setting Updated Successfully!',
                        timerProgressBar:true,
                        timer:10000,
                        icon:'success',
                        showConfirmButton:false,
                        animation:false,
                    })
                }else{
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Oops!, Looks like an error occured',
                        timerProgressBar:true,
                        timer:10000,
                        icon:'success',
                        showConfirmButton:false,
                        animation:false,
                    })
                }
            })
        }
    }
}