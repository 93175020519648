<template>
  <v-layout fill-height>
    <v-row
      v-if="noBooking"
      class="d-flex justify-center align-center"
      style="width: 100%"
    >
      <input
        id="bookingNumberField"
        ref="bookingNumberField"
        v-model="bookingNumber"
        class="input is-medium is-rounded"
        hidden
        readonly
        type="text"
        v-on:focus="$event.target.select()"
      />
      <v-col class="d-flex justify-center" cols="12" xs="12">
        <v-card class="pa-5 rounded-lg" max-width="800">
          <v-card-title>
            <div class="text-h6">Maelekezo kwa Mtahiniwa</div>
          </v-card-title>
          <v-card-text>
            <ol>
              <li v-for="maelezo in maelezoKwaMtahiniwa" :key="maelezo">
                {{ maelezo }}
              </li>
            </ol>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col class="d-flex justify-content-center" cols="12" sm="12">
        <v-spacer></v-spacer>
        <v-btn
          v-if="verificationIsAllowed && !examEnded && !isVerified"
          class="mx-2 rounded-lg"
          color="success"
          dark
          @click="startExam"
        >
          Fanya Uthibitisho
        </v-btn>

        <v-spacer></v-spacer>
      </v-col>

      <v-col cols="12" sm="12">
        <v-dialog
          v-model="examinationDialogue"
          :scrollable="scrollable"
          fullscreen
          hide-overlay
          persistent
          transition="examinationDialogue-bottom-transition"
        >
          <v-card>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-toolbar
                    color="rgba(39, 166, 245, 0.53)"
                    dark
                    sticky
                    style="
                      position: fixed;
                      z-index: 9999;
                      width: 100%;
                      left: 0;
                      top: 0;
                    "
                  >
                    <v-icon left>mdi-note-edit</v-icon>
                    <v-toolbar-title>{{ examinationTitle }}</v-toolbar-title>
                    <v-spacer></v-spacer>
           

                    <v-toolbar-items>
                      <div
                        class="text-center d-flex align-items-vertically-center h-100 p-0"
                        color="primary"
                      >
                        <v-btn
                          class="h-100 text-capitalize rounded-lg mx-2"
                          color="success"
                          dark
                          large
                          @click="areYouSure = true"
                        >
                          Wasilisha Mtihani
                        </v-btn>
                      </div>
                    </v-toolbar-items>
                  </v-toolbar>
                </v-col>
              </v-row>
              <v-row style="margin-top: 50px">
                <v-col cols="12" md="3">
                  <CandidateExaminationStatistics
                    :ExaminationLoaded="examinationLoaded"
                    :StatisticsValues="statisticsValues"
                    @requestPermission="requestPermission"
                  />
                </v-col>
                <v-col cols="12" md="9">
                  <v-card class="pa-2" outlined tile>
                    <v-row>
                      <v-col cols="12">
                        <v-list>
                          <v-list-item
                            v-for="(examSection, index) in examSections"
                            :key="index"
                          >
                            <v-row>
                              <v-col
                                cols="12"
                                style="background: rgba(255, 204, 0, 0.3)"
                              >
                                <h1>
                                  Section {{ examSection.sectionName }}:
                                  {{ examSection.questionCategoryType }}
                                </h1>
                              </v-col>
                              <v-col cols="12">
                                <ol>
                                  <li
                                    v-for="(
                                      questionsAndAnswer, indexQuestion
                                    ) in examSection.allQuestionsAndAnswers"
                                    :id="`section-${examSection.primaryKey}-question-${questionsAndAnswer.primaryKey}`"
                                    :key="indexQuestion"
                                  >
                                    <b>{{ questionsAndAnswer.question }}</b>
                                    <p>
                                      <small
                                        style="
                                          color: rgba(48, 67, 194, 0.76);
                                          font-weight: normal;
                                        "
                                        >Jibu moja tu</small
                                      >
                                    </p>
                                    <v-radio-group
                                      class="px-3 pt-0 pb-0"
                                      column
                                      @change="
                                        sendAnswer(
                                          examSection,
                                          questionsAndAnswer,
                                          $event
                                        )
                                      "
                                    >
                                      <v-row
                                        v-if="
                                          questionsAndAnswer.attachments
                                            .length > 0
                                        "
                                      >
                                        <span
                                          v-for="attachment in questionsAndAnswer.attachments"
                                          :key="attachment"
                                          class="ml-4 mb-4"
                                        >
                                          <v-img
                                            :src="attachment"
                                            max-height="100px"
                                            max-width="100px"
                                            style="border-radius: 10px"
                                          ></v-img>
                                        </span>
                                      </v-row>
                                      <v-radio
                                        v-for="answerOptions in questionsAndAnswer.answerOptions"
                                        :key="answerOptions.primaryKey"
                                        :label="answerOptions.answer"
                                        :value="answerOptions"
                                      ></v-radio>
                                      <v-row>
                                        <v-col
                                          class="d-flex justify-end"
                                          cols="12"
                                        >
                                          <v-checkbox
                                            class="mt-0"
                                            color="warning"
                                            hide-details
                                            label="Rudia Baadaye"
                                            @change="
                                              markOrUnMarkQuestionForReview(
                                                examSection,
                                                questionsAndAnswer,
                                                $event
                                              )
                                            "
                                          ></v-checkbox>
                                        </v-col>
                                        <v-col>
                                          <VDivider></VDivider>
                                        </v-col>
                                      </v-row>
                                    </v-radio-group>
                                  </li>
                                </ol>
                              </v-col>
                              <v-col cols="12">
                                <span v-if="examSections.length === index + 1"
                                  ><v-btn
                                    class="mt-5 rounded-lg text-capitalize mr-1"
                                    color="primary"
                                    @click="scrollToTop"
                                  >
                                    <v-icon>mdi-arrow-left-circle</v-icon>
                                    &nbsp;Pitia Mtihani Kuanzia Mwanzo &nbsp;
                                  </v-btn>
                                  <v-btn
                                    class="mt-5 rounded-lg text-capitalize ml-1"
                                    color="success"
                                    @click="areYouSure = true"
                                  >
                                    Wasilisha Mtihani &nbsp;<v-icon
                                      >mdi-arrow-right-circle</v-icon
                                    >
                                  </v-btn></span
                                >
                              </v-col>
                            </v-row>
                          </v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
        <v-overlay :absolute="absolute" :value="overlay" opacity="0.95">
          <v-card class="white pa-5" max-width="500">
            <v-card-text>
              <p style="color: black">
                Umeruhusiwa kutoka nje ya chumba cha Mtihani
              </p>
              <p style="color: black">
                Kama Umerudi bonye kitufe kilicho andikwa Nimerudi ili uruhusiwe
                kuendelea na mtihani
              </p>
            </v-card-text>
            <v-card-actions class="w-100 d-flex justify-center">
              <v-btn
                color="success"
                @click="
                  overlay = false;
                  examinationDialogue = true;
                "
              >
                Nimerudi
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-overlay>
        <v-row>
          <template>
            <div class="text-center">
              <v-snackbar v-model="snackbar" :timeout="timeout">
                {{ text }}

                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
            </div>
          </template>
        </v-row>
        <v-row>
          <v-overlay
            :absolute="absolute"
            :value="overlayEnding"
            color="white"
            opacity="0.6"
            transition="dialog-bottom-transition"
            width="100%"
            z-index="9999"
          >
            <v-card
              class="pa-5 d-flex align-items-center"
              elevation="0"
              width="100%"

            >
              <v-row>
                <v-col cols="12">
                  <h3 class="w-100">Asante kwa kushiriki mtihani.</h3>
                  <div>
                    Matokeo ni kama yafuatavyo:- <br />
                    <span
                      >Alama Ulizopata:
                      <strong>{{ examination_results.resultsAttainedMarks }} %</strong></span
                    ><br />
                    <span v-if="examination_results.resultsAsPassed"
                      >Aina ya Ufaulu:
                      <strong style="color: limegreen">{{
                        examination_results.resultsType
                      }}</strong></span
                    >
                    <span v-else
                      >Aina ya Ufaulu: 
                      <strong style="color: pink">{{
                        examination_results.resultsType
                      }}</strong></span
                    >
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    link
                    to="/"
                    block
                    outlined
                    color="success"
                    small
                    class="text-capitalize"
                  >
                    <v-icon>mdi-arrow-left</v-icon> Go Back</v-btn
                  >
                </v-col>
              </v-row>
            </v-card>
          </v-overlay>
        </v-row>
        <v-row>
          <v-dialog
            v-model="areYouSure"
            max-width="600"
            transition="dialog-bottom-transition"
          >
            <v-card>
              <v-toolbar dense color="error" dark
                >Unakaribia Kuwasilisha Mtihani!</v-toolbar
              >
              <v-card-text class="pa-5">
                Ukiwasilisha hautaweza kubadilisha chochote kwenye mtihani wako,
                Je unataka kuwasilisha sasa?
              </v-card-text>
              <v-card-actions>
                <div class="d-flex justify-end w-100">
                  <v-btn
                    class="mx-2 rounded-lg text-capitalize"
                    color="error"
                    @click="areYouSure = false"
                  >
                    <v-icon small>mdi-close-circle</v-icon>
                    Hapana
                  </v-btn>
                  <v-btn
                    class="mx-2 rounded-lg text-capitalize"
                    color="success"
                    @click="submitExam"
                  >
                    <v-icon small>mdi-check-circle</v-icon>
                    Ndio Wasilisha
                  </v-btn>
                </div>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-else class="px-8" justify="center">
      <v-col lg="4" md="6" sm="12">
        <v-alert
          border="top"
          color="error accent-4"
          colored-border
          elevation="2"
          icon="mdi-alert-circle"
          prominent
        >
          <div class="text-h6">Samahani!, Hauna Mtihani kwa sasa</div>
          Inaonekana hauna mtihani kwa sasa. Tafadhali hakiki taarifa zako,
          kisha jaribu tena siku sahihi ya mtihani. Karibu sana.
        </v-alert>
      </v-col>
    </v-row>

    <v-row>
      <div class="col-md-3">
        <video
          id="local-video"
          autoplay="autoplay"
          hidden
          muted="muted"
        ></video>
      </div>
    </v-row>
  </v-layout>
</template>

<script>
// import Toolbar from './candidate_shared/navigations/Toolbar.vue'
import verifyExaminationBooking from "../../../graphql/CandidateBooking/verifyExaminationBooking.graphql";
import candidateSubmitAnswer from "../../../graphql/CandidateExamination/candidateSubmitAnswer.gql";
import candidateSubmitExamination from "../../../graphql/CandidateExamination/candidateSubmitExamination.gql";
import CandidateExaminationStatistics from "./CandidateExaminationStatistics";
import generateExamination from "../../../graphql/CandidateExamination/generateExamination.graphql";
import candidateSession from "../../../graphql/CandidateExamination/candidateSession.graphql";
import Vue from "vue";
import onDateStartedExamination from "../../../graphql/Examinations/onDateStartedExamination.graphql";
import ReconnectingWebSocket from "reconnecting-websocket";

import $ from "jquery";
import * as easings from "vuetify/lib/services/goto/easing-patterns";

export default {
  data() {
    return {
      examination_results: {},
      scrollable: false,
      absolute: true,
      overlay: false,
      overlayEnding: false,
      userOutside: true,
      examinationDialogue: false,
      areYouSure: false,
      statisticsValues: {
        totalQuestions: 0,
        totalAnsweredQuestions: 0,
        averageTimePerQuestion: 0,
        originalAverageTime: 0,
        progress: "Uko nyuma ya muda",
      },
      e6: 1,
      e1: 1,
      e7: 1,
      timerEnabled: false,
      timerCount: 0,
      snackbar: false,
      text: "The test has ended and has been submited, you will now be redirected to your Dashboard",
      timeout: 5000,
      examEnded: false,
      examinationLoaded: false,
      answeredQuestion: [],
      websocketPersonal: null,
      websocketVenue: null,
      roomName: null,
      examinationTitle: null,
      noBooking: false,
      bookingNumber: null,
      bookingUniqueId: null,
      venueUniqueId: null,
      examinationStage: null,
      countdownTimer: null,
      displayedTime: null,
      examinationDurationInSeconds: 0,
      studentHasStartedExam: false,
      studentHasFinishedExam: false,
      studentHasVerifiedExam: false,
      examSections: [],
      examHasStarted: false,
      examinationKey: null,
      timetableSessionKey: null,
      isVerified: false,
      tenMinuteWarning: false,
      oneMinuteWarning: false,
      verificationIsAllowed: false,
      maelezoKwaMtahiniwa: [],
      type: "number",
      number: 0,
      selector: "#first",
      selections: ["#first", "#second", "#third"],
      selected: "Button",
      elements: ["Button", "Radio group"],
      duration: 300,
      offset: 0,
      easing: "easeInOutCubic",
      easings: Object.keys(easings),
      roomSelectionContainer: false,
      videoChatContainer: false,
      localVideoComponent: false,
      remoteVideoComponents: [],
      streamIDs: [],
      mediaConstraints: {
        video: { width: 1280, height: 720 },
      },
      localStream: null,
      remoteStream: null,
      isRoomCreator: false,
      rtcPeerConnection: null,
      iceServers: {
        iceServers: [
          {
            urls: process.env.VUE_APP_TURN_URL,
            username: process.env.VUE_APP_TURN_USERNAME,
            credential: process.env.VUE_APP_TURN_PASSWORD,
          },
        ],
      },
    };
  },
  methods: {
    verfiyFingerPrint() {
      document.getElementById("bookingNumberField").hidden = false;
      this.$refs.bookingNumberField.focus();
      document.execCommand("copy");
      document.getElementById("bookingNumberField").hidden = true;
      this.websocketPersonal.send(
        JSON.stringify({
          booking_number: this.bookingNumber,
          authToken: JSON.parse(localStorage.getItem("latra-dts-token")),
          event: "requestVerification",
        })
      );
      window.open("latra-dts-fingerprint:");
      self.focus();
    },
    startExam() {
      let totalQuestions = 0;
      this.$apollo
        .mutate({
          mutation: generateExamination,
          variables: {
            bookingNumber: this.bookingNumber,
          },
        })
        .then((response) => {
          this.examinationTitle =
            response.data.generateExamination.examination[0].candidateExamSession.timetableExamination.examinationTitle;
          console.log(this.statisticsValues.originalAverageTime);
          this.examinationKey =
            response.data.generateExamination.examination.primaryKey;
          response.data.generateExamination.examination.forEach((section) => {
            let allQuestionsAndAnswers = [];
            section.candidateExamQuestionSection.edges.forEach(
              (questionElement) => {
                let answerOptions = [];
                questionElement.node.candidateExamQuestionAnswer.edges.forEach(
                  (answerElement) => {
                    answerOptions.push({
                      sectionAnswerKey: answerElement.node.primaryKey,
                      answer:
                        answerElement.node.candidateExamAnswer
                          .answerDescriptions,
                      primaryKey:
                        answerElement.node.candidateExamAnswer.primaryKey,
                    });
                  }
                );
                let questionAttachment = [];
                if (
                  questionElement.node.candidateExamQuestion.attachmentQuestion
                    .length > 0
                ) {
                  questionElement.node.candidateExamQuestion.attachmentQuestion.forEach(
                    (image) => {
                      questionAttachment.push(
                        process.env.VUE_APP_URL + image.attachmentPathName
                      );
                      // questionAttachment.push("https://osat.all.co.tz:9006"+image.node.attachmentPathName)
                      // questionAttachment.push("http://172.17.17.96:8000"+image.node.attachmentPathName)
                    }
                  );
                }
                let questionsAndAnswers;
                questionsAndAnswers = {
                  question:
                    questionElement.node.candidateExamQuestion
                      .questionDescriptions,
                  attachments: questionAttachment,
                  primaryKey:
                    questionElement.node.candidateExamQuestion.primaryKey,
                  answerOptions: answerOptions,
                };
                this.answeredQuestion.push({
                  questionIdentification:
                    questionElement.node.candidateExamQuestion.primaryKey,
                  isAnswered: false,
                });
                allQuestionsAndAnswers.push(questionsAndAnswers);
              }
            );
            totalQuestions += allQuestionsAndAnswers.length;

            let processedSection;
            processedSection = {
              sectionName: section.candidateExamSection.sectionName,
              questionCategoryType:
                section.candidateExamSectionCategory.categoryName,
              primaryKey: section.candidateExamSection.primaryKey,
              allQuestionsAndAnswers: allQuestionsAndAnswers,
            };
            this.examSections.push(processedSection);
          });
          this.examinationLoaded = true;
          this.statisticsValues.totalQuestions = totalQuestions;
          this.statisticsValues.originalAverageTime =
            (totalQuestions /
              response.data.generateExamination.examination[0]
                .candidateExamSession.timetableExamination.examinationSetting
                .settingDuration) *
            3600;

          // let seconds = 172801;
          this.countdownTimer = setInterval(() => {
            this.websocketPersonal.send(
              JSON.stringify({
                booking_number: this.bookingNumber,
                authToken: JSON.parse(localStorage.getItem("latra-dts-token")),
                event: "candidate_remaining_time",
              })
            );
          }, 2000);
          // console.log(this.examSections)
          this.examinationDialogue = true;
          this.examHasStarted = true;
        });

    },
    showVerificationBtn() {
      this.verificationIsAllowed = !this.verificationIsAllowed;
    },
    loadCandidateSession() {
      this.$apollo
        .query({
          query: candidateSession,
          variables: {
            bookingNumber: this.bookingNumber,
          },
        })
        .then((response) => {
          // console.log(response.data.candidateSession)
          this.studentHasStartedExam =
            response.data.candidateSession.candidateSessionIsStarted;
          this.studentHasFinishedExam =
            response.data.candidateSession.candidateSessionIsFinished;
          this.examEnded = this.studentHasFinishedExam;
          this.timetableSessionKey =
            response.data.candidateSession.candidateSessionTimetable.primaryKey;
          this.isVerified =
            response.data.candidateSession.candidateSessionIsVerified;
          if (this.isVerified) {
            this.maelezoKwaMtahiniwa = [];
            this.maelezoKwaMtahiniwa.push(
              "Alama zako za vidole zimethibitishwa Kikamilifu"
            );
            this.maelezoKwaMtahiniwa.push(
              "Subiria Msimamizi aruhusu uanzaji wa mtihani"
            );
            this.maelezoKwaMtahiniwa.push(
              "Angalizo: Msimamizi akianzisha mtihani muda wa kufanya utaanza kupungua"
            );
            if (this.examHasStarted) {
              this.maelezoKwaMtahiniwa = [];
              this.maelezoKwaMtahiniwa.push("Mtihani Umeanza.");
              this.maelezoKwaMtahiniwa.push(
                "Tafadhali subiri kidogo, pindi mtihani wako unaandaliwa."
              );
              this.examHasStarted = true;
              this.startExam();
            }
          }
        });
    },
    loadSessionDetails() {
      this.$apollo
        .query({
          query: onDateStartedExamination,
          variables: {
            venueUniqueId: this.venueUniqueId,
          },
        })
        .then((response) => {
          this.examHasStarted =
            response.data.onDateStartedExamination.onDateExaminationIsStarted;
          this.verificationIsAllowed =
            response.data.onDateStartedExamination.onDateExaminationIsVerificationAllowed;
          if (this.verificationIsAllowed) {
            this.maelezoKwaMtahiniwa = [];
            this.maelezoKwaMtahiniwa.push(
              "Sasa Unaweza ukaanza uthibitisho wa alama za vidole"
            );
            this.maelezoKwaMtahiniwa.push(
              "Bonyeza kitufe kilicho andikwa Fanya uthibitisho"
            );
          }
          // console.log(this.examHasStarted)
        });
    },
    async sendAnswer(section, questionAnswer, selectedAnswer) {
      this.answeredQuestion.find(
        ({ questionIdentification }) =>
          questionIdentification === questionAnswer.primaryKey
      ).isAnswered = true;
      let totalAnsweredQuestions = 0;
      this.answeredQuestion.forEach((question) => {
        if (question.isAnswered) {
          totalAnsweredQuestions += 1;
        }
      });
      this.statisticsValues.totalAnsweredQuestions = totalAnsweredQuestions;

      this.$apollo
        .mutate({
          mutation: candidateSubmitAnswer,
          variables: {
            input: {
              bookingNumber: this.bookingNumber,
              sectionAnswerKey: selectedAnswer.sectionAnswerKey,
              questionKey: questionAnswer.primaryKey,
              answerKey: selectedAnswer.primaryKey,
            },
          },
        })
        .then((response) => {
          if (response.data.candidateSubmitAnswer.response.status) {
            Vue.swal({
              toast: true,
              position: "top-right",
              title: "Answer saved",
              text: "Your answer has been successfully recorded. But, your free to change it.",
              timerProgressBar: true,
              timer: 3000,
              icon: "success",
              showConfirmButton: false,
              animation: false,
            });
          } else {
            Vue.swal({
              toast: true,
              position: "top-right",
              title: "Fail to save answer",
              text: "There where error occure during save your answer please try again or contact your administrator",
              timerProgressBar: true,
              timer: 4000,
              icon: "error",
              showConfirmButton: false,
              animation: false,
            });
          }
        });

      // this.websocketPersonal.send(
      //   JSON.stringify({
      //     authToken: JSON.parse(localStorage.getItem("latra-dts-token")),
      //     bookingNumber: this.bookingNumber,
      //     sectionAnswerKey: selectedAnswer.sectionAnswerKey,
      //     questionKey: questionAnswer.primaryKey,
      //     answerKey: selectedAnswer.primaryKey,
      //     event: "submitAnswer",
      //   })
      // );

      this.statisticsValues.averageTimePerQuestion =
        this.calculateAverageTimePerQuestion(this.examinationDurationInSeconds);
    },

    async submitExam() {
      this.websocketPersonal.send(
        JSON.stringify({
          bookingNumber: this.bookingNumber,
          timetableExamination: this.timetableSessionKey,
          authToken: JSON.parse(localStorage.getItem("latra-dts-token")),
          event: "submitExam",
        })
      );
      this.$apollo
        .mutate({
          mutation: candidateSubmitExamination,
          variables: {
            bookingNumber: this.bookingNumber,
            timetableExamination: this.timetableSessionKey,
          },
        })
        .then((response) => {
          if (response.data.candidateSubmitExamination.status) {
            this.areYouSure = false;
            this.examinationDialogue = false;
            this.overlayEnding = true;
            this.examination_results =
              response.data.candidateSubmitExamination.examinationResults;
          } else {
            Vue.swal({
              toast: true,
              position: "center",
              title: response.data.candidateSubmitExamination.code,
              text: "Imeshidikana kuwasilisha Mtihani wako kwa sasa,Tafadhali jaribu tena",
              timerProgressBar: true,
              timer: 15000,
              icon: "error",
              showConfirmButton: true,
              animation: false,
            });
          }
        });
    },
    requestPermission() {
      // this.areYouSure=false;
      this.websocketVenue.send(
        JSON.stringify({
          venue: this.venue,
          authToken: JSON.parse(localStorage.getItem("latra-dts-token")),
          timetableSessionKey: this.timetableSessionKey,
          bookingNumber: this.bookingNumber,
          event: "requestPermission",
        })
      );
    },
    scrollToTop() {
      console.log("scrolling to top");
      this.$vuetify.goTo(0);
    },
    timerCustom() {
      console.log(this.timerCount);
      let days = Math.floor(this.timerCount / 24 / 60 / 60);
      let hoursLeft = Math.floor(this.timerCount - days * 86400);
      let hours = Math.floor(hoursLeft / 3600);
      let minutesLeft = Math.floor(hoursLeft - hours * 3600);
      let minutes = Math.floor(minutesLeft / 60);
      let remainingSeconds = this.timerCount % 60;

      this.displayedTime =
        this.pad(hours) +
        ":" +
        this.pad(minutes) +
        ":" +
        this.pad(remainingSeconds);
      if (this.timerCount === 0) {
        clearInterval(this.countdownTimer);
        this.displayedTime = "Muda Umeisha";
      } else {
        this.timerCount--;
      }
    },
    calculateAverageTimePerQuestion(duration) {
      let durationPerQuestion =
        parseInt(duration) /
        (parseInt(this.statisticsValues.totalQuestions) -
          parseInt(this.statisticsValues.totalAnsweredQuestions));
      let days = Math.floor(durationPerQuestion / 24 / 60 / 60);
      let hoursLeft = Math.floor(durationPerQuestion - days * 86400);
      let hours = Math.floor(hoursLeft / 3600);
      let minutesLeft = Math.floor(hoursLeft - hours * 3600);
      let minutes = Math.floor(minutesLeft / 60);
      let progressIndicator =
        parseInt(durationPerQuestion) /
        parseInt(this.statisticsValues.originalAverageTime);

      let percentageOfLag = 0;
      percentageOfLag = progressIndicator * 100;

      if (percentageOfLag < 0.3) {
        this.statisticsValues.progress = "Uko nyuma ya muda";
      } else {
        this.statisticsValues.progress = "Nzuri";
      }

      if (parseInt(hours) !== 0) {
        if (parseInt(hours) >= 0)
          return (
            "Masaa " +
            this.pad(hours) +
            " na dakika " +
            parseInt(this.pad(minutes))
          );
        else
          return (
            "Saa " +
            this.pad(hours) +
            " na dakika " +
            parseInt(this.pad(minutes))
          );
      } else {
        return "Dakika " + parseInt(this.pad(minutes));
      }
    },
    pad(n) {
      return n < 10 ? "0" + n : n;
    },

    async startVideoCallWithOffer() {
      // SOCKET EVENT CALLBACKS =====================================================
      console.log("Socket event callback: start_call");

      // if (isRoomCreator) {
      this.rtcPeerConnection = new RTCPeerConnection(this.iceServers);
      this.addLocalTracks(this.rtcPeerConnection);
      this.rtcPeerConnection.ontrack = this.setRemoteStream;
      this.rtcPeerConnection.onicecandidate = this.sendIceCandidate;
      await this.createOffer(this.rtcPeerConnection);
      // }
    },
    async startAnswer(offer_contents) {
      console.log("Socket event callback: webrtc_offer");
      this.rtcPeerConnection = new RTCPeerConnection(this.iceServers);
      this.addLocalTracks(this.rtcPeerConnection);
      this.rtcPeerConnection.ontrack = this.setRemoteStream;
      this.rtcPeerConnection.onicecandidate = this.sendIceCandidate;
      await this.rtcPeerConnection.setRemoteDescription(
        new RTCSessionDescription(offer_contents)
      );
      await this.createAnswer(this.rtcPeerConnection);
    },
    startSetRemoteDescription(answer_contents) {
      console.log("Socket event callback: webrtc_answer");
      this.rtcPeerConnection.setRemoteDescription(
        new RTCSessionDescription(answer_contents)
      );
    },
    setRemoteCandidate(candidateData) {
      console.log("Socket event callback: webrtc_ice_candidate");

      // ICE candidate configuration.
      let candidate = new RTCIceCandidate({
        sdpMLineIndex: candidateData.sdpMLineIndex,
        candidate: candidateData.candidate,
      });
      this.rtcPeerConnection.addIceCandidate(candidate);
    },
    async setLocalStream(mediaConstraints) {
      this.localVideoComponent = document.getElementById("local-video");
      let stream;
      try {
        stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
      } catch (error) {
        console.error("Could not get user media", error);
      }
      this.localStream = stream;
      this.localVideoComponent.srcObject = stream;
    },
    addLocalTracks(rtcPeerConnection) {
      this.localStream.getTracks().forEach((track) => {
        rtcPeerConnection.addTrack(track, this.localStream);
      });
    },
    async createOffer(rtcPeerConnection) {
      let sessionDescription;
      try {
        sessionDescription = await rtcPeerConnection.createOffer();
        rtcPeerConnection.setLocalDescription(sessionDescription);
      } catch (error) {
        console.error(error);
      }

      let offer_data = JSON.stringify({
        event: "offerevent",
        offer_creator: 12,
        offer_receiver: this.venueUniqueId,
        offer_contents: sessionDescription,
        call_id: "2",
      });
      this.websocketWebRTC.send(offer_data);
    },
    async createAnswer(rtcPeerConnection) {
      let sessionDescription;
      try {
        sessionDescription = await rtcPeerConnection.createAnswer();
        await rtcPeerConnection.setLocalDescription(sessionDescription);
      } catch (error) {
        console.error(error);
      }

      let answer_data = JSON.stringify({
        event: "answerevent",
        answer_creator: this.venue,
        answer_receiver: "client1",
        answer_contents: sessionDescription,
        call_id: "2",
      });

      this.websocketWebRTC.send(answer_data);
    },
    setRemoteStream(event) {
      console.log(event.streams[0].id);
      // if(!this.streamIDs.find(element => element == event.streams[0].id)){
      //   // let colmdElement=document.createElement('div')
      //   // colmdElement.setAttribute('class','col-md-3')
      //   // let video = document.createElement('video')
      //   // video.setAttribute("class","remote-video")
      //   // video.setAttribute("autoplay","autoplay")
      //   // video.setAttribute('id',event.streams[0].id)

      //   console.log("Setting Stream")
      //   // video.srcObject=event.streams[0]
      //   // remoteVideoComponents.srcObject = event.streams[0]
      //   // remoteStream = event.stream
      //   // colmdElement.appendChild(video)
      //   this.streamIDs.push(event.streams[0].id)
      //   // document.getElementById("video-chat-container").children[0].appendChild(colmdElement)
      //   this.remoteVideoComponents.push(event.streams[0])
      // }
    },
    async sendIceCandidate(event) {
      if (event.candidate) {
        console.log("CANDIDATEEEeeeee111111111111111111");
        console.log(event.candidate);
        const candidate_data = JSON.stringify({
          event: "candidateevent",
          candidate_creator: this.venueUniqueId,
          candidate_receiver: this.venueUniqueId,
          candidate_contents: event.candidate,
          call_id: "2",
        });
        await this.websocketWebRTC.send(candidate_data);
      }
    },
    markOrUnMarkQuestionForReview(section, questionItem, value) {
      if (value) {
        document
          .getElementById(
            "section-" +
              section.primaryKey +
              "-question-" +
              questionItem.primaryKey
          )
          .classList.add("marked-for-review");
      } else {
        document
          .getElementById(
            "section-" +
              section.primaryKey +
              "-question-" +
              questionItem.primaryKey
          )
          .classList.remove("marked-for-review");
      }
    },
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount--;
        }, 1000);
      }
    },
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        } else {
          this.examinationDialogue = false;
          this.snackbar = true;
          if (!this.examEnded) {
            this.timerCount = 5;
          }
          this.examEnded = true;
        }

        if (this.examEnded && this.timerCount <= 0) {
          // this.submitExam()
        }
      },
      immediate: false, // This ensures the watcher is triggered upon creation
    },
    verificationIsAllowed: function () {
      // console.log(newValue)
    },
  },
  components: {
    CandidateExaminationStatistics,
  },
  computed: {
    target() {
      const value = this[this.type];
      if (!isNaN(value)) return Number(value);
      else return value;
    },
    options() {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing,
      };
    },
    element() {
      if (this.selected === "Button") return this.$refs.button;
      else if (this.selected === "Radio group") return this.$refs.radio;
      else return null;
    },
  },
  mounted() {
    $(window).focus(function () {
      //do something
      console.log("On the tab");
    });

    $(window).blur(function () {
      //do something
      console.log("Away");
    });
    // const ws_scheme = window.location.protocol === "https:" ? "wss" : "ws"
    this.maelezoKwaMtahiniwa.push(
      "Subiria Msimamizi wa mtihani aruhusu uthibitishaji wa vidole"
    );
    this.$apollo
      .query({
        fetchPolicy: "no-cache",
        query: verifyExaminationBooking,
      })
      .then((response) => {
        if (response.data.verifyExaminationBooking) {
          // console.log("Bookiing is available")
          let self = this;
          this.noBooking = true;
          this.bookingNumber =
            response.data.verifyExaminationBooking.bookingNumber;
          this.bookingUniqueId =
            response.data.verifyExaminationBooking.bookingUniqueId;
          this.venueUniqueId =
            response.data.verifyExaminationBooking.bookingExamination.timetableVenue.venuesUniqueId;
          // console.log(this.bookingUniqueId)
          this.websocketPersonal = new ReconnectingWebSocket(
            process.env.VUE_APP_WEBSOCKET_URL + this.bookingNumber + "/"
          );
          this.websocketVenue = new ReconnectingWebSocket(
            process.env.VUE_APP_WEBSOCKET_URL + this.venueUniqueId + "/"
          );
          this.websocketWebRTC = new ReconnectingWebSocket(
            process.env.VUE_APP_VIDEO_WEBSOCKET_URL + this.venueUniqueId + "/"
          );
          // this.websocketPersonal = new ReconnectingWebSocket(ws_scheme+"://latradts.mikutano.co.tz:8090/ws/exam/socket/"+this.bookingNumber+"/")
          // this.websocketVenue = new ReconnectingWebSocket(ws_scheme+"://latradts.mikutano.co.tz:8090/ws/exam/socket/"+this.venueUniqueId+"/")
          // this.websocketWebRTC = new ReconnectingWebSocket(ws_scheme+"://latradts.mikutano.co.tz:8090/ws/video_chat/"+this.venueUniqueId+"/")

          // this.websocketPersonal = new ReconnectingWebSocket(ws_scheme+"://172.17.17.116:8000/ws/exam/socket/"+this.bookingNumber+"/")
          // this.websocketVenue = new ReconnectingWebSocket(ws_scheme+"://172.17.17.116:8000/ws/exam/socket/"+this.venueUniqueId+"/")
          // this.websocketWebRTC = new ReconnectingWebSocket(ws_scheme+"://172.17.17.116:8000/ws/video_chat/"+this.venueUniqueId+"/")

          this.loadSessionDetails();
          this.loadCandidateSession();

          // console.log(this.websocketPersonal)
          // console.log(this.websocketVenue)
          this.websocketPersonal.onmessage = function (event) {
            const data = JSON.parse(event.data);
            // console.log(event);
            // console.log(data)
            if (data.event.event === "fingerprintVerification") {
              if (data.event.code === 9300) {
                self.maelezoKwaMtahiniwa = [];
                self.maelezoKwaMtahiniwa.push(
                  "Alama zako za vidole zimethibitishwa Kikamilifu"
                );
                self.maelezoKwaMtahiniwa.push(
                  "Subiria Msimamizi aruhusu uanzaji wa mtihani"
                );
                self.maelezoKwaMtahiniwa.push(
                  "Angalizo: Msimamizi akianzisha mtihani muda wa kufanya utaanza kupungua"
                );
                self.isVerified = true;
                if (self.examHasStarted) {
                  self.maelezoKwaMtahiniwa = [];
                  self.maelezoKwaMtahiniwa.push("Mtihani Umeanza.");
                  self.maelezoKwaMtahiniwa.push(
                    "Tafadhali subiri kidogo, pindi mtihani wako unaandaliwa."
                  );
                  self.examHasStarted = true;
                  self.startExam();
                }
                Vue.swal({
                  toast: true,
                  position: "center",
                  title: "Alama zako za vidole zimethibitishwa Kikamilifu",
                  text: "Subiria Msimamizi aruhusu uanzaji wa mtihani",
                  timerProgressBar: true,
                  timer: 15000,
                  icon: "success",
                  showConfirmButton: true,
                  animation: false,
                });
              } else {
                Vue.swal({
                  toast: true,
                  position: "center",
                  title: "Imeshindikana kuhakiki alama zako za vidole",
                  text: "Tafadhali rudia kufanya uthibitisho wa alama za vidole, tatizo likijirudia tena tafadhali muite msimamizi wa mtihani akusaidie",
                  timerProgressBar: true,
                  timer: 15000,
                  icon: "error",
                  showConfirmButton: true,
                  animation: false,
                });
              }
            }
            if (data.event.event === "candidate_remaining_time") {
              let duration = data.event.message.remaining_time;
              let days = Math.floor(duration / 24 / 60 / 60);
              let hoursLeft = Math.floor(duration - days * 86400);
              let hours = Math.floor(hoursLeft / 3600);
              let minutesLeft = Math.floor(hoursLeft - hours * 3600);
              let minutes = Math.floor(minutesLeft / 60);
              self.examinationDurationInSeconds = duration;
              self.statisticsValues.averageTimePerQuestion =
                self.calculateAverageTimePerQuestion(duration);

              if (hours === 1)
                self.displayedTime =
                  "Limebaki Saa " +
                  parseInt(self.pad(hours)) +
                  " na dakika " +
                  parseInt(self.pad(minutes));
              else
                self.displayedTime =
                  "Yamebaki Masaa " +
                  self.pad(hours) +
                  " na dakika " +
                  parseInt(self.pad(minutes));
              if (parseInt(duration) <= 0) {
                clearInterval(this.countdownTimer);
                self.displayedTime = "Muda Umeisha";
                self.submitExam();
              } else if (
                parseInt(duration) <= 1800 &&
                self.tenMinuteWarning === false
              ) {
                Vue.swal({
                  toast: true,
                  position: "top-right",
                  title: "Zimebakia dakika " + parseInt(self.pad(minutes)),
                  text: "Hakikisha upo kwenye hatua zako za mwishoni",
                  timerProgressBar: true,
                  timer: 20000,
                  icon: "warning",
                  showConfirmButton: false,
                  animation: false,
                  persistent: true,
                });
                self.tenMinuteWarning = true;
              } else if (
                parseInt(duration) <= 60 &&
                self.oneMinuteWarning === false
              ) {
                Vue.swal({
                  toast: true,
                  position: "top-right",
                  title:
                    "Mtihani utaisha ndani ya dakika " +
                    parseInt(self.pad(minutes)),
                  text: "Hakikisha upo kwenye hatua zako za mwishoni",
                  timerProgressBar: true,
                  timer: 20000,
                  icon: "warning",
                  showConfirmButton: false,
                  animation: false,
                  persistent: true,
                });
                self.oneMinuteWarning = true;
              } else {
                // duration--;
                console.log(duration);
              }
            }
            if (data.event.event === "grantPermission") {
              if (data.event.permissionGranted === true) {
                this.overlay = true;
                this.examinationDialogue = false;
                console.log(this.overlay, this.examinationDialogue);
              } else {
                Vue.swal({
                  toast: true,
                  position: "center",
                  title: "Haujaruhusiwa kutoka nje ya chumba cha Mtihani",
                  text: "Tafadhali Endelea na utaratibu wa kufanya Mtihani",
                  timerProgressBar: true,
                  timer: 15000,
                  icon: "error",
                  showConfirmButton: false,
                  animation: false,
                });
              }
              console.log(data.event.message);
            }
          };

          this.websocketPersonal.onopen = function () {
            // console.log(event)
            console.log(
              "Successfully connected to the echo websocket Venue server..."
            );
          };
          this.websocketVenue.onmessage = function (event) {
            const data = JSON.parse(event.data);
            // console.log(data.event.event)
            // console.log(data.event.event==="fingerprintVerification")
            if (data.event.event != "allowVerification") {
              console.log(data.event.code);
              self.verificationIsAllowed = data.event.code === 9300;
              self.maelezoKwaMtahiniwa = [];
              self.maelezoKwaMtahiniwa.push(
                "Sasa Unaweza ukaanza uthibitisho wa alama za vidole"
              );
              self.maelezoKwaMtahiniwa.push(
                "Bonyeza kitufe kilicho andikwa Fanya uthibitisho, kisha fuata utaratibu."
              );
              if (data.event.code === 9300) {
                console.log(self);
                Vue.swal({
                  toast: true,
                  position: "center",
                  title: "Sasa Unaweza ukaanza uthibitisho wa alama za vidole",
                  text: "Bonyeza kitufe kilicho andikwa Fanya uthibitisho, kisha fuata utaratibu.",
                  timerProgressBar: true,
                  timer: 15000,
                  icon: "success",
                  showConfirmButton: true,
                  animation: false,
                });
              }
              console.log(this.verificationIsAllowed);
            } else if (data.event.event === "startExamination") {
              if (self.isVerified) {
                self.maelezoKwaMtahiniwa = [];
                self.maelezoKwaMtahiniwa.push("Mtihani Umeanza.");
                self.maelezoKwaMtahiniwa.push(
                  "Tafadhali subiri kidogo, pindi mtihani wako unaandaliwa."
                );
                self.startExam();
                Vue.swal({
                  toast: true,
                  position: "center",
                  title: "Mtihani Umeanza.",
                  text: "Tafadhali subiri kidogo, pindi mtihani wako unaandaliwa.",
                  timerProgressBar: true,
                  timer: 10000,
                  icon: "success",
                  showConfirmButton: true,
                  confirmButtonText: "Sawa",
                  animation: false,
                });
              } else {
                self.examHasStarted = true;
                Vue.swal({
                  toast: true,
                  position: "top-right",
                  title: "Mtihani Umeanza.",
                  text: "Jitahidi kukamilisha uthibiitisho, ili kuanza mtihani",
                  timerProgressBar: true,
                  timer: 10000,
                  icon: "warning",
                  showConfirmButton: true,
                  confirmButtonText: "Sawa",
                  animation: false,
                });
              }
            } else if (data.event.event === "invigilator_grant_permission") {
              console.log(data.event.message.permission_granted);
              if (data.event.message.bookingNumber === self.bookingNumber)
                if (data.event.message.permission_granted === true) {
                  self.overlay = true;
                  self.examinationDialogue = false;
                  console.log(self.overlay, self.examinationDialogue);
                } else {
                  Vue.swal({
                    toast: true,
                    position: "center",
                    title: "Umekataliwa, Kutoka kwenye chumba cha mtihani",
                    timerProgressBar: true,
                    timer: 10000,
                    icon: "error",
                    showConfirmButton: false,
                    animation: false,
                  });
                }
            }
          };
          this.websocketVenue.onopen = function () {
            // console.log(event)
            // console.log("Successfully connected to the echo websocket Venue server...")
          };

          this.websocketWebRTC.onmessage = function (e) {
            const data = JSON.parse(e.data);
            if (
              data["event"]["event"] === "offerevent" &&
              data["event"]["offer_creator"] !== 12
            ) {
              let offer_contents = data["event"]["offer_contents"];
              console.log(
                "offer_contents==========================================================="
              );
              console.log(offer_contents);
              self.startAnswer(offer_contents);
            } else if (data["event"]["event"] === "answerevent") {
              console.log(
                "answer_contents==========================================================="
              );
              let answer_contents = data["event"]["answer_contents"];
              console.log(answer_contents);
              self.startSetRemoteDescription(answer_contents);
            } else if (data["event"]["event"] === "candidateevent") {
              console.log(
                "candidate_contents==========================================================="
              );
              let candidate_contents = data["event"]["candidate_contents"];
              console.log(candidate_contents);
              self.setRemoteCandidate(candidate_contents);
            }
          };
          this.websocketWebRTC.onopen = function () {
            console.log(
              "Successfully connected to the webrtc websocket server..."
            );
          };
        } else {
          console.log("Bookiing is  not available");
          this.noBooking = false;
        }
      });
  },
};
</script>

<style>
.d-flex {
  display: flex;
}
.align-items-vertically-center {
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.left-curved-radius {
  border-radius: 20px 7px 7px 20px;
}
.h-100 {
  height: 100%;
}
.w-100 {
  width: 100%;
}
.p-0 {
  padding: 0;
}
.count-down {
  background-color: white;
  color: rgba(48, 67, 194, 0.76);
  padding: 10px 20px;
  border-radius: 50px;
}
.marked-for-review {
  background: rgba(255, 204, 0, 0.3);
  border-radius: 10px;
  padding: 15px 10px 0px 10px;
  margin-bottom: 5px;
}
</style>
