<template>
  <v-row class="h-100 d-flex align-items-center" justify="center">
    <v-alert
      border="left"
      colored-border
      elevation="2"
      max-width="390"
      type="error"
    >
      <b>Inaonekana haujathibitishwa kikamilifu,</b> Tafadhali rudi nyuma na
      jaribu tena
    </v-alert>
  </v-row>
</template>

<script>
import verifyToken from "../../../graphql/Authentication/verifyToken.graphql";
import me from "../../../graphql/Authentication/me.graphql";

export default {
  name: "VerifyUser",
  data() {
    return {
      isAuthenticated: false,
    };
  },
  mounted() {
    this.$apollo
      .mutate({
        mutation: verifyToken,
        variables: {
          token: this.$route.params.authToken,
        },
      })
      .then((response) => {
        this.isAuthenticated = response.data.verifyToken.success;
        if (this.isAuthenticated) {
          // localStorage.removeItem('latra-dts-token')
          console.log('"' + this.$route.params.authToken + '"');
          localStorage.setItem(
            "latra-dts-token",
            '"' + this.$route.params.authToken + '"'
          );
          console.log(localStorage.getItem("latra-dts-token"));
          this.$store.commit(
            "SET_TOKEN",
            localStorage.getItem("latra-dts-token")
          );
          this.$apollo
            .query({
              query: me,
            })
            .then((userDetails) => {
              let user = userDetails.me;
              this.$store.commit("LOGIN_USER", user);
              this.$router.push("/latradts_canditate/exam");
            });
        }
      });
  },
};
</script>

<style scoped></style>
