<template>
  <v-row justify="center">
    <v-dialog
      v-model="EditExaminationSectionDialog"
      max-width="60%"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-h7"><v-icon>mdi-home-plus</v-icon> Edit Examination Section</span>
        </v-card-title>
        <v-divider></v-divider>
            <v-card-text>
                    <v-container>
                    <v-row>
                        <v-col
                            cols="12"
                            md="12"
                            sm="12"
                        >
                   <v-text-field
                        v-model="sectionName"
                        label="Section Name *"
                        prepend-icon="mdi-card-account-details"
                        required
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="sectionDescription"
                        label="Section Description *"
                        prepend-icon="mdi-card-account-details"
                        required
                    >
                    </v-text-field>
                    </v-col>
                        </v-row>
                    </v-container>
                    <v-divider></v-divider>
            </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            color="pink"
            fab
            small
            
           @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            class="white--text"
            color="rgba(48, 67, 194, 0.76)"
            fab
            small
           @click="EditExamination"
          >
            <v-icon>mdi-check-all</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <LocationSettings :openLocationDialog="openLocationDialog" @close="openLocationDialog =! openLocationDialog"/> -->
  </v-row>
</template>


<script>

  export default {
    components:{
        
    },
    data: () => ({
      checkbox:false,
      sectionName:null,
      sectionDescription:null,
      sectionUniqueId:null,
    }),
    props: ["EditExaminationSectionDialog","ExaminationSectionItems"],
    mounted(){
      
    },
    methods:{

      EditExamination: function(){
        let data={
          sectionName:this.sectionName,
          sectionDesciptions:this.sectionDescription,
          sectionUniqueId:this.sectionUniqueId
        }
        this.$store.dispatch('updateExaminationSection',data).then(()=>{
          this.$emit('close')
        })
        // this.$apollo.mutate({
        //   mutation:editExamination,
        //   variables:{
        //     examinationTitle:this.sectionName,
        //     sectionUniqueId:this.sectionUniqueId
        // }
        // }).then(()=>{
        //   // console.log(response.editExamination.newSettings)
        //   this.$emit('close')
        // })
      }
      
    },watch:{
      EditExaminationSectionDialog:function(){
        this.sectionUniqueId=this.ExaminationSectionItems.sectionUniqueId
        this.sectionName=this.ExaminationSectionItems.sectionName
        this.sectionDescription=this.ExaminationSectionItems.sectionDescriptions
      }
    }
  }
</script>