import {apolloClient} from '@/vue-apollo'
import allExaminationTimetablesGroups from '../../graphql/Timetables/allExaminationTimetablesGroups.graphql'
import allExaminationTimetables from '../../graphql/Examinations/allExaminationTimetables.gql'
import addNewExmainationTimetable from "../../graphql/Examinations/addNewExaminationTimetable.gql";
import updateExaminationTimetable from "../../graphql/Examinations/updateExaminationTimetable.gql";
import cancelExaminationTimetable from "../../graphql/Examinations/cancelExaminationTimetable.graphql";
import publishExaminationTimetable from "../../graphql/Examinations/publishExaminationTimetable.graphql"
import Vue from "vue";

export default {
    state: {
        allTimetables:[],
        selectedTimetableSession:null,
    },
    mutations: {
        addTimetables(state, data){
            state.allTimetables.push(data)
        },
        editTimetables(state, data){
            state.allTimetables.find(({primaryKey}) => primaryKey === data.primaryKey ).name= data.name
            // state.allTimetables.find(({primaryKey}) => primaryKey === data.primaryKey ).timetableUniqueId= data.timetableUniqueId
            state.allTimetables.find(({primaryKey}) => primaryKey === data.primaryKey ).exam= data.exam
            state.allTimetables.find(({primaryKey}) => primaryKey === data.primaryKey ).venue= data.venue
            // state.allTimetables.find(({primaryKey}) => primaryKey === data.primaryKey ).venuesUniqueId= data.venuesUniqueId
            state.allTimetables.find(({primaryKey}) => primaryKey === data.primaryKey ).date= data.date
            state.allTimetables.find(({primaryKey}) => primaryKey === data.primaryKey ).time= data.time
            state.allTimetables.find(({primaryKey}) => primaryKey === data.primaryKey ).slots= data.slots
            state.allTimetables.find(({primaryKey}) => primaryKey === data.primaryKey ).actions= data.actions
            // state.allTimetables.find(({primaryKey}) => primaryKey === data.primaryKey ).timetableIsCanceled= data.timetableIsCanceled
            // state.allTimetables.find(({primaryKey}) => primaryKey === data.primaryKey ).timetableIsPublished= data.timetableIsPublished
        },
        clearTimetables(state){
            state.allTimetables=[]
        },
        setselectedTimetableSession(state,session){
            state.selectedTimetableSession=null
            state.selectedTimetableSession=session
        },        
        publishTimetableSession(state,data){
            state.allTimetables.find(({name}) => name === data.timetableName ).timetableIsPublished= data.timetableIsPublished
        },
        cancelTimetable(state,data){
           state.allTimetables.splice(state.allTimetables.findIndex(({name}) => name === data.name ),1)
        }
    },
    actions: {
        async updateselectedTimetableSession(context,session) {
            context.commit('setselectedTimetableSession',session)
        },
         
        async LoadTimetables(context){
            // console.log("This is mee")
            context.commit('clearTimetables')
            apolloClient.query({
                fetchPolicy:"no-cache",
                query:allExaminationTimetablesGroups
            }).then((response)=>{

                response.data.allExaminationTimetablesGroups.forEach(timetableSessionGroups=>{
                    // console.log(timetableSessionGroups.timetableName)
                    let BigData=[]
                    let venuesUniqueIdGeneral=null
                    apolloClient.query({
                        query:allExaminationTimetables,
                        variables:{
                            timetableName:timetableSessionGroups.timetableName
                        }
                    }).then((response)=>{
                        // console.log("response")
                        // console.log(response)
                        response.data.allExaminationTimetables.edges.forEach(element=>{
                            venuesUniqueIdGeneral=element.node.timetableVenue.venuesUniqueId
                            let data={
                                relayId:element.node.id,
                                primaryKey:element.node.primaryKey,
                                timetableUniqueId:element.node.timetableUniqueId,
                                exam:element.node.timetableExamination.examinationTitle,
                                unique_id:element.node.timetableExamination.primaryKey,
                                exam_relay_id:element.node.timetableExamination.id,
                                venue:element.node.timetableVenue.venuesName,
                                venuesUniqueId:element.node.timetableVenue.venuesUniqueId,
                                date:element.node.timetableDate,
                                time:element.node.timetableTime,
                                slots:element.node.timetableSlots,
                                actions:element.node.timetableUniqueId,
                                timetableIsCanceled:element.node.timetableIsCanceled,
                                timetableIsPublished:element.node.timetableIsPublished
                            }
                            BigData.push(data)
                        })

                    }).finally(()=>{
                        let allTimetableGroups={
                            name:timetableSessionGroups.timetableName,
                            // timetableUniqueId:timetableSessionGroups.timetableUniqueId,
                            date:timetableSessionGroups.timetableDate,
                            time:timetableSessionGroups.timetableTime,
                            venue:timetableSessionGroups.timetableVenue,
                            venuesUniqueId:venuesUniqueIdGeneral,
                            slots:timetableSessionGroups.timetableSlots,
                            examinations:BigData,
                            timetableIsPublished:timetableSessionGroups.isPublished,
                            timetableIsCanceled:timetableSessionGroups.isCancelled
                        }
                        context.commit('addTimetables',allTimetableGroups)
                    })
                })
            })
        },
        async createTimetable(context,data){
            apolloClient.mutate({
                mutation:addNewExmainationTimetable,
                variables:data
            }).then((response)=>{
                // response.data.addNewExaminationTimetable.newTimeTable.forEach(element=>{
                //     // let data2={
                //     //     primaryKey:element.primaryKey,
                //     //     name:element.timetableName,
                //     //     // timetableUniqueId:element.timetableUniqueId,
                //     //     exam:element.timetableExamination,
                //     //     unique_id:element.primaryKey,
                //     //     // exam_relay_id:element.timetableExamination.id,
                //     //     venue:element.timetableVenue,
                //     //     venuesUniqueId:data.venuesUniqueId,
                //     //     date:data.timetableDate,
                //     //     time:data.timetableTime,
                //     //     slots:element.timetableSlots,
                //     //     actions:element.timetableUniqueId,
                //     //     timetableIsCanceled:false,
                //     //     timetableIsPublished:false
                //     // }
                //     context.commit('addTimetables',data2)
                // })
                 // context.dispatch('LoadTimetables')
                if(response.data.addNewExaminationTimetable.success){
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Timetable created Succesfully!',
                        timerProgressBar:true,
                        timer:10000,
                        icon:'success',
                        showConfirmButton:false,
                        animation:false,
                    });
                    
                    let BigData=[]
                    let venuesUniqueIdGeneral=null
                    apolloClient.query({
                        query:allExaminationTimetables,
                        variables:{
                            timetableName:response.data.addNewExaminationTimetable.newTimeTable[0].timetableName
                        }
                    }).then((response2)=>{
                        // console.log("response2")
                        // console.log(response2)
                        response2.data.allExaminationTimetables.edges.forEach(element=>{
                            venuesUniqueIdGeneral=element.node.timetableVenue.venuesUniqueId
                            let dataExaminations={
                                relayId:element.node.id,
                                primaryKey:element.node.primaryKey,
                                // name:element.node.timetableName,
                                timetableUniqueId:element.node.timetableUniqueId,
                                exam:element.node.timetableExamination.examinationTitle,
                                unique_id:element.node.timetableExamination.primaryKey,
                                exam_relay_id:element.node.timetableExamination.id,
                                venue:element.node.timetableVenue.venuesName,
                                venuesUniqueId:element.node.timetableVenue.venuesUniqueId,
                                date:element.node.timetableDate,
                                time:element.node.timetableTime,
                                slots:element.node.timetableSlots,
                                actions:element.node.timetableUniqueId,
                                timetableIsCanceled:element.node.timetableIsCanceled,
                                timetableIsPublished:element.node.timetableIsPublished
                            }
                            BigData.push(dataExaminations)
                        })}).finally(()=>{
                            console.log(response.data.addNewExaminationTimetable.newTimeTable);
                            let allTimetableGroups={
                                name:response.data.addNewExaminationTimetable.newTimeTable[0].timetableName,
                                // timetableUniqueId:response.data.addNewExaminationTimetable.newTimeTable[0].timetableUniqueId,
                                date:response.data.addNewExaminationTimetable.newTimeTable[0].timetableDate,
                                time:data.timetableTime,
                                venue:response.data.addNewExaminationTimetable.newTimeTable[0].timetableVenue,
                                venuesUniqueId:venuesUniqueIdGeneral,
                                slots:response.data.addNewExaminationTimetable.newTimeTable[0].timetableSlots,
                                examinations:BigData,
                                timetableIsPublished:false,
                                timetableIsCanceled:false
                            }
                            context.commit('addTimetables',allTimetableGroups)
                        })
                }else {
                    console.log(response.data.addNewExaminationTimetable);
                    if(response.data.addNewExaminationTimetable.code===9307){
                        Vue.swal({
                            title:"Error: "+response.data.addNewExaminationTimetable.code,
                            text: 'You Cannot create a session in the past',
                            icon:'error',
                            showConfirmButton:true,
                            animation:false,
                        });
                    }
                    else if(response.data.addNewExaminationTimetable.code===9312){
                        Vue.swal({
                            title:"Error: "+response.data.addNewExaminationTimetable.code,
                            text: 'Another Session is present at this time',
                            icon:'error',
                            showConfirmButton:true,
                            animation:false,
                        });
                    }
                    else if(response.data.addNewExaminationTimetable.code===9310){
                        Vue.swal({
                            title:"Error: "+response.data.addNewExaminationTimetable.code,
                            text: 'Another Session is present at this time',
                            icon:'error',
                            showConfirmButton:true,
                            animation:false,
                        });
                    }
                }
            })
        },
        async cancelTimetable(context,data){
            console.log("Hii ni Action")
            console.log(data)
            apolloClient.mutate({
                mutation:cancelExaminationTimetable,
                variables:data
            }).then((response)=>{
                // console.log(response.data.cancelExaminationTimetable.cancelledTimeTable)
                let data={
                    name:response.data.cancelExaminationTimetable.cancelledTimeTable.timetableName,
                }

                if(response.data.cancelExaminationTimetable.success){
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Timetable Canceled Successfully!',
                        timerProgressBar:true,
                        timer:15000,
                        icon:'success',
                        showConfirmButton:false,
                        animation:false,
                    });
                    context.commit('cancelTimetable',data)
                }else {
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Error: '+response.data.cancelExaminationTimetable.code,
                        text: "You can't Cancel this timetable",
                        timerProgressBar:true,
                        timer:15000,
                        icon:'error',
                        showConfirmButton:false,
                        animation:false,
                    });
                }

            })
        },
        async editTimetable(context,data){
            console.log("Hii ni Action")
            console.log(data)
            apolloClient.mutate({
                mutation:updateExaminationTimetable,
                variables:data
            }).then((response)=>{
                console.log(response.data.updateExaminationTimetable.newTimeTable)
                let data2={
                    // relayId:response.data.updateExaminationTimetable.newTimeTable.id,
                    primaryKey:response.data.updateExaminationTimetable.newTimeTable.primaryKey,
                    name:response.data.updateExaminationTimetable.newTimeTable.timetableName,
                    timetableUniqueId:response.data.updateExaminationTimetable.newTimeTable.timetableUniqueId,
                    // exam:response.data.updateExaminationTimetable.newTimeTable.timetableExamination.examinationTitle,
                    // unique_id:response.data.updateExaminationTimetable.newTimeTable.timetableExamination.examinationUniqueId,
                    venue:response.data.updateExaminationTimetable.newTimeTable.timetableVenue,
                    // venuesUniqueId:response.data.updateExaminationTimetable.newTimeTable.timetableVenue.venuesUniqueId,
                    date:response.data.updateExaminationTimetable.newTimeTable.timetableDate,
                    time:response.data.updateExaminationTimetable.newTimeTable.timetableTime,
                    slots:response.data.updateExaminationTimetable.newTimeTable.timetableSlots,
                    // actions:response.data.updateExaminationTimetable.newTimeTable.timetableUniqueId,
                    // timetableIsCanceled:response.data.updateExaminationTimetable.newTimeTable.timetableIsCanceled,
                    timetableIsPublished:data.timetableIsPublished
                }
                context.commit('editTimetables',data2)
                if(response.data){
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Timetable Updated Successfully!',
                        timerProgressBar:true,
                        timer:10000,
                        icon:'success',
                        showConfirmButton:false,
                        animation:false,
                    });

                }else {
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Opps! An error Occurred',
                        text: response.errors[0].message,
                        timerProgressBar:true,
                        timer:10000,
                        icon:'error',
                        showConfirmButton:false,
                        animation:false,
                    });
                }

            })
        },
        async publishTimetableSession(context,data){
            apolloClient.mutate({
                mutation:publishExaminationTimetable,
                variables:data
            }).then((response)=>{
                let publishedTimeTable={
                    timetableName: response.data.publishExaminationTimetable.publishedTimeTable.timetableName,
                    timetableIsPublished: response.data.publishExaminationTimetable.publishedTimeTable.timetableIsPublished
                }
                if(publishedTimeTable.timetableIsPublished){
                    Vue.swal({
                        toast: true,
                        position: 'top-right',
                        title: 'Timetable Published Succesfully!',
                        timerProgressBar: true,
                        timer: 10000,
                        icon: 'success',
                        showConfirmButton: false,
                        animation: false,
                    });
                }else{
                    Vue.swal({
                        toast: true,
                        position: 'top-right',
                        title: 'Timetable Un-Published Successfully!',
                        timerProgressBar: true,
                        timer: 10000,
                        icon: 'success',
                        showConfirmButton: false,
                        animation: false,
                    });
                }
                context.commit('publishTimetableSession',publishedTimeTable)
            })
        }
    }
}