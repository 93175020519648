<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <vue-json-to-csv :json-data="this.$store.state.ReportStore.centerReport" :csv-title="'institutional_report'">
          <v-btn text outlined small color="primary">Export Report</v-btn>
        </vue-json-to-csv>

        <v-divider class="mx-4" color="orange " inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="this.$store.state.ReportStore.centerReport"
        item-key="candidateLastName"
        class="elevation-1"
        :search="search"
        :custom-filter="filterOnlyCapsText"
      >
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import VueJsonToCsv from "vue-json-to-csv";
export default {
  components: {
    VueJsonToCsv,
  },
  data() {
    return {
      search: "",
    };
  },
  async mounted() {
    await this.$store.dispatch("AllTestCenterReport");
  },

  computed: {
    headers() {
      return [
        {
          text: "Instituion/Venue",
          align: "start",
          sortable: false,
          value: "CenterName",
        },
        { text: "Total Candidates", value: "Location" },
        {
          text: "Examination Taken",
          value: "capacity",
          filter: (value) => {
            if (!this.candidateTotalBookings) return true;

            return value < parseInt(this.candidateTotalBookings);
          },
        },
        { text: "Examination Start Time", value: "slot" },
        { text: "Average Marks", value: "examAppeared" },
        { text: "Minimum Mark", value: "examAppeared" },
        { text: "Maximum Mark", value: "examAppeared" },
        { text: "Bellow Pass Mark", value: "examAppeared" },
      ];
    },
  },
  methods: {
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
  },
};
</script>
