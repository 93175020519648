import {apolloClient} from '@/vue-apollo'
import allRegions from '../../graphql/VenuesAdmin/allRegions.gql'
import createCenterRegion from "../../graphql/VenuesAdmin/createCenterRegion.graphql";
import updateCenterRegion from "../../graphql/VenuesAdmin/updateCenterRegion.graphql";
import Vue from "vue";

export default {
    state: {
        allRegions:[]
    },
    mutations: {
        LoadRegions(state,data){
            state.allRegions.push(data)
        },
        EditRegion(state,data){
            state.allRegions.find(({regionUniqueId})=> regionUniqueId ===data.regionUniqueId).regionName=data.regionName
            state.allRegions.find(({regionUniqueId})=> regionUniqueId ===data.regionUniqueId).regionIsActive=data.regionIsActive
        },
        clearRegions(state){
            state.allRegions=[]
        }
    },
    actions: {
        async LoadRegionsAction(context){
            context.commit('clearRegions')

            apolloClient.query({
                fetchPolicy:"no-cache",
                query:allRegions
            }).then((response)=>{
                response.data.allRegions.edges.forEach(element=>{

                    let data={
                        primaryKey:element.node.primaryKey,
                        regionUniqueId:element.node.regionUniqueId,
                        regionName:element.node.regionName,
                        regionIsActive:element.node.regionIsActive,
                    }
                    context.commit('LoadRegions',data)
                })
            })
        },
        async AddRegionsAction(context,data){

            apolloClient.mutate({
                mutation:createCenterRegion,
                variables:data
            }).then((response)=>{
                if(response.data){
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Region Created Successfully!',
                        timerProgressBar:true,
                        timer:10000,
                        icon:'success',
                        showConfirmButton:false,
                        animation:false,
                    })
                }else{
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Oops!, Looks like an error occurred.',
                        timerProgressBar:true,
                        timer:10000,
                        icon:'error',
                        showConfirmButton:false,
                        animation:false,
                    })
                }
                let data={
                    primaryKey:response.data.createCenterRegion.region.primaryKey,
                    regionUniqueId:response.data.createCenterRegion.region.regionUniqueId,
                    regionName:response.data.createCenterRegion.region.regionName,
                    regionIsActive:response.data.createCenterRegion.region.regionIsActive,
                }
                context.commit('LoadRegions',data)

            })
        },
        async EditRegionsAction(context,data){

            apolloClient.mutate({
                mutation:updateCenterRegion,
                variables:data
            }).then((response)=>{
                if(response.data){
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Region Updated Succesfully!',
                        timerProgressBar:true,
                        timer:10000,
                        icon:'success',
                        showConfirmButton:false,
                        animation:false,
                    })
                }else{
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Oops!, Looks like an error occured.',
                        timerProgressBar:true,
                        timer:10000,
                        icon:'error',
                        showConfirmButton:false,
                        animation:false,
                    })
                }
                let data={
                    primaryKey:response.data.updateCenterRegion.updatedRegion.primaryKey,
                    regionUniqueId:response.data.updateCenterRegion.updatedRegion.regionUniqueId,
                    regionName:response.data.updateCenterRegion.updatedRegion.regionName,
                    regionIsActive:response.data.updateCenterRegion.updatedRegion.regionIsActive,
                }
                context.commit('EditRegion',data)

            })
        }
    }
}