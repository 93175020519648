<template>
  <v-row justify="center">
    <v-dialog
      v-model="OpenNewAccessForm"
      persistent
      max-width="60%"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6"><v-icon>mdi-seal</v-icon> Add New User Role </span>
        </v-card-title>
        <v-divider></v-divider>
            <v-card-text>
                    <v-container>
                    <v-row>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
                    <v-text-field
                        v-model="role_name"
                        :rules="nameRules"
                        :counter="20"
                        label="Role Name *"
                        required
                        prepend-icon="mdi-text-recognition"
                    >
                    </v-text-field>
                    <v-checkbox
                        color="success"
                        v-model="isCloned"
                        :label="`Clone from the Existing Roles`"
                        @change="consoleMethod"
                        ></v-checkbox>
                        <div v-if="isCloned" style="width:100%; padding:20px; ">
                            <v-select v-if="isCloned"
                                v-model="selectedUserRoles"
                                :items="roles"
                                attach
                                chips
                                item-value="node.primaryKey"
                                item-text="node.roleDescriptions"
                                label="Existing Roles"
                                multiple
                                @change="consoleMethod"
                                outlined
                            ></v-select>        

                        </div>
                            <v-treeview
                              v-model="selectedPermissions"
                              selectable
                              :items="groupRoles"
                              v-if="groupRoles.length>0"
                              @input="consoleMethod"
                            ></v-treeview>
                        </v-col>
                        </v-row>
                    </v-container>
                    <v-divider></v-divider>
            </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="font-weight-bold px-4 mb-3 rounded-lg"
            dark
            outlined
           @click="$emit('close')"
          >
            <v-icon small>mdi-close</v-icon> Cancel
          </v-btn>
          <v-btn
            color="success"
            dark
            class="font-weight-bold px-4 mb-3 rounded-lg"
           @click="submitRole"
          >
            <v-icon small>mdi-check-all</v-icon> Create Role
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import createUserRole from '../../../../graphql/createUserRole.gql'
import allUserRoles from '../../../../graphql/allUserRoles.gql'
import allPermissionGroups from '../../../../graphql/allPermissionGroups.gql'


export default {
    components:{
    },
    data: () => ({
        Applicdations:'',
        checkbox:false,
        openLocationDialog:false,
        selectedUserRoles:'',
        selectedPermissions:'',
        time: null,
        menu4:false,
        menu3: false,
        modal2: false,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        modal: false,
        menu2: false,
        timeCounter:0,
        picker: new Date().toISOString().substr(0, 7),
        dialog: false,   
        role_name:'',
        isCloned:false,   
        nameRules: [
            v => !!v || 'Role name is required',
            v => v.length <= 20 || 'Role must be less than 20 characters',
        ],
        roles:[],
        groupRoles: []
    }),
    props: ["OpenNewAccessForm"],
    methods:{
      submitRole:function(){
        this.$apollo.mutate({
              mutation:createUserRole,
              variables: {
                  roleDescription: this.role_name,
                  permissionIdList:this.selectedPermissions+"".replaceAll('c',''),
                  roleIdList:this.selectedUserRoles+"",
                  isCloned:this.isCloned
              },
          }).then((response)=>{
              // if(response.data.createUserRole){
              //   }
              console.log(response)
                  this.$router.push('/latradts_settings/users')
          })
          this.role_name=''
          this.$emit('close');
    },
    consoleMethod: function(){
      // console.log(this.selectedUserRoles+"")
      // console.log(this.selectedPermissions+"".toString().replaceAll('c','a'))
    }
    },mounted(){
      this.$apollo.query({
        query:allUserRoles,
      }).then((response)=>{
        this.roles=response.data.allRoles.edges
      })

      this.$apollo.query({
        query:allPermissionGroups
      }).then((response)=>{
        response.data.allPermissionGroups.edges.forEach(element=>{
          let children = []
          element.node.permissionBelongingGroup.edges.forEach(child=>{
            children.push({'id':child.node.primaryKey,'name':child.node.permissionDescriptions})
          })
          let disabled =true
          if(children.length>0){
            disabled=false
          }
          this.groupRoles.push({'id':"p"+element.node.primaryKey,'name':element.node.groupDescriptions,'children':children, disabled:disabled})
        });
      })
    }
  }
</script>