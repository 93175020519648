import {apolloClient} from '@/vue-apollo'
import Vue from "vue";
import allVenueDevices from "../../graphql/TestCenterConfiguration/allVenueDevices.graphql";
import addNewExmainationVenueDevice from "../../graphql/TestCenterConfiguration/addNewExmainationVenueDevice.graphql";
import updateExmainationVenueDevice from "../../graphql/TestCenterConfiguration/updateExmainationVenueDevice.graphql";

export default {
    state: {
        allVenueDevices:[]
    },
    mutations: {
        addVenueDevices(state, data){
            state.allVenueDevices.push(data)
        },
        editVenueDevices(state, data){
            state.allVenueDevices.find(({deviceSerialNumber}) => deviceSerialNumber === data.deviceSerialNumber ).region= data.region
            state.allVenueDevices.find(({deviceSerialNumber}) => deviceSerialNumber === data.deviceSerialNumber ).venue= data.venue

        },
        clearVenueDevices(state){
            state.allVenueDevices=[]
        }
    },
    actions: {
        async LoadVenueDevices(context){
            context.commit('clearVenueDevices')

            apolloClient.query({
                fetchPolicy:"no-cache",
                query:allVenueDevices
            }).then((response)=>{
                response.data.allVenueDevices.edges.forEach(element=>{
                    let data= {
                        'deviceSerialNumber':element.node.deviceSerialNumber,
                        'region':element.node.deviceVenue.venuesRegion,
                        'venue':element.node.deviceVenue,
                        'unique_id':element.node.deviceUniqueId
                    }
                    context.commit('addVenueDevices',data)
                })

            })
        },
        async createVenueDevice(context,data){
            apolloClient.mutate({
                mutation:addNewExmainationVenueDevice,
                variables:data
            }).then((response)=>{

                console.log(response.data.addNewExmainationVenueDevice.venueDevice.deviceSerialNumber)
                let data= {
                    'deviceSerialNumber':response.data.addNewExmainationVenueDevice.venueDevice.deviceSerialNumber,
                    'region':response.data.addNewExmainationVenueDevice.venueDevice.deviceVenue.venuesRegion,
                    'venue':response.data.addNewExmainationVenueDevice.venueDevice.deviceVenue,
                    'unique_id':response.data.addNewExmainationVenueDevice.venueDevice.deviceUniqueId
                }

                context.commit('addVenueDevices',data)
                if(response.data){
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Fingerprint Device has been Bound to venue Successfully!',
                        timerProgressBar:true,
                        timer:5000,
                        icon:'success',
                        showConfirmButton:false,
                        animation:false,
                    });

                }else {
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Opps! Looks like an error Occurred',
                        text: response.errors[0].message,
                        timerProgressBar:true,
                        timer:5000,
                        icon:'error',
                        showConfirmButton:false,
                        animation:false,
                    });
                }

            })
        },
        async editVenueDevice(context,data){
            apolloClient.mutate({
                mutation:updateExmainationVenueDevice,
                variables:data
            }).then((response)=>{
                console.log(response.data.updateExmainationVenueDevice)
                if(response.data){
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Fingerprint Device Configuration Updated Succesfully!',
                        timerProgressBar:true,
                        timer:5000,
                        icon:'success',
                        showConfirmButton:false,
                        animation:false,
                    });

                }else {
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Opps! Looks like an error Occured',
                        text: response.errors[0].message,
                        timerProgressBar:true,
                        timer:5000,
                        icon:'error',
                        showConfirmButton:false,
                        animation:false,
                    });
                }
                let data= {
                    'deviceSerialNumber':response.updateExmainationVenueDevice.updatedVenue.deviceSerialNumber,
                    'region':response.updateExmainationVenueDevice.updatedVenue.deviceVenue.venuesRegion,
                    'venue':response.updateExmainationVenueDevice.updatedVenue.deviceVenue,
                    'unique_id':response.updateExmainationVenueDevice.updatedVenue.deviceUniqueId
                }
                context.commit('editVenueDevices',data)


            })
        },

    }
}