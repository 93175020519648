import gql from 'graphql-tag'

export const LOGGED_IN_USER = gql`
 query Me{
  me{
    id
    firstName
    lastName
    isStaff
    isActive
    email
    username
  }
}
`