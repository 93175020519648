<template>
<v-banner outlined shaped type="">
      <v-chart class="chart" :option="option" />
</v-banner>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
]);

export default {
  name: "HelloWorld",
  props:['dataValues'],
  components: {
  VChart
  },
  provide: {
    [THEME_KEY]: "white"
  },
data() {
    return {
      option:{
          title: {
            text: 'Examination Type Appeared',
        },
         tooltip: {
            trigger: 'item'
          },
  legend: {
    top: 'bottom'
  },
  toolbox: {
    show: true,
    feature: {
      mark: { show: true },
      dataView: { show: true, readOnly: false },
      restore: { show: true },
      saveAsImage: { show: true }
    }
  },
  series: [
    {
     
      type: 'pie',
      // radius: '70%',
       radius: [50, 150],
      center: ['40%', '40%'],
      roseType: 'area',
      itemStyle: {
        borderRadius: 8
        
      },
      data: this.dataValues
    }
  ]
},
    computed: {

    }
    }
  }
};

</script>

<style scoped>
.chart {
  height: 400px;
}
</style>




