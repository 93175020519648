import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import AuthStore from './store.js';
import CandidatePermission from './CanidatePermission.js';
import RolesStore from './Roles.js';
import CancellationLimitStore from './cancelationLimit.js';
import TimetableStore from './TimetableStore/timetable.js';
import VenueStore from './VenuesStore/venues.js';
import RegionsStore from './VenuesStore/regions.js';
import UserStore from './UserStore/users.js';
import StaffStore from './StaffStore/staff.js';
import ExaminationStore from './ExaminationStore/examination.js';
import ExaminationSectionStore from './ExaminationStore/ExaminationSections.js';
import BookingSettingsStore from './BookingSettings';
import ExaminationSettingsStore from './ExaminationStore/examinationSettings.js';
import QuestionStore from './questions_store/Questions.js';
import CategoriesStore from './questions_store/Categories.js';
import QuestionsChartStore from './QuestionsChartStore/QuestionsChart.js';
import FingerprintDeviceConfigStore from './FingerprintDeviceConfigStore/fingerprintDeviceConfig.js';
import BookingStore from './BookingStore/Booking.js'
import ReportStore from './ReportStore/questionReportStore.js'

import SecureLS from "secure-ls";

let ls = new SecureLS({
   encodingType: "aes",
   isCompression: false,
   encryptionSecret: process.env.VUE_APP_STR_PWD
});

Vue.use(Vuex)

export default new Vuex.Store({
 modules: {
    AuthStore,
    CandidatePermission,
    RolesStore,
    TimetableStore,
    ReportStore,
    BookingStore,
    VenueStore,
    RegionsStore,
    UserStore,
    StaffStore,
    ExaminationStore,
    ExaminationSectionStore,
    ExaminationSettingsStore,
    BookingSettingsStore,
    QuestionStore,
    CategoriesStore,
    QuestionsChartStore,
    FingerprintDeviceConfigStore,
    CancellationLimitStore
 },
       plugins:[
          createPersistedState({
             key: 'EG',
             storage: {
                getItem: (key) => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: (key) => ls.remove(key),
             },
          }),
       ],
  namespaced:true

})