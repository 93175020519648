<template>
  <div>
    <div class="spinner-box23">
      <v-text-field
        label="Password"
        name="Password"
        v-model="password1"
        prepend-icon="mdi-lock"
        type="password"
        color="#3f51b5"
      />
      <v-text-field
        label="Re-Type Password"
        name="ReTypePassword"
        v-model="password2"
        prepend-icon="mdi-lock"
        type="password"
        color="#3f51b5"
      />
      <v-btn
        style="margin: 20px"
        link
        to="/landing_page"
        rounded
        color="#3f51b5"
        dark
      >
        <v-icon>mdi-arrow-left</v-icon> GO TO HOME PAGE
      </v-btn>

      <v-btn
        style="margin: 20px"
        @click="passwordReset"
        rounded
        color="#3f51b5"
        dark
        >RESET <v-icon>mdi-lock-reset</v-icon></v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userToken: null,
      absolute: true,
      overlay: true,
      password1: null,
      password2: null,
    };
  },
  methods: {
    async passwordReset() {
      this.$store.dispatch("showLoadingPinner", true);
      await this.$store.dispatch("passwordReset", {
        newPassword1: this.password1,
        newPassword2: this.password2,
        token: this.$route.params.userToken,
      });
      this.password1 = null;
      this.password2 = null;
      this.$store.dispatch("showLoadingPinner", false);
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.spinner-box23 {
  padding: 20px;
  position: fixed;
  width: 50%;
  margin: auto;
  left: 0px;
  top: 40%;
  right: 0px;
  background: white;
  box-shadow: 0px 0px 9px 0px #888cbf;
  text-align: center;
}
</style>
