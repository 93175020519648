<template>
  <v-container fluid>
    <v-row>
      <v-col lg="12" v-if="!nobooking">
        <v-alert
          v-for="bookingDetail in bookingDetails"
          :key="bookingDetail.node.primaryKey"
          border="left"
          colored-border
          color="warning"
          elevation="2"
          alert="false"
          class="rounded-lg"
        >
          <div
            class="d-flex justify-content-between align-items-vertically-center pa-2"
          >
            <v-row>
              <v-col sm="4">
                <span>
                  <span class="booking-number-orange"
                    >No.
                    <strong>{{
                      bookingDetail.node.bookingNumber
                    }}</strong></span
                  >
                  <h2>
                    Category:
                    {{
                      bookingDetail.node.bookingExamination.timetableExamination
                        .examinationTitle
                    }}
                  </h2>
                  <span
                    >Date:
                    {{
                      bookingDetail.node.bookingExamination.timetableDate
                    }}</span
                  ><br />
                  <span
                    >time:
                    {{ bookingDetail.node.bookingExamination.timetableTime }}
                  </span>
                </span>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col sm="4">
                <!-- <span>
                  <span>Ukumbi:</span>
                  <h2>{{bookingDetail}}</h2>
                  <span>Mkoa: Mikoa</span>
                </span> -->
              </v-col>
              <v-divider vertical></v-divider>
              <v-col sm="4">
                <span class="d-flex flex-column mx-5">
                  <span>Actions</span>
                  <v-btn
                    color="error"
                    style="width: 200px"
                    @click.stop="
                      cancelBooking(bookingDetail.node.bookingNumber);
                      confirmDialog = true;
                    "
                    class="rounded-lg"
                  >
                    <v-icon left>mdi-close-circle</v-icon> Sitisha Mtihani
                  </v-btn>
                  <v-btn
                    class="mt-2 rounded-lg"
                    color="success"
                    style="width: 200px"
                    @click="takeExam()"
                  >
                    <v-icon left>mdi-check-circle</v-icon> Fanya Mtihani
                  </v-btn>
                </span>
              </v-col>
            </v-row>
          </div>
        </v-alert>
      </v-col>
      <v-col cols="12" sm="12">
        <v-btn
          class="white--text float-right text-capitalize primary"
          v-if="nobooking"
          color="rgba(39, 166, 245, 0.53)"
          
          text
          @click="CreateBookingDialog = true"
        >
          Register to Take Exam / Quiz
        </v-btn>
      </v-col>

      <v-col cols="12" sm="12">
        <v-alert
          v-if="nobooking"
          text
          type="info"
          transition="scale-transition"
          origin="center center"
        >
          You dont have scheduled examination for now.
        </v-alert>
      </v-col>
      <v-row align="center" justify="center">
        <div>
          <v-img src="/nobooking.png" width="50%"> </v-img>
        </div>
      </v-row>
      <v-dialog v-model="confirmDialog" max-width="500">
        <v-card>
          <v-card-title class="text-h5">
            Thibitisha Kuahirisha kwa Mtihani ?
          </v-card-title>

          <v-card-text>
            <v-text-field
              v-model="cancelReasons"
              outlined
              class="mt-5"
              hint="Kuwa Makini, Kuhairishwa kwa Mtihani haiwezi kutenduliwa."
              persistent-hint
              placeholder="Sababu za kusitisha Mtihani"
              label="Sababu za kusitisha Mtihani"
            >
            </v-text-field>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="red darken-1"
              class="rounded-lg px-5 mb-3"
              text
              @click="confirmDialog = false"
            >
              Ghairi
            </v-btn>

            <v-btn
              color="green"
              class="rounded-lg px-5 mb-3"
              dark
              @click="submitCancelExamination"
            >
              Sitisha Mtihani
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar" :timeout="timeout">
        Mtihani wako umehairishwa kikamilifu
      </v-snackbar>
      <CreateBooking
        :CreateBookingDialog="CreateBookingDialog"
        @closeBookingDialog="CreateBookingDialog = false"
      />
    </v-row>
  </v-container>
</template>

<script>
import CreateBooking from "./CreateBooking.vue";
import allExaminationBookings from "../../../graphql/CandidateBooking/allExaminationBookings.graphql";
import bookingSystemCancellation from "../../../graphql/CandidateBooking/bookingSystemCancellation.graphql";
import { mapGetters } from "vuex";

export default {
  components: {
    CreateBooking,
  },
  data() {
    return {
      nobooking: false,
      confirmDialog: false,
      bookingNumber: null,
      cancelReasons: null,
      CreateBookingDialog: false,
      region: null,
      bookingDetails: [],
      snackbar: false,
      timeout: 4000,
    };
  },
  methods: {
    takeExam() {
      window.location.href = "/latradts_canditate/exam";
    },
    cancelBooking: function (bookingNumber) {
      this.bookingNumber = bookingNumber;
    },
    submitCancelExamination: function () {
      this.$apollo
        .mutate({
          mutation: bookingSystemCancellation,
          variables: {
            bookingNumber: this.bookingNumber,
            cancelReasons: this.cancelReasons,
          },
        })
        .then(() => {
          this.confirmDialog = false;
          this.snackbar = true;
          this.cancelReasons = "";
        });
    },
  },
  async mounted() {
    this.$apollo
      .query({
        fetchPolicy: "no-cache",
        query: allExaminationBookings,
        variables: {
          bookingCandidate: this.user.id,
        },
      })
      .then((response) => {
        this.bookingDetails = response.data.allExaminationBookings.edges;
      });
  },
  computed: {
    ...mapGetters({ authStatus: "authStatus", user: "user" }),
  },
  watch: {
    bookingDetails: function (newvalue) {
      console.log(newvalue.length);
      if (newvalue.length > 0) {
        this.nobooking = false;
      } else {
        this.nobooking = true;
      }
      console.log(this.nobooking, newvalue.length);
    },
  },
};
</script>

<style>
.d-flex {
  display: flex;
}
.justify-content-center {
  justify-content: center;
}
.flex-column {
  flex-direction: column;
}
.align-items-center {
  align-items: center;
}
.booking-number-orange {
  background: rgba(235, 136, 34, 0.2);
  padding: 4px 8px;
  border-radius: 3px;
}
</style>
