import gql from 'graphql-tag'

export const LOGIN_USER = gql`
mutation login($username: String!, $password: String!) {
  tokenAuth(username: $username, password: $password) {
    token
    user{
      id
      firstName
      lastName
      isStaff
      isActive
      email
      username
      userroleUser{
        edges{
          node{
            userroleRole{
              roleIsDefault
              roleDescriptions
              roleIsActive
              templateRole{
                edges{
                  node{
                    templatePermission{
                      permissionDescriptions
                      permissionIsActive
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

`
export const REGISTER_USER = gql`
mutation register($username: String!, $password: String!){
  register(email:$username,password1:$password, password2:$password, username:$username,firstName:"Fredrick",lastName:"Chami"){
    errors
    success
    token
    refreshToken
  }
}
`