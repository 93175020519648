import Vue from "vue";
import VueApollo from "vue-apollo";
import { setContext } from "apollo-link-context";
import { createApolloClient, restartWebsockets } from "vue-cli-plugin-apollo/graphql-client";
// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = "latra-dts-token";

// Http endpoint
const httpEndpoint =
  // process.env.VUE_APP_GRAPHQL_HTTP || "https://osat.all.co.tz:9006/graphql";
  // process.env.VUE_APP_GRAPHQL_HTTP || "https://osat.all.co.tz:9006/graphql";
  process.env.VUE_APP_GRAPHQL_HTTP || process.env.VUE_APP_GRAPHQL_URL;
  // Files URL root
export const filesRoot =
  process.env.VUE_APP_FILES_ROOT ||
  httpEndpoint.substr(0, httpEndpoint.indexOf("/graphql"));

Vue.prototype.$filesRoot = filesRoot;

// Config
const authLink = setContext(async (_, { headers }) => {
  // Use your async token function here:
  // const token = JSON.parse(localStorage.getItem('latra-dts-token'))
  // console.log(authLink)
  // Return the headers to the context so httpLink can read them
  // console.log(headers)
  return {
    headers: {
      ...headers,
      // authorization: token || ''
    }
  }
})
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  wsEndpoint: null,
  // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || "ws://172.17.17.96:8000/ws/exam/socket/graphql",
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  link: authLink,

  // Override default cache
  // cache: myCache

  // Override the way the Authorization header is set
  getAuth: tokenName => {
    let token='';
    if(localStorage.getItem(tokenName)){
      token = 'JWT ' + JSON.parse(localStorage.getItem(tokenName))
      // return the headers to the context so httpLink can read them

    }

    return token || ''
	}

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
};
// Create apollo client
export const { apolloClient, wsClient } = createApolloClient({
  ...defaultOptions,
  // ...options,
});

apolloClient.wsClient = wsClient;
// Call this in the Vue app file
export function createProvider() {
  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log(
        "%cError",
        "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
        error.message
      );
    },
  });

  return apolloProvider;
}

// Manually call this when user log in
export async function onLogin(apolloClient, token) {
  if (typeof localStorage !== "undefined" && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (login)", "color: orange;", e.message);
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem(AUTH_TOKEN);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (logout)", "color: orange;", e.message);
  }
}
