<template>
<v-container fluid>
        <CreateExaminationTimetable
            v-if="$store.getters.permissionMap.find(({permissionDescriptions})=> permissionDescriptions==='can_create_timetable_session')!==undefined"
            :CreateExaminationTimeTableDialog="CreateExaminationTimeTableDialog"
            @close="CreateExaminationTimeTableDialog=false"/>
        <EditExaminationTimeTable :EditExaminationTimetableDialog="editExaminationTimetableDialog" :EditExaminationTimetableItems="editExaminationTimetableItems" @closeEdit="editExaminationTimetableDialog=false"/>
        <EditGeneralSettings :EditGeneralSettingsDialog="editGeneralSettingsDialog" :GeneralSettingItems="ExaminationGeneralDuration" @close="editGeneralSettingsDialog=false"/>
        <PreviewExaminations :PreviewExaminationDialog="previewExaminationDialog" :PreviewExaminationItems="previewExaminationItems" @close="previewExaminationDialog=false"/>
 
  <v-row>
      <v-col sm="12">
        <v-tooltip bottom color="rgba(48, 67, 194, 0.76)">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-if="$store.getters.permissionMap.find(({permissionDescriptions})=> permissionDescriptions==='can_create_timetable_session')!==undefined"
                class="rounded-lg ml-2 text-capitalize"  color="rgba(48, 67, 194, 0.76) " dark  right style="margin:5px" v-bind="attrs" @click="CreateExaminationTimeTableDialog =true" v-on="on">
              <v-icon class="white--text" small>mdi-plus-circle</v-icon>  Create Timetable Session
            </v-btn>
          </template>
          <span>Create Examination Settings</span>
        </v-tooltip>
      </v-col>
      <v-col sm="12" lg="12">
        <div style="padding:10px">
            <template>
                <v-card>
                    <v-card-title>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                    </v-card-title>
                    <v-data-table
                    :headers="headers"
                    :items="$store.state.TimetableStore.allTimetables"
                    :search="search"
                    >
                      <template v-slot:item.timetableIsPublished="{ item }">
                        <v-btn v-if="item.timetableIsPublished" class="text-capitalize" color="success" depressed rounded x-small>Published</v-btn>
                        <v-btn v-else class="text-capitalize" color="error" depressed rounded x-small >Un-Published</v-btn>
                      </template>
                    <template v-slot:item.actions="{ item }">
                        <v-row>
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  color="primary"
                                  dark
                                  fab
                                  small
                                  text
                                  v-bind="attrs"
                                  v-on="on"
                              >
                                <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>
                            <v-list class="text-capitalize">
                              <v-list-item link @click="navigateToBookingList(item.actions,item.name,item.key,item.relayId)">
                                <v-list-item-title >
                                    <v-icon>mdi-eye</v-icon> View Bookings
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item link @click="previewExamination(item)">
                                <v-list-item-title >
                                  <v-icon>mdi-script</v-icon> View Examinations
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item link @click="openEditDialog(item)">
                                <v-list-item-title >
                                  <v-icon>mdi-pen</v-icon> Edit Timetable
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item link @click="openCancelSessionDialog(item)">
                                <v-list-item-title >
                                  <v-icon color="error">mdi-close</v-icon> Cancel Timetable
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item v-if="item.timetableIsPublished" link @click="publishExam(item,false)">
                                <v-list-item-title >
                                  <v-icon color="error">mdi-cancel</v-icon> Un Publish
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item v-else link @click="publishExam(item,true)">
                                <v-list-item-title >
                                  <v-icon color="success">mdi-check-circle</v-icon> Publish
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-row>
                    </template>
                    </v-data-table>
                </v-card>
            </template>
        </div>
      </v-col>
      <v-dialog
          v-model="cancelExamDialog"
          width="500"
      >

        <v-card>
          <v-card-title class="text-h5 error white--text">
            Cancel Timetable Session
          </v-card-title>

          <v-card-text>
            <h2 class="mt-4 mb-4">Are you sure you want to cancel this session?</h2>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                class="text-capitalize rounded-lg"
                color="error"
                text
                @click="cancelExamDialog = false"
            >
              No
            </v-btn>
            <v-btn
                class="text-capitalize rounded-lg px-4"
                color="primary"
                @click="cancelExam"
            >
              Cancel Session
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
</v-container>
</template>
<script>
import EditExaminationTimeTable from './EditTimetableSetting.vue'
import EditGeneralSettings from "./EditGeneralSettings";
import CreateExaminationTimetable from './CreateTimetableSetting.vue'
import PreviewExaminations from "./PreviewExaminations";

export default {
data:()=>({
      skill: 20,
      knowledge: 33,
      power: 78,
    CreateExaminationTimeTableDialog:false,
    editExaminationTimetableDialog:false,
    timetableName:'',
    cancelExamDialog:false,
    examDuration:'',
    ExaminationGeneralDuration:3,
    editGeneralSettingsDialog:false,
    previewExaminationDialog:false,
    previewExaminationItems:null,
    examNumberOfQuestions:'',
    examNumberOfSections:'',
    examNumberOfOptionPerQuestion:'',
    examExaminationFlow:'',
    examsettingName:'',
    cancelReason:'',
    TimetableUniqueId:'',
    examinationSettingsItems:null,
    search: '',
    headers: [
        // {
        // text: 'Timetable Name', value: 'name',
        // },
        { text: 'Timetable Name', value: 'name'},
        { text: 'Venue', value: 'venue' },
        { text: 'Date', value: 'date' },
        { text: 'Time', value: 'time' },
        { text: 'Status', value: 'timetableIsPublished' },
        { text: 'Slots', value: 'slots' },
        { text: 'Edit', value: 'actions' },

    ],
  editExaminationTimetableItems:null,
}),
components:{
    CreateExaminationTimetable,
    EditExaminationTimeTable,
    EditGeneralSettings,
    PreviewExaminations,
},
mounted(){
  if(this.$route.params.venueName!==undefined)
    this.search=this.$route.params.venueName
  this.$store.dispatch('LoadExaminations')
  this.$store.dispatch('LoadTimetables')
},
methods:{
  
    openEditDialog:function(value){
        console.log(value)
        this.editExaminationTimetableItems=value
        this.editExaminationTimetableDialog=true
    },

      navigateToBookingList(timetableUniqueId,timetableName,primaryKey,relayId){
            var selected={ timetableUniqueId: timetableUniqueId,timetableName:timetableName,primaryKey:primaryKey,relayId:relayId }
            this.$store.dispatch('updateselectedTimetableSession',selected)
            this.$store.dispatch('listAllBookingCandidate',relayId)
            this.$router.push({ name: 'ExaminationBookingList'})
        },

  publishExam(item,publishStatus){
      let data={
        timetableIsPublished:publishStatus,
        timetableName:item.name
      }
      this.$store.dispatch('publishTimetableSession',data)
    console.log(data)
  },
  openCancelSessionDialog(item){
    this.cancelExamDialog=true
    this.TimetableUniqueId=item.name
  },
  previewExamination(item){
      console.log(item)
    this.previewExaminationItems=item
    this.previewExaminationDialog=true
  },
  cancelExam(){
      let data = {
        timetableIsCancelationReasons: this.cancelReason,
        timetableName: this.TimetableUniqueId
      }
      this.$store.dispatch('cancelTimetable', data)
      this.cancelReason=""
      this.cancelExamDialog = false
  }
},
computed: {

}
}
</script>

<style>

</style>