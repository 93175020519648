<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <vue-json-to-csv
          :json-data="exportedData"
          :csv-title="'session_report'"
        >
          <v-btn text outlined small color="primary">Export Report</v-btn>
        </vue-json-to-csv>
        <v-divider class="mx-4" color="orange " inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="this.$store.state.ReportStore.examinationSession"
        class="elevation-1"
        :search="search"
        :custom-filter="filterOnlyCapsText"
      >
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import VueJsonToCsv from "vue-json-to-csv";
export default {
  components: {
    VueJsonToCsv,
  },
  data() {
    return {
      search: "",
    };
  },
  async mounted() {
    await this.$store.dispatch("getAllSessionsReports");
  },
  computed: {
    headers() {
      return [
        {
          text: "Session Name",
          align: "start",
          sortable: false,
          value: "sessionName",
        },
        { text: "Examination Start Time", value: "sessionTime" },
        { text: "Examination Name", value: "sessionExamination" },
        { text: "Registered Candidates", value: "sessionBookings" },
        { text: "Examined Candidates", value: "sessionCandidates" },
        { text: "Average Marks", value: "sessionAverageMarks" },
        { text: "Lowest Mark", value: "sessionLowestMarks" },
        { text: "Highest Mark", value: "sessionHighestMarks" },
        { text: "Bellow Pass Mark", value: "sessionBelowPassMarks" },
      ];
    },

    exportedData(){
      var data=[]
      this.$store.state.ReportStore.examinationSession.forEach(session => {
        data.push({
          "Session Name":session.sessionName,
          "Examination Start Time":session.sessionTime,
          "Examination Name":session.sessionExamination,
          "Registered Candidates":session.sessionBookings,
          "Examined Candidates":session.sessionCandidates,
          "Average Marks":session.sessionAverageMarks,
          "Lowest Mark":session.sessionLowestMarks,
          "Highest Mark":session.sessionHighestMarks,
          "Bellow Pass Mark":session.sessionBelowPassMarks,
        })
        
      });
      return data

    }

  },
  methods: {
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
  },
};
</script>

    
    
    
    
    
    
    
    
    
    