<template>
   <v-app id="inspire">
      <v-main>
         <v-container fluid fill-height>
            <v-layout align-center justify-center>
               <v-flex xs12 sm8 md5>
                  <v-card class="elevation-12 pb-5">
                     <v-toolbar dark color="rgba(48, 67, 194, 0.76)">
                        <v-toolbar-title dense>LATRA - Successfully Account Activation</v-toolbar-title>
                     </v-toolbar>
                     <v-card-text v-if="accountVerificationStatus">
                         <v-icon class="justify-center d-flex mt-5" color="success" x-large>mdi-check-circle</v-icon>
                         <h2 class="justify-center d-flex mt-5" style="text-align:center">Congratulation!,<br> Your account has been activated Successfully.</h2>
                           
                         <v-card-actions class="px-4 justify-center mt-5">
                            <v-btn class="white--text rounded-lg mb-3 mx-5 mt-5 auth-submit pa-5" color="rgba(48, 67, 194, 0.76)" @click="updatePassword" ><v-icon small>mdi-lock-open-outline</v-icon> Update Passowrd Here</v-btn>
                         </v-card-actions>
                     </v-card-text>
                    <v-card-text v-else>
                      <v-icon class="justify-center d-flex mt-5" color="error" x-large>mdi-close-circle</v-icon>
                      <h2 class="justify-center d-flex mt-5" style="text-align:center">Ooops!,<br> Looks Like Something Went Wrong, Click the button below to retry.</h2>

                      <v-card-actions class="px-4 justify-center mt-5">
                        <v-btn class="white--text rounded-lg mb-3 mx-5 mt-5 auth-submit pa-5" color="rgba(48, 67, 194, 0.76)" @click="retryPasswordVerification" ><v-icon small>mdi-lock-open-outline</v-icon> Activate Account</v-btn>
                      </v-card-actions>
                    </v-card-text>
                  </v-card>
               </v-flex>
            </v-layout>
         </v-container>
      </v-main>
   </v-app>
</template>

<script>
import verifyAccount from '../../../graphql/Authentication/verifyAccount.graphql'
import {mapActions} from 'vuex'

export default {
   name: 'Login',
   props: {
      source: String,
   },
   data () {
    return {
      authDetails: {
        username: '',
        password: ''
      },
      accountVerificationStatus:false

    }
   },
   methods: {
    ...mapActions(['login']),
    updatePassword: function () {
      this.$router.push('/password_reset/'+this.$route.params.email)
    },
     retryPasswordVerification: function(){
       this.$apollo.mutate({
         mutation:verifyAccount,
         variables:{
           token:this.$route.params.uuid
         }
       }).then((response)=>{
         this.accountVerificationStatus=response.data.verifyAccount.success
       })
     }
  },mounted() {
     this.$apollo.mutate({
       mutation:verifyAccount,
       variables:{
         token:this.$route.params.uuid.toString()
       }
     }).then((response)=>{
         this.accountVerificationStatus=response.data.verifyAccount.success
          if (!response.data.verifyAccount.success){
            if (response.data.verifyAccount.errors.nonFieldErrors[0].code==="already_verified"){
              this.accountVerificationStatus=true
            }
          }
     })
  }
};
</script>

<style></style>
