<template>
    <v-container fluid class="px-5">
      <v-toolbar
          flat
      >
          <v-tabs
              fixed-tabs
              slider-color="rgba(48, 67, 194, 0.76)"
              color="rgba(48, 67, 194, 0.76)"
              v-model="venueTabs"
          >
            <v-tab
                href="#tab-1"
            >
            Venues
            </v-tab>
            <v-tab
                href="#tab-2"
            >
              Regions
            </v-tab>
          </v-tabs>
      </v-toolbar>

      <v-tabs-items v-model="venueTabs">
        <v-tab-item value="tab-1">
          <v-row class="pa-5">
            <v-btn @click="AddNewCommitteesDialog =! AddNewCommitteesDialog"  dark  right color="rgba(48, 67, 194, 0.76) " class="rounded-lg mb-4 ml-md-auto ml-xs-0">
              <v-icon class="white--text">mdi-home-plus</v-icon>  Add Examination Venue
            </v-btn>
            <v-card width="100%">
              <v-card-title>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    hide-details
                    label="Search"
                    single-line
                ></v-text-field>
              </v-card-title>
              <v-data-table
                  :headers="headers"
                  :items="$store.state.VenueStore.allVenues"
                  :search="search"
              >
                <template v-slot:item.isActive="{item}">
                  <v-btn v-if="item.isActive" class="text-capitalize" color="success" depressed rounded x-small>
                    <v-icon elevation="0" small>mdi-checkbox-marked-circle-outline</v-icon> Active
                  </v-btn>
                  <v-btn v-else class="text-capitalize" color="error" depressed rounded x-small>
                    <v-icon small>mdi-close-circle</v-icon> In-active
                  </v-btn>
                </template>
                <template v-slot:item.venueName="{item}">
                <span class="font-weight-medium">
                  {{item.venueName}}
                </span><br/>
                  <span class="grey--text">Region: {{item.region.regionName}}</span>
                </template>
                <template v-slot:item.available="{item}">
                <span class="font-weight-medium">
                  Capacity: {{item.capacity}}
                </span><br/>
                  <span class="grey--text">Slots: {{item.available}}</span>
                </template>
                <template v-slot:item.nextExamination="{item}">
                <span v-if="item.nextExamination.date">
                  <span class="font-weight-medium">
                  Date: {{item.nextExamination.date}}
                </span><br/>
                <span class="grey--text">Time: {{item.nextExamination.time}}HRS</span>
                </span>
                  <span v-else>
                  <v-btn v-if="item.isActive" class="text-capitalize" color="error" depressed rounded x-small>
                  <v-icon elevation="0" small>mdi-close-circle</v-icon> None
                </v-btn>
                </span>
                </template>
                <template v-slot:item.actions="{item}">
                  <v-menu  app offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn  slot="activator" color="rgba(48, 67, 194, 0.76)" icon   v-bind="attrs"    v-on="on" ><v-icon>mdi-dots-vertical</v-icon></v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item link @click="openEditVenueDialog(item)">
                        <v-list-item-title><v-icon style="height: 16px;font-size: 16px;" >mdi-book-edit-outline</v-icon> Edit Venue Informations</v-list-item-title>
                      </v-list-item>
                      <v-list-item link @click="showTimetables(item.venueName)" >
                        <v-list-item-title><v-icon style="height: 16px;font-size: 16px;">mdi-view-grid-outline</v-icon>  View Timetable</v-list-item-title>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item v-if="item.isActive" link @click="changeVenueActiveStatus(item)">
                        <v-list-item-title><v-icon style="height: 16px;font-size: 16px;color:red">mdi-close</v-icon> Deactivate Venue</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-else link @click="changeVenueActiveStatus(item)">
                        <v-list-item-title><v-icon color="success" style="height: 16px;font-size: 16px;">mdi-check-all</v-icon> Activate Venue</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </v-card>
          </v-row>
        </v-tab-item>
        <v-tab-item value="tab-2">
          <v-row class="pa-5">
              <v-btn @click="AddNewRegionDialog =true"  dark color="rgba(48, 67, 194, 0.76) " class="rounded-lg mb-4 ml-md-auto ml-xs-0">
                <v-icon class="white--text" small>mdi-map-marker</v-icon>  Create Region
              </v-btn>
            <v-card width="100%">
              <v-card-title>
                <v-text-field
                    v-model="searchRegions"
                    append-icon="mdi-magnify"
                    hide-details
                    label="Search"
                    single-line
                ></v-text-field>
              </v-card-title>
              <v-data-table
                  :headers="headersRegions"
                  :items="$store.state.RegionsStore.allRegions"
                  :search="searchRegions"
              >
                <template v-slot:item.regionIsActive="{item}">
                  <v-btn v-if="item.regionIsActive" class="text-capitalize" color="success" depressed rounded x-small>
                    <v-icon elevation="0" small>mdi-checkbox-marked-circle-outline</v-icon> Active
                  </v-btn>
                  <v-btn v-else class="text-capitalize" color="error" depressed rounded x-small>
                    <v-icon small>mdi-close-circle</v-icon> In-active
                  </v-btn>
                </template>
                <template v-slot:item.actions="{item}">
                  <v-btn fab text color="primary" small @click="openEditRegionDialog(item)">
                    <v-icon small>mdi-pen</v-icon>
                  </v-btn>
                  <v-btn v-if="item.regionIsActive" class="rounded-xl"  fab text color="success" small @click="deactivateRegion(item)">
                    <v-icon small>mdi-checkbox-marked-circle-outline</v-icon>
                  </v-btn>
                  <v-btn v-else fab text color="error" small @click="deactivateRegion(item)">
                    <v-icon small>mdi-cancel</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
        <add-new-venues :AddNewCommitteesDialog='AddNewCommitteesDialog' @close="AddNewCommitteesDialog=false"></add-new-venues>
        <AddNewRegions :AddNewRegionDialog="AddNewRegionDialog" @close="AddNewRegionDialog=false"/>
        <EditVenues :EditVenueDialog="editVenueDialog" :EditVenueItems="editVenueItems" @close="editVenueDialog=false"/>
        <EditRegions :EditRegionDialog="editRegionDialog" :EditRegionItems="editRegionItems" @close="editRegionDialog=false"/>
    </v-container>
</template>

<script>
import AddNewVenues from './AddNewVenues.vue'
import AddNewRegions from './AddNewRegions.vue'
import EditVenues from "./EditVenues";
import EditRegions from "./EditRegion";

export default {
    components:{
      AddNewVenues,
      AddNewRegions,
      EditVenues,
      EditRegions,
    },
    data(){
        return{
          venueTabs:null,
          search:'',
          searchRegions:'',
          editVenueDialog:false,
          editVenueItems:null,
          editRegionDialog:false,
          editRegionItems:null,
          headers:[
            { text: 'Venue Name', value: 'venueName' },
            { text: 'Capacity/Available Slots', value: 'available' },
            { text: 'Active Status', value: 'isActive' },
            { text: 'Next Exam', value: 'nextExamination' },
            { text: 'Actions', value: 'actions' },
          ],
          headersRegions:[
            { text: 'Region Name', value: 'regionName' },
            { text: 'Active Status', value: 'regionIsActive' },
            { text: 'Actions', value: 'actions' },
          ],
          AddNewCommitteesDialog:false,
          AddNewRegionDialog:false,
          examinationVenues:[],
        }
    },mounted(){
      this.$store.dispatch('LoadVenues')
      this.$store.dispatch('LoadRegionsAction')
    },methods:{

    openEditVenueDialog(values){
      this.editVenueDialog=true
      this.editVenueItems=values
    },
    openEditRegionDialog(regions){
      this.editRegionDialog=true
      this.editRegionItems=regions
    },
    deactivateRegion(regions){
      let data={
        regionUniqueId:regions.regionUniqueId,
        regionName:regions.regionName,
        regionIsActive:!regions.regionIsActive,
      }
      this.$store.dispatch('EditRegionsAction',data)
    },
    changeVenueActiveStatus(item){
      console.log(item)
      let data={
        venuesUniqueId:item.venuesUniqueId,
        venuesIsActive:!item.isActive,
      }
      this.$store.dispatch('ChangeVenueActiveStatus',data)
    },showTimetables(venueName){
      this.$router.push("/latradts_settings/timetable/"+venueName)
    }
  }
    }
</script>

<style>

</style>