<template>
  <v-row justify="center">
    <v-dialog v-model="uploadDialog" max-width="600">
      <v-card :loading="uploadLoadingCard" class="pa-3">
        <v-card-title class="text-h5"> Uploading Questions </v-card-title>

        <v-card-text>
          Make sure you are using the right question bank template and that you
          have adhered to the format that have been directed. After you're done
          just press Done

          <vue-dropzone
            id="dropzone"
            ref="myVueDropzone"
            v-model="fileUpload"
            :options="dropzoneOptions"
            class="mt-3"
            @vdropzone-file-added="uploadLoadingFile"
          ></vue-dropzone>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            class="text-capitalize rounded-lg"
            dark
            @click="
              uploadLoading = false;
              uploadLoadingCard = false;
              $emit('close');
            "
          >
            Done
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import uploadQuestionsBank from "../../../graphql/dts_questions_graphql/uploadQuestion.gql";
import Vue from "vue";

export default {
  props: ["uploadDialog", "categoryId","categoryRelayID"],
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      uploadLoading: false,
      uploadLoadingCard: false,
      fileUpload: null,
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        autoProcessQueue: false,
        thumbnailWidth: 150,
        maxFilesize: 60,
        headers: { "My-Awesome-Header": "header value" },
      },
    };
  },
  methods: {
    uploadLoadingFile(file) {
      this.uploadLoading = true;
      this.uploadLoadingCard = true;
      console.log(file);
      this.$apollo
        .mutate({
          mutation: uploadQuestionsBank,
          variables: {
            file: file,
            questionCategory: this.categoryId,
          },
        })
        .then((response) => {
          console.log(response);
          this.uploadLoading = false;
          this.uploadLoadingCard = false;
          this.fileUpload = null;
          Vue.swal({
            toast: true,
            position: "top-right",
            title: "Question Bank Uploaded Successfully!",
            timerProgressBar: true,
            timer: 15000,
            icon: "success",
            showConfirmButton: false,
            animation: false,
          });
          let dataVariables = {
            questionCategory: this.categoryRelayID,
            limit: 1000,
            offset: 0,
          };
          this.$store.dispatch("loadCategoryQuestions", dataVariables);
        });
    },
  },
};
</script>

<style></style>
