<template>
  <v-app  style="background: url('/flag_login.png') no-repeat; background-size:cover">
    <v-main style="background: url('~@/assets/flag_login.png') no-repeat center center fixed !important">
      <p>{{user.name}}</p>
      <router-view />
    </v-main>
  </v-app>
</template>
<style>
#app{
  /* background: url('~@/assets/flag_login.png') no-repeat center center fixed !important; */
  background-size: cover;
}
</style>
<script>
import {mapGetters} from 'vuex'
import * as echarts from "echarts";

export default {
  name: "App",
  components:{
  },
  data: () => ({
    //
    token:null
  }),
  methods: {
    logOut: function () {
      this.$store.dispatch('logOut')
        .then(() => this.$router.push('/login'))
    }

  },
  computed: {
    ...mapGetters(['authStatus', 'user'])
  },
    mounted() {
    var dom = document.getElementById("container");
    var myChart = echarts.init(dom, null, {
      renderer: "canvas",
      useDirtyRect: false,
    });
    if (this.option && typeof this.option === "object") {
      myChart.setOption(this.option);
    }
  },

};
</script>
