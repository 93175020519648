<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <vue-json-to-csv :json-data="candidateResults" :csv-title="'candidate'">
          <v-btn text outlined small color="primary">Export Report</v-btn>
        </vue-json-to-csv>

        <v-divider class="mx-4" color="orange " inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :expanded.sync="expanded"
        show-expand
        :headers="headers"
        :items="this.$store.state.ReportStore.candidateReport"
        item-key="candidateLastName"
        class="elevation-1"
        :search="search"
        :custom-filter="filterOnlyCapsText"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <ul>
              <li
                v-for="examination in item.Examination"
                :key="`examination` + examination.resultsBooking"
              >
                <span>Examination: {{ examination.resultsExamination }}</span
                >,&nbsp;
                <span>Booking Number: {{ examination.resultsBooking }}</span
                >,&nbsp; <span>Remarks: {{ examination.resultsType }}</span
                >,&nbsp;
                <span>Marks: {{ examination.resultsMarksAttained }}</span
                >&nbsp;
              </li>
            </ul>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import VueJsonToCsv from "vue-json-to-csv";
export default {
  components: {
    VueJsonToCsv,
  },
  data() {
    return {
      search: "",
      expanded: [],
    };
  },
  async mounted() {
    await this.$store.dispatch("AllCandidateReport");
  },

  computed: {
    headers() {
      return [
        {
          text: "Candidate Name",
          align: "start",
          sortable: false,
          value: "candidateFirstName",
        },
        { text: " Exam Number", value: "Examination[0].resultsBooking" },
        { text: " Exam Name", value: "Examination[0].resultsExamination" },
        { text: "Marks", value: "Examination[0].resultsMarksAttained" },
        { text: "Status/Remarks", value: "Examination[0].resultsType" },
      ];
    },

    examination: function () {
      return this.$store.state.ReportStore.candidateReport;
    },

    candidateResults() {
      var temp_list = [];
      this.$store.state.ReportStore.candidateReport.forEach((candidate) => {
        console.log(candidate.Examination[0]);
        var resultsBooking = "";
        var resultsExamination = "";
        var resultsMarksAttained = "";
        var resultsType = "";
        if (candidate.Examination.length > 0) {
          resultsBooking = candidate.Examination[0].resultsBooking;
          resultsExamination = candidate.Examination[0].resultsExamination;
          resultsMarksAttained = candidate.Examination[0].resultsMarksAttained;
          resultsType = candidate.Examination[0].resultsType;
        }

        temp_list.push({
          Fullname: candidate.candidateFirstName,
          "Examination Number": resultsBooking,
          "Examination Name": resultsExamination,
          Marks: resultsMarksAttained,
          Remarks: resultsType,
        });
      });
      return temp_list;
    },
  },
  methods: {
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
  },
};
</script>
