// import allCategoryQuestions from '../../graphql/dts_questions_graphql/allCategoryQuestions.gql'
// import createQuestion from '../../graphql/dts_questions_graphql/createQuestion.gql'
// import allQuestionDifficulties from '../../graphql/dts_questions_graphql/allQuestionDifficulties.gql'
import {apolloClient} from '@/vue-apollo'
import allQuestionCategories from '../../graphql/allQuestionCategories.gql'
import updateQuestionCategory from '../../graphql/updateQuestionCategory.gql'
import Vue from "vue";
import createQuestionCategory from "../../graphql/createQuestionCategory.gql";

export default {
    state: {
        categorylist: [],
        selectedCategory:null
    },
    
    mutations: {
        setSelectedCategory(state,category){
            state.selectedCategory=category
        },        
        addCategory(state,category){
            state.categorylist.push(category)
        },
        deleteCategory(state){
            state.categorylist=[]
        },
        editCategory(state,category){
            state.categorylist.find(({ key }) => key === category.key ).relayId=category.relayId
            state.categorylist.find(({ key }) => key === category.key ).name=category.name
            state.categorylist.find(({ key }) => key === category.key ).active_status=category.active_status
        },
    },
    actions: {
        async updateSelectedCategory(context,category) {
            context.commit('setSelectedCategory',category)
        },

        async loadCategoriesFromServer(context){
                context.commit('deleteCategory')
                apolloClient.query({
                    fetchPolicy:"no-cache",
                    query:allQuestionCategories
                }).then((response)=>{

                    let questionCategories=response.data.allQuestionCategories.edges
                    questionCategories.forEach(element => {
                        context.commit('addCategory',
                            {
                                'key':element.node.primaryKey,
                                'name':element.node.categoryName,
                                'active_status':element.node.categoryIsActive,
                                'created_date':new Date(element.node.categoryCreateddate).toString().substr(0,21),
                                'actions':element.node.categoryUniqueId,
                                'relayId':element.node.id,
                                'questionNo':0
                            })
                    });
                })
        },
        async updateQuestionCategory(context,data){
            apolloClient.mutate({
                mutation:updateQuestionCategory,
                variables:data
            }).then((response)=>{
                context.commit('editCategory',
                    {
                        'key':response.data.updateQuestionCategory.updatedCategory.primaryKey,
                        'name':response.data.updateQuestionCategory.updatedCategory.categoryName,
                        'active_status':response.data.updateQuestionCategory.updatedCategory.categoryIsActive,
                        'created_date':new Date(response.data.updateQuestionCategory.updatedCategory.categoryCreateddate).toString().substr(0,21),
                        'actions':response.data.updateQuestionCategory.updatedCategory.categoryUniqueId,
                        'relayId':response.data.updateQuestionCategory.updatedCategory.id,
                        'questionNo':0
                    }
                )
                Vue.swal({
                    toast:true,
                    position:'top-right',
                    title:'Category update Successfully!',
                    timerProgressBar:true,
                    timer:10000,
                    icon:'success',
                    showConfirmButton:false,
                    animation:false,
                })
                return response
            })
        },
        async createQuestionCategory(context,data){
            apolloClient.mutate({
                mutation:createQuestionCategory,
                variables:data
            }).then((response)=>{
                context.commit('addCategory',
                    {
                        'key':response.data.createQuestionCategory.category.primaryKey,
                        'name':response.data.createQuestionCategory.category.categoryName,
                        'active_status':response.data.createQuestionCategory.category.categoryIsActive,
                        'created_date':new Date(response.data.createQuestionCategory.category.categoryCreateddate).toString().substr(0,21),
                        'actions':response.data.createQuestionCategory.category.categoryUniqueId,
                        'relayId':response.data.createQuestionCategory.category.id,
                        'questionNo':0
                    })
                Vue.swal({
                    toast:true,
                    position:'top-right',
                    title:'Category created Successfully!',
                    timerProgressBar:true,
                    timer:10000,
                    icon:'success',
                    showConfirmButton:false,
                    animation:false,
                })
                return response
            })
        },
    },
}

