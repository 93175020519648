<template>
  <v-row justify="center">
    <v-spacer></v-spacer>
    <v-dialog
      v-model="CreateQuestionCategory"
      max-width="500"
    >

   
   
      <template>
      <v-card class="pa-3" >
        <v-card-title class="text-h5">
          Create Question Category
        </v-card-title>

        <v-card-text>
          <v-text-field
          class="mt-5 mb-0"
            v-model="categoryName"
            label="Category Name"
            placeholder="Category Name"
            outlined
            required
          ></v-text-field>
        </v-card-text>

        <v-card-actions class="mb-3">
          <v-spacer></v-spacer>

          <v-btn
            color="red darken-1"
            text
            @click="$emit('close')"
          >
            <v-icon small>mdi-close</v-icon> Cancel
          </v-btn>

          <v-btn
            color="orange"
            dark
            @click="createQuestionCategory()"
          >
            <v-icon small>mdi-checkbox-marked-circle-outline</v-icon> Create Category
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
    
    
    </v-dialog>
  </v-row>
</template>

<script>
export default {
     data: () => ({
        categoryName:'',
    }),
  props:['CreateQuestionCategory'],
   methods:{
       createQuestionCategory:function(){
         let data={
           categoryName:this.categoryName
         }
         this.$store.dispatch('createQuestionCategory',data).then(()=>{

           this.CreateQuestionCategory=false
           this.categoryName=''
         })

       },
   }
}
</script>

<style>

</style>