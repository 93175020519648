<template>
  <v-row>
    <EditUser
      :EditUserDialog="editUserDialog"
      :EditUserItems="editUserItems"
      @close="editUserDialog = false"
    />
    <v-col cols="12" sm="12">
      <v-card class="pa-3 mt-2" flat max-width="100%">
        <v-card-text>
          <template>
            <v-card>
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  hide-details
                  label="Search"
                  single-line
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="$store.state.UserStore.allStaffs"
                :search="search"
              >
                <template
                  v-slot:item.name="{ item }"
                  style="font-family: Poppins, Helvetica, Arial, sans-serif"
                >
                  <span class="font-weight-regular" style="font-size: large">
                    {{ item.name }}
                    <v-icon v-if="item.superUser" color="primary" small
                      >mdi-check-decagram</v-icon
                    > </span
                  ><br />
                  <span class="text-caption" style="color: #777">{{
                    item.email
                  }}</span>
                </template>
                <template v-slot:item.activeStatus="{ item }">
                  <v-btn
                    v-if="item.activeStatus"
                    color="#C8E6C9"
                    depressed
                    rounded
                    x-small
                  >
                    <v-icon color="success" x-small>mdi-check-circle</v-icon>
                    <span class="text-capitalize green--text text-capitalize"
                      >Active</span
                    >
                  </v-btn>
                  <v-btn v-else color="#FFCDD2" depressed rounded x-small>
                    <v-icon color="error" x-small>mdi-close-circle</v-icon>
                    <span class="text-capitalize red--text text-capitalize"
                      >Deactivated</span
                    >
                  </v-btn>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-menu app offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        slot="activator"
                        color="rgba(39, 166, 245, 0.53)"
                        icon
                        v-bind="attrs"
                        v-on="on"
                        ><v-icon>mdi-dots-vertical</v-icon></v-btn
                      >
                    </template>
                    <v-list dense>
                      <v-list-item link @click="openEditUserDialog(item)">
                        <v-list-item-title
                          ><v-icon style="height: 16px; font-size: 16px"
                            >mdi-account-edit</v-icon
                          >
                          Edit User</v-list-item-title
                        ></v-list-item
                      >
                      <v-list-item link>
                        <v-list-item-title
                          ><v-icon style="height: 16px; font-size: 16px"
                            >mdi-account-tie</v-icon
                          >
                          User Profile</v-list-item-title
                        >
                      </v-list-item>

                      <v-list-item link>
                        <v-list-item-title
                          ><v-icon style="height: 16px; font-size: 16px"
                            >mdi-sync</v-icon
                          >
                          Reset Password</v-list-item-title
                        >
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item link>
                        <v-list-item-title
                          v-if="!item.activeStatus"
                          @click="
                            updateUserActiveStatus(
                              item.email,
                              !item.activeStatus
                            )
                          "
                          ><v-icon
                            style="height: 16px; font-size: 16px; color: green"
                            >mdi-check-circle</v-icon
                          >
                          Activate</v-list-item-title
                        >
                        <v-list-item-title
                          v-else-if="item.activeStatus"
                          @click="
                            updateUserActiveStatus(
                              item.email,
                              !item.activeStatus
                            )
                          "
                          ><v-icon
                            style="height: 16px; font-size: 16px; color: red"
                            >mdi-close-circle</v-icon
                          >
                          Deactivate</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
              <div class="text-center pt-2">
                Total: {{ $store.state.UserStore.totalCount }}
                <v-pagination
                  v-model="page"
                  :length="$store.state.UserStore.totalPages"
                ></v-pagination>
              </div>
            </v-card>
          </template>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import EditUser from "./EditUser.vue";

export default {
  components: {
    EditUser,
  },
  props: ["boardMembers"],
  data() {
    return {
      editUserDialog: false,
      editUserItems: null,
      search: "",
      headers: [
        {
          text: "User",
          align: "start",
          value: "name",
        },
        { text: "Active Status", value: "activeStatus" },
        { text: "Role", value: "role.roleDescriptions" },
        { text: "Date Joined", value: "date" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  async mounted() {
    this.$store.dispatch('LoadCandidate')
    this.$store.dispatch('LoadStaff')
    console.log(this.$store.state.UserStore.allStaffs);
  },
  methods: {
    updateUserActiveStatus: function (username, isActive) {
      let data = {
        username: username,
        activeStatus: isActive,
      };
      this.$store.dispatch("EditUserActiveStatusAdmin", data);
    },
    openEditUserDialog(itemContent) {
      this.editUserDialog = true;
      this.editUserItems = itemContent;
    },
  },
};
</script>

<style></style>
