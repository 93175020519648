<template>
  <v-row justify="center">
    <v-dialog
      v-model="EditBookingSettingDialog"
      persistent
      max-width="60%"
    >
      <v-card>
        <v-card-title>
          <span class="text-h7"><v-icon>mdi-home-plus</v-icon> Edit Booking Setting</span>
        </v-card-title>
        <v-divider></v-divider>
            <v-card-text>
                    <v-container>
                    <v-row>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
                   <v-text-field
                        v-model="settingsMaximumBookingTime"
                        label="Maximum Booking Time *"
                        required
                        prepend-icon="mdi-book-clock"
                        type="number"
                    >
                     </v-text-field>
                     <v-text-field
                        v-model="settingsMaximumCancelationTime"
                        label="Maximum Cancelation Time *"
                        required
                        prepend-icon="mdi-cancel"
                        type="number"
                    >
                    </v-text-field>
                    </v-col>
                        </v-row>
                    </v-container>
                    <v-divider></v-divider>
            </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            fab
            small
            class="white--text"
            
           @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            color="rgba(48, 67, 194, 0.76)"
            fab
            small
            class="white--text"
           @click="updateBookingSettings"
          >
            <v-icon>mdi-check-all</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <LocationSettings :openLocationDialog="openLocationDialog" @close="openLocationDialog =! openLocationDialog"/> -->
  </v-row>
</template>


<script>

  export default {
    components:{
        
    },
    data: () => ({
        checkbox:false,
        settingsMaximumBookingTime:null,
        settingsMaximumCancelationTime:null,
        settingsUniqueId:null,
    }),
    props: ["EditBookingSettingDialog","BookingSettingsItems"],
    mounted(){
      
    },
    
    methods:{
      updateBookingSettings: function(){
        let data={
          settingsMaximumBookingTime:this.settingsMaximumBookingTime,
          settingsMaximumCancelationTime:this.settingsMaximumCancelationTime,
          settingsSettingUniqueId:this.settingsUniqueId
        }
        this.$store.dispatch('EditBookingSetting',data).then(()=>{
          this.$emit('close')
        })
      }
      
    },watch:{
      EditBookingSettingDialog:function(){
        this.settingsMaximumBookingTime=this.BookingSettingsItems.max_book_time
        this.settingsMaximumCancelationTime=this.BookingSettingsItems.max_cancel_time
        this.settingsUniqueId=this.BookingSettingsItems.unique_id
      }
    }
  }
</script>