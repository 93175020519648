import { apolloClient, onLogout } from "@/vue-apollo";
import { LOGGED_IN_USER } from "@/graphql/queries";
import { LOGIN_USER, REGISTER_USER } from "@/graphql/mutations";
import router from "../router/index.js";
import Vue from "vue";
import sendPasswordResetEmail from "../graphql/sendPasswordResetEmail.gql";
import passwordReset from '../graphql/passwordReset.gql'
import swal from "sweetalert2";

export default {
  state: {
    token: localStorage.getItem("latra-dts-token") || null,
    user: {},
    role: "",
    roleIsActive: false,
    authStatus: false,
    permissionMap: [],
    permissions: [],
  },
  getters: {
    isAuthenticated: (state) => !!state.token,
    authStatus: (state) => state.authStatus,
    user: (state) => state.user,
    permissionMap: (state) => state.permissionMap,
    permissions: (state) => state.permissions,
    roleIsActive: (state) => state.roleIsActive,
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    LOGIN_USER(state, user) {
      state.authStatus = true;
      state.user = { ...user };
    },
    LOGOUT_USER(state) {
      state.authStatus = "";
      state.token = "" && localStorage.removeItem("latra-dts-token");
      state = null;
    },
    SET_ROLE_AND_PERMISSION(state, role_and_permission) {
      state.permissionMap = role_and_permission.permissionList;
      state.permissions = [];
      role_and_permission.permissionList.forEach((permision) => {
        state.permissions.push(permision.permissionDescriptions);
      });
      state.role = role_and_permission.role;
      state.roleIsActive = role_and_permission.roleIsActive;
      console.log(state.roleIsActive);
    },
  },
  actions: {
    async register({ commit }, authDetails) {
      try {
        const { data } = await apolloClient.mutate({
          mutation: REGISTER_USER,
          variables: { ...authDetails },
        });
        const token = JSON.stringify(data.createUser.token);
        commit("SET_TOKEN", token);
        // localStorage.setItem('latra-dts-token', token)
        // dispatch('setUser')
      } catch (e) {
        console.log(e);
      }
    },
    async login({ commit }, authDetails) {
      try {
        const { data, error } = await apolloClient.mutate({
          mutation: LOGIN_USER,
          variables: { ...authDetails },
        });
        if (data.tokenAuth) {
          const token = JSON.stringify(data.tokenAuth.token);
          commit("SET_TOKEN", token);
          let allPermissions = [];
          data.tokenAuth.user.userroleUser.edges[0].node.userroleRole.templateRole.edges.forEach(
            (permissions) => {
              if (permissions.node.templatePermission.permissionIsActive)
                allPermissions.push(permissions.node.templatePermission);
            }
          );
          let role_and_permission = {
            role: data.tokenAuth.user.userroleUser.edges[0].node.userroleRole
              .roleDescriptions,
            roleIsActive:
              data.tokenAuth.user.userroleUser.edges[0].node.userroleRole
                .roleIsActive,
            permissionList: allPermissions,
          };
          commit("LOGIN_USER", data.tokenAuth.user);
          commit("SET_ROLE_AND_PERMISSION", role_and_permission);

          localStorage.setItem("latra-dts-token", token);
          router.push("/");
        } else {
          console.log(error);
        }
      } catch (e) {
        console.log(e);
        Vue.swal({
          toast: true,
          position: "top",
          title: "Wrong Credentials",
          text: "It looks like either the e-mail or password you entered is wrong. Please try again.",
          timerProgressBar: true,
          timer: 10000,
          icon: "error",
          showConfirmButton: false,
          animation: false,
        });
      }
    },
    async setUser({ commit }) {
      const { data } = await apolloClient.query({ query: LOGGED_IN_USER });
      commit("LOGIN_USER", data.me);
      // console.log(data.allUsers.edges[0].node);
    },
    async logOut({ commit }) {
      // console.log("Wee have been logged out")
      commit("LOGOUT_USER");
      onLogout(apolloClient);
    },
    async sendPasswordResetEmail(context, email) {
      try {
        const { data } = await apolloClient.mutate({
          mutation: sendPasswordResetEmail,
          variables: {
            email: email,
          },
        });
        if (data.sendPasswordResetEmail.success) {
          swal.fire({
            toast: true,
            icon: "success",
            title:
              "Success . . . ! \nThe email has been sent to your account successfuly.",
            animation: false,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          swal.fire({
            toast: true,
            icon: "error",
            title:
              "Sorry - that didn't work. The provided email was not valid.\n Please contact your institution administrator.",
            animation: false,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
        this.$router.push("/login");
      } catch (e) {
        console.log(e);
      }
    },
    async passwordReset(context, passwordData) {
      try {
        const { data } = await apolloClient.mutate({
          mutation: passwordReset,
          variables: {
            newPassword1: passwordData.newPassword1,
            newPassword2: passwordData.newPassword2,
            token: passwordData.token,
          },
        });
        if (data.passwordReset.success) {
          swal.fire({
            toast: true,
            icon: "success",
            title:
              "Success . . . ! \nThe password has been changed successfuly.",
            animation: false,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          swal.fire({
            toast: true,
            icon: "error",
            title:
              "Sorry - that didn't work. The provided email was not valid.\n Please contact your institution administrator.",
            animation: false,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
        this.$router.push("/login");
      } catch (e) {
        console.log(e);
      }
    },
  },
};
