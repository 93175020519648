import CordinatorMain from './CordinatorMain.vue'
import QuestionList from './question_bank/QuestionList.vue'
import QuestionBank from './question_bank/QuestionBank.vue'
import questionReport from '../latradts_report/questionReport.vue'

export default [
    {
        path: '/latradts',
        name:'CordinatorMain',
        component: CordinatorMain,
        meta: { requiresAuth: true },
        children:[
            {
                path:'questionReport',
                name:'',
                component:questionReport,
            },
            {
                path:'question_bank',
                name:'',
                component:QuestionBank,
            },
            {
                path:'questions',
                name:'QuestionsList',
                component:QuestionList,
            }
        ]
     },    
]