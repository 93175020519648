import { apolloClient } from "@/vue-apollo";
import questionReport from "../../graphql/Report_Graphql/QuestionReport/questionReport.gql";
import getSessionsReports from "../../graphql/Report_Graphql/SessionReport/getSessionsReports.gql";
import performanceReport from "../../graphql/Report_Graphql/ResultPerfomanceReport/performanceReport.gql";
import personalReport from "../../graphql/Report_Graphql/PersonalReport/personalReport.gql";
import testCentersReport from "../../graphql/Report_Graphql/TestCenterReport/testCentersReport.gql";

export default {
  state: {
    questionCount: null,
    examinationSession: null,
    resultPerformance: null,
    candidateReport: [],
    centerReport: null,
  },

  mutations: {
    addquestionCount(state, question) {
      state.questionCount = question;
    },

    examinationSessionCount(state, session) {
      state.examinationSession=session;
    },

    resultPerformanceCount(state, results) {
      state.resultPerformance = results;
    },

    candidateReportCount(state, candidate) {
      state.candidateReport.push(candidate);
    },

    testCenterReport(state, center) {
      state.centerReport.push(center);
    },

    clearquestionCount(state) {
      state.questionCount = [];
    },
    clearSession(state) {
      state.examinationSession = [];
    },
    clearresults(state) {
      state.resultPerformance = [];
    },
    clearcandidateReport(state) {
      state.candidateReport = [];
    },
    clearCenterReport(state) {
      state.centerReport = [];
    },
  },

  actions: {
    async Allquestion(context) {
      context.commit("clearquestionCount");

      apolloClient
        .query({
          fetchPolicy: "no-cache",
          query: questionReport,
        })
        .then((response) => {
          let index = 0;
          var questionCount = response.data.questionsReport;
          context.commit("addquestionCount", {
            serial_number: index + 1,
            totalQuestionsCategories: questionCount.totalQuestionsCategories,
            totalQuestion: questionCount.totalQuestion,
            totalAskedQuestions: questionCount.totalAskedQuestions,
            AskedQuestions: questionCount.askedQuestions,
            categoryWithMostQuestions: questionCount.categoryWithMostQuestions,
            mostAppearedQuestions: questionCount.mostAppearedQuestions,
            mostCorrectQuestions: questionCount.mostCorrectQuestions,
            mostMissedQuestions: questionCount.mostMissedQuestions,
          });
          index++;
        });
    },
    async getAllSessionsReports(context) {
      await apolloClient
        .query({
          fetchPolicy: "no-cache",
          query: getSessionsReports,
        })
        .then((response) => {
          context.commit("examinationSessionCount",response.data.getSessionsReports)
          });
    },
    async AllresultPeformance(context) {
      context.commit("clearresults");

      apolloClient
        .query({
          fetchPolicy: "no-cache",
          query: performanceReport,
        })
        .then((response) => {
          var resultPerformance = response.data.performanceReport;
          context.commit("resultPerformanceCount", {
            ExaminationCandidates: resultPerformance.totalExaminationCandidates,
            examTypes: resultPerformance.examinationTypes,
            examinationResultsTypes: resultPerformance.examinationResultsTypes,
          });
        });
    },

    async AllCandidateReport(context) {
      context.commit("clearcandidateReport");

      apolloClient
        .query({
          fetchPolicy: "no-cache",
          query: personalReport,
        })
        .then((response) => {
          var candidateReport = response.data.personalReport;
          console.log(candidateReport);
          candidateReport.forEach((element) => {
            context.commit("candidateReportCount", {
              candidateFirstName:
                element.candidateFirstName + " " + element.candidateLastName,
              candidateLastName: element.candidateLastName,
              candidateTotalBookings: element.candidateTotalBookings,
              candidateTotalExaminations: element.candidateTotalExaminations,
              candidateAverageResult: element.candidateAverageResult,
              CancelledBookings: element.candidateTotalCancelledBookings,
              Examination: element.candidateResults,
            });
          });
        });
    },
    async AllTestCenterReport(context) {
      context.commit("clearCenterReport");

      apolloClient
        .query({
          fetchPolicy: "no-cache",
          query: testCentersReport,
        })
        .then((response) => {
          var centerReport = response.data.testCentersReport;

          centerReport.forEach((element) => {
            context.commit("testCenterReport", {
              CenterName: element.testCenterName,
              Location: element.testCenterLocation,
              capacity: element.testCenterCapacity,
              slot: element.testCenterSlots,
              examAppeared: element.testCenterExaminations,
            });
          });
        });
    },
  },
};
