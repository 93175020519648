<template>
  <v-row justify="center">
    <v-dialog
      v-model="CreateExaminationTimeTableDialog"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title>
          <span class="text-h7"><v-icon>mdi-plus-circle</v-icon> Create Timetable Session</span>
        </v-card-title>
        <v-divider></v-divider>
            <v-card-text>
                    <v-container>
                    <v-row>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
<!--                   <v-text-field-->
<!--                        v-model="timetableName"-->
<!--                        :counter="30"-->
<!--                        label="Timetable Name *"-->
<!--                        prepend-icon="mdi-card-account-details"-->
<!--                        required-->
<!--                    >-->
<!--                    </v-text-field>-->
                    <v-select
                        v-model="timetableExamination"
                        :items="$store.state.ExaminationStore.allExaminations"
                        prepend-icon="mdi-chart-timeline-variant-shimmer"
                        attach
                        multiple
                        item-text="title"
                        item-value="key"
                        chips
                        label="Examination *"
                      ></v-select>

                    <v-dialog
                      ref="dateDialog"
                      v-model="modal"
                      :return-value.sync="date"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          label="Timetable Date"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date"
                        color="primary"
                        type="date"
                        :min="today"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="modal = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dateDialog.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                    <v-dialog
                      ref="timeDialog"
                      v-model="modal2"
                      :return-value.sync="time"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="time"
                          label="Timetable Time"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="modal2"
                        v-model="time"
                        full-width
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="modal2 = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.timeDialog.save(time)"
                        >
                          OK
                        </v-btn>
                      </v-time-picker>
                    </v-dialog>
                   
                    
                    <v-select
                        v-model="venuesUniqueId"
                        :items="$store.state.VenueStore.allVenues"
                        prepend-icon="mdi-chart-timeline-variant-shimmer"
                        attach
                        item-text="venueName"
                        item-value="venuesUniqueId"
                        chips
                        label="Venue"
                        @change="setSlotsValue(venuesUniqueId)"
                      ></v-select>
                     <v-text-field
                        v-model="timetableSlots"
                        label="Available Slots *"
                        required
                        type="number"
                        readonly
                        prepend-icon="mdi-select"
                    >
                    </v-text-field>
                       
                    </v-col>
                        </v-row>
                    </v-container>
                    <v-divider></v-divider>
            </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            fab
            small
            class="white--text"
            
           @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            color="rgba(48, 67, 194, 0.76)"
            fab
            small
            class="white--text"
           @click="CreateExaminationTimeTable"
          >
            <v-icon>mdi-check-all</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <LocationSettings :openLocationDialog="openLocationDialog" @close="openLocationDialog =! openLocationDialog"/> -->
  </v-row>
</template>


<script>

// import Vue from "vue";
const moment = require('moment')

export default {
    components:{
        
    },
    data: () => ({
        checkbox:false,
        openLocationDialog:false,
        menu4:false,
        menu3: false,
        modal2: false,
        menu: false,
        modal: false,
        menu2: false,
        timeCounter:0,
        picker: new Date().toISOString().substr(0, 7),
        dialog: false,   
        timetableName:"Mtihani ",
        timetableExamination:null,
        date:null,
        today:new Date().toISOString().substr(0,10),
        time:null,
        timetableSlots:null,
        venuesUniqueId:null,
        venueName:null,

        Region: '',
        District: [],
        flow:[
            {'code':'SS', 'name':'Single Section'},
            {'code':'SQ', 'name':'Single Question'},
            {'code':'AQ', 'name':'All Questions'},
        ]
    }),
    props: ["CreateExaminationTimeTableDialog"],
    mounted(){
      // console.log(this.today)
      this.$store.dispatch('LoadExaminations')
      this.$store.dispatch('LoadVenues')

    },
    methods:{
      CreateExaminationTimeTable: function(){

        let data={
          timetableName:this.getTimetableName(this.date,this.time)+" - "+this.venueName,
          timetableExaminations:this.timetableExamination+"",
          timetableDate:this.date,
          timetableTime:this.time,
          timetableSlots:this.timetableSlots,
          venuesUniqueId:this.venuesUniqueId,
        }
        this.$store.dispatch('createTimetable',data).then(()=>{
          this.$emit('close')
          // this.$store.dispatch('LoadTimetables')
        })
        console.log(data)
      },
      setSlotsValue:function(id_value){
        console.log(id_value)

        console.log(this.$store.state.VenueStore.allVenues)
        this.timetableSlots=this.$store.state.VenueStore.allVenues.find(({ venuesUniqueId }) => venuesUniqueId === id_value).available
        this.venueName=this.$store.state.VenueStore.allVenues.find(({ venuesUniqueId }) => venuesUniqueId === id_value).venueName
      },
      getTimetableName(dateValue,timeValue){
        //Changing date Language
        moment().locale('sw')
        let dateInSwahili = moment(dateValue).locale('sw')
        let swahiliDateWithTimeRemoved=dateInSwahili.format("dddd, Do MMMM YYYY, h:mm:ss").split(',')[0]+", "+dateInSwahili.format("dddd, Do MMMM YYYY, h:mm:ss").split(',')[1]

        let timetableNameProcessed=""
        let timeInWords=["Sita","Saba","Nane","Tisa","Kumi","Kumi na Moja", "Kumi na Mbili","Moja","Mbili","Tatu","Nne","Tano"]
        let timeInNumbers=parseInt(timeValue.toString().substr(0,2))
        let dakika=timeValue.toString().substr(3,5)
        let dakikaInWords=""
        if(parseInt(dakika)===0){
          dakikaInWords="Kamili"
        }else if(parseInt(dakika)===30){
          dakikaInWords="na Nusu"
        }else {
          dakikaInWords="na dakika "+parseInt(dakika)
        }

        if(timeInNumbers>11&&timeInNumbers<=15){
          timetableNameProcessed="Mtihani wa "+swahiliDateWithTimeRemoved+": Saa "+timeInWords[timeInNumbers%12]+" "+dakikaInWords+" Mchana"
        }else if(timeInNumbers>=16&&timeInNumbers<=18){
          timetableNameProcessed="Mtihani wa "+swahiliDateWithTimeRemoved+": Saa "+timeInWords[timeInNumbers%12]+" "+dakikaInWords+" Jioni"
        }else if((timeInNumbers>=0&&timeInNumbers<=4)||(timeInNumbers>=19&&timeInNumbers<=23)){
          timetableNameProcessed="Mtihani wa "+swahiliDateWithTimeRemoved+": Saa "+timeInWords[timeInNumbers%12]+" "+dakikaInWords+" Usiku"
        }else if(timeInNumbers<=11){
          timetableNameProcessed="Mtihani wa "+swahiliDateWithTimeRemoved+": Saa "+timeInWords[timeInNumbers%12]+" "+dakikaInWords+" Asubuhi"
        }

        return timetableNameProcessed
      }
    },watch:{
      time:function (){
        console.log("This is it")
      }
  }
  }
</script>