<template>
    <v-container>
        <v-app-bar dense app color="rgba(39, 166, 245, 0.53)" dark>
          <router-link to="/">
            <div  class="d-flex align-center" style="padding:5px 10px 5px 20px;border-radius:40px" >
                        <v-img
                        alt="Vuetify Logo"
                        class="shrink mr-2"
                        contain
                        src="/arm.png"
                        transition="scale-transition"
                        width="30"
                        />

            </div>
          </router-link>
            <v-spacer></v-spacer>
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <span> 
                        {{$store.state.AuthStore.user.firstName}} {{$store.state.AuthStore.user.lastName}}
                        <v-icon  v-bind="attrs"
                        v-on="on" left>mdi-dots-vertical</v-icon>
                    </span>
                </template>
                <v-list>
                    <v-list-item link><v-list-item-title  > <v-icon left>mdi-lock</v-icon> Change Password</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="logoutUser"><v-list-item-title  > <v-icon left>mdi-logout</v-icon> Logout</v-list-item-title></v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar> 
        <SideBar :mini="mini"/>
    </v-container>
</template>
<script>
import SideBar from './SideBar.vue'
import {mapActions} from 'vuex'
import Getters from '../../../../store/store'

export default {
    components:{
        SideBar
    },
    data(){
        return{
            mini:false,
            userdata:Getters.state.user,
            items: [
                { title: 'Click Me' },
                { title: 'Click Me' },
                { title: 'Click Me' },
                { title: 'Click Me 2' },
            ],
        }
    },methods:{
        ...mapActions(['logOut']),
    logoutUser: function () {
        
      this.logOut()
        .then(() => this.$router.push('/login'))
    }
    }
}
</script>

<style>

</style>