<template>
  <v-row justify="center">
    <v-dialog
        v-model="EditTestCenterConfigurationDialog"
        persistent
        max-width="60%"
    >
      <v-card>
        <v-card-title>
          <span class="text-h7"><v-icon>mdi-home-plus</v-icon> Edit Test Center Configuration</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
              >
                <v-text-field
                    v-model="DeviceSerialNumber"
                    label="Device Serial Number *"
                    hint="Click Get Device Details"
                    persistent-hint
                    required
                    readonly
                    prepend-icon="mdi-card-account-details"
                >
                </v-text-field>


                <v-row>
                  <v-col>
                    <v-btn
                        class="rounded-lg float-end"
                        color="success"
                        @click="getDeviceDetails"
                    >
                      Get Device Details
                    </v-btn>
                  </v-col>
                </v-row>
                <v-autocomplete
                    v-model="ExaminationVenue"
                    :items="examinationVenues"
                    label="Test Center Venue"
                    item-text="node.venuesName"
                    item-value="node.primaryKey"
                    chips
                >
                  <template v-slot:selection="data">
                    <v-chip
                        :key="JSON.stringify(data.item.node.primaryKey)"
                        v-bind="data.attrs"
                        :input-value="data.item.node.primaryKey"
                        @click:close="data.parent.selectItem(data.item.node.primaryKey)"
                    >
                      <v-avatar
                          class="accent white--text"
                          left
                          v-text="data.item.node.venuesName.slice(0, 1).toUpperCase()"
                      ></v-avatar>
                      {{ data.item.node.venuesName }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="pink"
              fab
              small
              class="white--text"

              @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
              color="rgba(48, 67, 194, 0.76)"
              fab
              small
              class="white--text"
              @click="EditTestCenterConfiguration"
          >
            <v-icon>mdi-check-all</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <LocationSettings :openLocationDialog="openLocationDialog" @close="openLocationDialog =! openLocationDialog"/> -->
  </v-row>
</template>


<script>

import allVenues from '../../../../graphql/VenuesAdmin/allVenues.gql'

export default {
  components:{

  },
  data: () => ({
    checkbox:false,
    DeviceSerialNumber:null,
    DeviceUniqueId:null,
    ExaminationVenue:null,
    selectedVenue:null,
    examinationVenues:[],
  }),
  props: ["EditTestCenterConfigurationDialog","testCenterConfigurationsItems"],
  mounted(){
    this.$apollo.query({
      query:allVenues,
    }).then((response)=>{
      this.examinationVenues=response.data.allVenues.edges
      console.log(this.examinationVenues)
    })
  },
  methods:{
    EditTestCenterConfiguration: function(){
      let data={
        deviceSerialNumber:this.DeviceSerialNumber,
        deviceVenue:this.ExaminationVenue,
        deviceUniqueId:this.DeviceUniqueId,
      }
      this.$store.dispatch('editVenueDevice',data).finally(()=>{
        this.$emit('close')
      })
    },
    getDeviceDetails:function (){
      navigator.usb.requestDevice({ filters: [{ vendorId: 0x1b55 }] })
          .then(device => {
            this.DeviceSerialNumber=device.serialNumber;
          })
          .catch(error => { console.error(error); });
    }

  },watch:{
    EditTestCenterConfigurationDialog:function(){
      this.DeviceSerialNumber=this.testCenterConfigurationsItems.deviceSerialNumber
      this.ExaminationVenue=this.testCenterConfigurationsItems.venue.primaryKey
      this.DeviceUniqueId=this.testCenterConfigurationsItems.unique_id
      console.log("After Clicking edit")
      console.log(this.testCenterConfigurationsItems)
    }
  }
}
</script>