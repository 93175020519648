<template>
  <v-row justify="center">
    <v-dialog
      v-model="AddNewCommitteesDialog"
      persistent
      max-width="60%"
    >
      <v-card>
        <v-card-title>
          <span class="text-h7"><v-icon>mdi-home-plus</v-icon> Add New Examination Venue</span>
        </v-card-title>
        <v-divider></v-divider>
            <v-card-text>
                    <v-container>
                    <v-row>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
                    <v-text-field
                        v-model="LocationName"
                        :rules="nameRules"
                        :counter="30"
                        label="Name *"
                        required
                        prepend-icon="mdi-domain"
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="SeatCapacity"
                        label="Seats Capacity *"
                        required
                        type="number"
                        @keyup="checkVenueValueStatus"
                        @change="checkVenueValueStatus"
                        prepend-icon="mdi-seat-outline"
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="AvailableCapacity"
                        label="Available Capacity *"
                        required
                        type="number"
                        @keyup="checkVenueValueStatus"
                        @change="checkVenueValueStatus"
                        prepend-icon="mdi-seat-outline"
                    >
                    </v-text-field>
                      <v-select
                        v-model="Region"
                        :items="$store.state.RegionsStore.allRegions"
                        prepend-icon="mdi-map-marker"

                        item-text="regionName"
                        item-value="primaryKey"
                        chips
                        label="Select Region"
                        
                      ></v-select>
                        </v-col>
                        </v-row>
                    </v-container>
                    <v-divider></v-divider>
            </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            fab
            small
            class="white--text"
            
           @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            color="rgba(48, 67, 194, 0.76)"
            fab
            small
            class="white--text"
           @click="addNewVenue"
          >
            <v-icon>mdi-check-all</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <LocationSettings :openLocationDialog="openLocationDialog" @close="openLocationDialog =! openLocationDialog"/> -->
  </v-row>
</template>


<script>

export default {
    components:{
        
    },
    data: () => ({
        checkbox:false,
        openLocationDialog:false,
        time: null,
        menu4:false,
        menu3: false,
        modal2: false,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        modal: false,
        menu2: false,
        timeCounter:0,
        picker: new Date().toISOString().substr(0, 7),
        dialog: false,   
        LocationName:'', 
        SeatCapacity:'',
        AvailableCapacity:'',
        venueSlotsSuccess:false,
        regions:[], 
        nameRules: [
            v => !!v || 'Location name is required',
            v => v.length <= 30 || 'Location name must be less than 10 characters',
        ],
        Region: '',
        District: [],
    }),
    props: ["AddNewCommitteesDialog"],
    methods:{
      addNewVenue: function(){
        if(parseInt(this.SeatCapacity)>= parseInt(this.AvailableCapacity)){
        let data={
          venuesName:this.LocationName,
          venuesRegion:this.Region,
          venuesCapacity:this.SeatCapacity,
          venuesAvailableSeats:this.AvailableCapacity,
        }
        this.$store.dispatch('addVenue',data).then(()=>{
          // console.log(response.addNewExmainationVenue.venue.venuesName)
          this.$emit('close')
        })
         }else{
          this.$swal({
            toast:true,
            position:'top-right',
            title:'Available capacity Exceeds Seat Capacity!',
            text:'Please Revise',
            timerProgressBar:true,
            timer:10000,
            icon:'error',
            showConfirmButton:false,
            animation:false,
          })
        }
      },
      checkVenueValueStatus(){
        console.log("Create",parseInt(this.SeatCapacity)> parseInt(this.AvailableCapacity),parseInt(this.SeatCapacity),parseInt(this.AvailableCapacity))
        if(parseInt(this.SeatCapacity)> parseInt(this.AvailableCapacity)){
          this.venueSlotsSuccess=true
        }else{
          this.venueSlotsSuccess=false
        }
      }
    }
  }
</script>