<template>
  <v-row justify="center">
    <v-dialog
      v-model="CreateExaminationSectionDialog"
      max-width="60%"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-h7"><v-icon>mdi-home-plus</v-icon> Create Examination Section</span>
        </v-card-title>
        <v-divider></v-divider>
            <v-card-text>
                    <v-container>
                    <v-row>
                        <v-col
                            cols="12"
                            md="12"
                            sm="12"
                        >
                          <v-text-field
                              v-model="sectionName"
                              label="Section Name *"
                              prepend-icon="mdi-card-account-details"
                              required
                          >
                          </v-text-field>
                          <v-text-field
                              v-model="sectionDescription"
                              label="Section Description *"
                              prepend-icon="mdi-card-account-details"
                              required
                          >
                          </v-text-field>
                    </v-col>
                        </v-row>
                    </v-container>
                    <v-divider></v-divider>
            </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            color="pink"
            fab
            small
            
           @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            class="white--text"
            color="rgba(48, 67, 194, 0.76)"
            fab
            small
           @click="CreateExaminationSection"
          >
            <v-icon>mdi-check-all</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <LocationSettings :openLocationDialog="openLocationDialog" @close="openLocationDialog =! openLocationDialog"/> -->
  </v-row>
</template>


<script>

  import Vue from "vue";

  export default {
    components:{
        
    },
    data: () => ({
      sectionName:"",
      sectionDescription:"",
    }),
    props: ["CreateExaminationSectionDialog"],
    mounted(){
      
    },
    methods:{

      CreateExaminationSection: function(){
        let data={
          sectionName:this.sectionName,
          sectionDesciptions:this.sectionDescription,
        }
        if(this.sectionName!==""&&this.sectionDescription!==""){
          this.$store.dispatch('addNewExaminationSections',data).then(()=>{
            this.sectionName=null
            this.sectionDescription=null
            this.$emit('close')
          })
        }else {
          Vue.swal({
            position:'center',
            title:'Empty Fields',
            text:'Either Section name or Section Description or both are empty',
            icon:'success',
            showConfirmButton:true,
            animation:false,
          })
        }

      }
      
    }
  }
</script>