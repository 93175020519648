<template>
  <v-row justify="center">
    <v-dialog
      v-model="CreateExaminationDialog"
      persistent
      max-width="60%"
    >
      <v-card>
        <v-card-title>
          <span class="text-h7"><v-icon>mdi-home-plus</v-icon> Create Examination Category</span>
        </v-card-title>
        <v-divider></v-divider>
            <v-card-text>
                    <v-container>
                    <v-row>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
                   <v-text-field
                        v-model="examinationName"
                        label="Examination Category Name *"
                        required
                        prepend-icon="mdi-card-account-details"
                    >
                    </v-text-field>
                    <v-select
                        v-model="examinationSetting"
                        :items="$store.state.ExaminationSettingsStore.allExaminationSettings"
                        item-text="name"
                        item-value="key"
                        label="Examination Setting *"
                        prepend-icon="mdi-card-account-details"
                        required
                    >
                    </v-select>
                    </v-col>
                        </v-row>
                    </v-container>
                    <v-divider></v-divider>
            </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            fab
            small
            class="white--text"
            
           @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            color="rgba(48, 67, 194, 0.76)"
            fab
            small
            class="white--text"
           @click="CreateExamination"
          >
            <v-icon>mdi-check-all</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <LocationSettings :openLocationDialog="openLocationDialog" @close="openLocationDialog =! openLocationDialog"/> -->
  </v-row>
</template>


<script>

  export default {
    components:{
        
    },
    data: () => ({
      checkbox:false,
      examinationName:null,
      examinationSetting:null
    }),
    props: ["CreateExaminationDialog"],
    mounted(){
      
    },
    methods:{

      CreateExamination: function(){
        let data={
          examinationTitle:this.examinationName,
          examinationSetting: this.examinationSetting,
        }
        this.$store.dispatch('createExamination',data).then(()=>{
          this.$emit('close')
        })
      }
      
    }
  }
</script>