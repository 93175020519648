import { apolloClient } from "@/vue-apollo";
import allCategoryQuestions from "../../graphql/dts_questions_graphql/allCategoryQuestions.gql";
import createQuestion from "../../graphql/dts_questions_graphql/createQuestion.gql";
import cancelIndividualQuestion from "../../graphql/dts_questions_graphql/cancelIndividualQuestion.graphql";
import updateQuestions from "../../graphql/dts_questions_graphql/updateQuestions.gql";
import allQuestionDifficulties from "../../graphql/dts_questions_graphql/allQuestionDifficulties.gql";
import Vue from "vue";

export default {
  state: {
    questionList: [],
    questionDifficulties: [],
    totalPages: 0,
    totalCount: 0,
  },

  mutations: {
    deleteQuestion(state) {
      state.questionList = [];
    },
    editQuestion(state, data) {
      state.questionList.find(
        ({ questionUniqueId }) => questionUniqueId === data.questionUniqueId
      ).description = data.description;
      state.questionList.find(
        ({ questionUniqueId }) => questionUniqueId === data.questionUniqueId
      ).dificult = data.dificult;
      state.questionList.find(
        ({ questionUniqueId }) => questionUniqueId === data.questionUniqueId
      ).code = data.code;
      state.questionList.find(
        ({ questionUniqueId }) => questionUniqueId === data.questionUniqueId
      ).category = data.category;
      state.questionList.find(
        ({ questionUniqueId }) => questionUniqueId === data.questionUniqueId
      ).categoryId = data.categoryId;
      state.questionList.find(
        ({ questionUniqueId }) => questionUniqueId === data.questionUniqueId
      ).date = data.date;
      state.questionList.find(
        ({ questionUniqueId }) => questionUniqueId === data.questionUniqueId
      ).hasAttachment = data.hasAttachment;
      state.questionList.find(
        ({ questionUniqueId }) => questionUniqueId === data.questionUniqueId
      ).fileName = data.fileName;
      state.questionList.find(
        ({ questionUniqueId }) => questionUniqueId === data.questionUniqueId
      ).filePath = data.filePath;
      state.questionList.find(
        ({ questionUniqueId }) => questionUniqueId === data.questionUniqueId
      ).fileKey = data.fileKey;
      state.questionList.find(
        ({ questionUniqueId }) => questionUniqueId === data.questionUniqueId
      ).correctAnswer = data.correctAnswer;
      state.questionList.find(
        ({ questionUniqueId }) => questionUniqueId === data.questionUniqueId
      ).answerOptions = data.answerOptions;
    },
    addQuestion(state, data) {
      state.questionList.push(data);
      // console.log(state.questionList)
    },
    addQuestionDifficulties(state, data) {
      state.questionDifficulties = data;
    },
    cancelQuestion(state, data) {
      state.questionList.find(
        ({ questionUniqueId }) => questionUniqueId === data.questionUniqueId
      ).isActive = data.isActive;
    },
    setTotalPages(state, data) {
      state.totalPages = data;
    },
    setTotal(state, data) {
      state.totalCount = data;
    },
  },

  actions: {
    async loadQuestionDifficulties(context) {
      context.commit("addQuestionDifficulties");

      apolloClient.query({ query: allQuestionDifficulties }).then(() => {
        // var questionDifficulties = [];
        // questionDifficulties = response.data.systemConfigurations;
        // console.log(questionDifficulties[0]['questionDificulties'])
      });
    },

    async loadCategoryQuestions(context, variableData) {
      context.commit("deleteQuestion");

      apolloClient
        .query({
          fetchPolicy: "no-cache",
          query: allCategoryQuestions,
          variables: variableData,
        })
        .then((response) => {
          response.data.allQuestionsBank.results.forEach((question) => {
            let attachmentDetails;
            if (question.hasAttachment) {
              if (question.attachmentQuestion.length !== 0) {
                question.attachmentQuestion.forEach((elementInside) => {
                  attachmentDetails = {
                    fileName: elementInside.attachmentFileName,
                    filePath: elementInside.attachmentPathName,
                    fileKey: elementInside.primaryKey,
                  };
                });
              } else {
                attachmentDetails = {
                  fileName: "",
                  filePath: "",
                  fileKey: "",
                };
              }
            } else {
              attachmentDetails = {
                fileName: "",
                filePath: "",
                fileKey: "",
              };
            }
            var description = question.questionDescriptions.trim();
            var questionUniqueId = question.questionUniqueId;
            var dificult = question.questionDifficulties;
            var dificultLevelCode = null;
            var category = question.questionCategory.categoryName;
            var categoryId = question.questionCategory.primaryKey;
            var difficultLevel = [
              { code: "es", description: "Easy" },
              { code: "md", description: "Medium" },
              { code: "hd", description: "Hard" },
              { code: "xd", description: "Extra Hard" },
            ];
            difficultLevel.forEach((level) => {
              if (level.code.toLowerCase() === dificult.toLowerCase()) {
                dificult = level.description;
                dificultLevelCode = level.code;
              }
            });
            var date = new Date(question.questionCreateddate)
              .toString()
              .substr(0, 21);
            var answerOptionsRaw = question.questionAnswerOptions.edges;
            var answerOptionsProcessed = [];
            var correctAnswer = null;
            answerOptionsRaw.forEach((answerOption) => {
              if (answerOption.node.answerIsCorrect) {
                correctAnswer = answerOption;
              }
              answerOptionsProcessed.push(answerOption.node);
            });
            var qObj = {
              questionUniqueId: questionUniqueId,
              description: description,
              isActive: question.questionIsActive,
              dificult: dificult,
              code: dificultLevelCode,
              category: category,
              categoryId: categoryId,
              date: date,
              hasAttachment: question.hasAttachment,
              fileName: attachmentDetails.fileName,
              filePath: attachmentDetails.filePath,
              fileKey: attachmentDetails.fileKey,
              correctAnswer: correctAnswer,
              answerOptions: answerOptionsProcessed,
            };
            context.commit("addQuestion", qObj);
          });
        });
    },
    async createQuestions(context, data) {
      apolloClient
        .mutate({
          mutation: createQuestion,
          variables: data,
        })
        .then((response) => {
          var question = response.data.createQuestionsBank.question;
          var description = question.questionDescriptions;
          var questionUniqueId = question.questionUniqueId;
          var dificult = question.questionDifficulties;
          var category = question.questionCategory.categoryName;
          var categoryId = question.questionCategory.primaryKey;
          var date = new Date(question.questionCreateddate)
            .toString()
            .substr(0, 21);

          let attachmentDetails;
          if (question.hasAttachment) {
            if (question.attachmentQuestion.length !== 0) {
              question.attachmentQuestion.forEach((elementInside) => {
                attachmentDetails = {
                  fileName: elementInside.attachmentFileName,
                  filePath: elementInside.attachmentPathName,
                  fileKey: elementInside.primaryKey,
                };
              });
            } else {
              attachmentDetails = {
                fileName: "",
                filePath: "",
                fileKey: "",
              };
            }
          } else {
            attachmentDetails = {
              fileName: "",
              filePath: "",
              fileKey: "",
            };
          }
          var dificultLevelCode = null;
          // console.log(question.node.questionAnswerOptions.edges)
          var difficultLevel = [
            { code: "es", description: "Easy" },
            { code: "md", description: "Medium" },
            { code: "hd", description: "Hard" },
            { code: "xd", description: "Extra Hard" },
          ];
          difficultLevel.forEach((level) => {
            if (level.code.toLowerCase() === dificult.toLowerCase()) {
              dificult = level.description;
              dificultLevelCode = level.code;
            }
          });

          var answerOptionsRaw = question.questionAnswerOptions.edges;
          var answerOptionsProcessed = [];
          var correctAnswer = null;
          answerOptionsRaw.forEach((answerOption) => {
            if (answerOption.node.answerIsCorrect) {
              correctAnswer = answerOption;
            }
            answerOptionsProcessed.push(answerOption.node);
          });
          var qObj = {
            questionUniqueId: questionUniqueId,
            description: description,
            dificult: dificult,
            code: dificultLevelCode,
            category: category,
            categoryId: categoryId,
            date: date,
            hasAttachment: question.hasAttachment,
            fileName: attachmentDetails.fileName,
            filePath: attachmentDetails.filePath,
            fileKey: attachmentDetails.fileKey,
            correctAnswer: correctAnswer,
            answerOptions: answerOptionsProcessed,
          };
          console.log(question);
          context.commit("addQuestion", qObj);
        });
    },

    async editQuestions(context, data) {
      apolloClient
        .mutate({
          mutation: updateQuestions,
          variables: data,
        })
        .then((response) => {
          var question = response.data.updateQuestionsBank.question;
          var description = question.questionDescriptions;
          var questionUniqueId = question.questionUniqueId;
          var dificult = question.questionDifficulties;
          var category = question.questionCategory.categoryName;
          var categoryId = question.questionCategory.primaryKey;
          var date = new Date(question.questionCreateddate)
            .toString()
            .substr(0, 21);

          let attachmentDetails;
          console.log(question.attachmentQuestion.length !== 0);
          if (question.hasAttachment) {
            if (question.attachmentQuestion.length !== 0) {
              question.attachmentQuestion.forEach((elementInside) => {
                attachmentDetails = {
                  fileName: elementInside.attachmentFileName,
                  filePath: elementInside.attachmentPathName,
                  fileKey: elementInside.primaryKey,
                };
              });
            } else {
              attachmentDetails = {
                fileName: "",
                filePath: "",
                fileKey: "",
              };
            }
            console.log("Responsible question");
            console.log(question.node);
          } else {
            attachmentDetails = {
              fileName: "",
              filePath: "",
              fileKey: "",
            };
          }
          var dificultLevelCode = null;
          // console.log(question.node.questionAnswerOptions.edges)
          var difficultLevel = [
            { code: "es", description: "Easy" },
            { code: "md", description: "Medium" },
            { code: "hd", description: "Hard" },
            { code: "xd", description: "Extra Hard" },
          ];
          difficultLevel.forEach((level) => {
            if (level.code.toLowerCase() === dificult.toLowerCase()) {
              dificult = level.description;
              dificultLevelCode = level.code;
            }
          });

          var answerOptionsRaw = question.questionAnswerOptions.edges;
          var answerOptionsProcessed = [];
          var correctAnswer = null;
          answerOptionsRaw.forEach((answerOption) => {
            if (answerOption.node.answerIsCorrect) {
              correctAnswer = answerOption;
            }
            answerOptionsProcessed.push(answerOption.node);
          });
          var qObj = {
            questionUniqueId: questionUniqueId,
            description: description,
            dificult: dificult,
            code: dificultLevelCode,
            category: category,
            categoryId: categoryId,
            date: date,
            hasAttachment: question.hasAttachment,
            fileName: attachmentDetails.fileName,
            filePath: attachmentDetails.filePath,
            fileKey: attachmentDetails.fileKey,
            correctAnswer: correctAnswer,
            answerOptions: answerOptionsProcessed,
          };
          console.log(question);
          context.commit("editQuestion", qObj);
        });
    },
    async deactivateQuestion(context, data) {
      apolloClient
        .mutate({
          mutation: cancelIndividualQuestion,
          variables: data,
        })
        .then((response) => {
          let updateData = {
            questionUniqueId:
              response.data.cancelIndividualQuestion.question.questionUniqueId,
            isActive:
              response.data.cancelIndividualQuestion.question.questionIsActive,
          };
          if (updateData.isActive === data.questionIsActive) {
            if (updateData.isActive) {
              Vue.swal({
                toast: true,
                position: "top-right",
                title: "Question Activated Successfully!",
                timerProgressBar: true,
                timer: 10000,
                icon: "success",
                showConfirmButton: false,
                animation: false,
              });
            } else {
              Vue.swal({
                toast: true,
                position: "top-right",
                title: "Question De-activated Successfully!",
                timerProgressBar: true,
                timer: 10000,
                icon: "success",
                showConfirmButton: false,
                animation: false,
              });
            }
          } else {
            Vue.swal({
              toast: true,
              position: "top-right",
              title: "Oops The Update Request could not come through!",
              timerProgressBar: true,
              timer: 10000,
              icon: "success",
              showConfirmButton: false,
              animation: false,
            });
          }
          context.commit("cancelQuestion", updateData);
        });
    },
  },
};
