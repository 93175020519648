<template>
  <v-row justify="center">
    <v-dialog
        v-model="EditUserRoleDialog"
        max-width="60%"
        persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-h6"><v-icon>mdi-seal</v-icon>Edit User Role </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  md="12"
                  sm="12"
              >
                <v-text-field
                    v-model="role_name"
                    :counter="20"
                    :rules="nameRules"
                    label="Role Name *"
                    prepend-icon="mdi-text-recognition"
                    required
                >
                </v-text-field>
                <v-treeview
                    v-if="groupRoles.length>0"
                    v-model="selectedPermissions"
                    :items="groupRoles"
                    selectable
                    @input="consoleMethod"
                ></v-treeview>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="font-weight-bold px-4 mb-3 rounded-lg"
              color="error"
              dark
              outlined
              @click="$emit('close')"
          >
            <v-icon small>mdi-close</v-icon> Cancel
          </v-btn>
          <v-btn
              class="font-weight-bold px-4 mb-3 rounded-lg"
              color="success"
              dark
              @click="updateUserRole"
          >
            <v-icon small>mdi-check-all</v-icon> Update Role
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
// import createUserRole from '../../../../graphql/createUserRole.gql'
// import allUserRoles from '../../../../graphql/allUserRoles.gql'
import allPermissionGroups from '../../../../graphql/allPermissionGroups.gql'
import updateUserRole from "../../../../graphql/updateUserRole.gql";


export default {
  components:{
  },
  data: () => ({
    Applicdations:'',
    checkbox:false,
    openLocationDialog:false,
    selectedUserRoles:'',
    selectedPermissions:'',
    time: null,
    menu4:false,
    menu3: false,
    modal2: false,
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    timeCounter:0,
    picker: new Date().toISOString().substr(0, 7),
    dialog: false,
    role_name:null,
    role_identification:null,
    isCloned:false,
    nameRules: [
      v => !!v || 'Role name is required',
      v => v.length <= 20 || 'Role must be less than 20 characters',
    ],
    groupRoles: []
  }),
  props: ["EditUserRoleDialog","UserRoleItems"],
  methods:{
    updateUserRole:function(){
      this.$apollo.mutate({
        mutation:updateUserRole,
        variables: {
          primaryKey:this.role_identification,
          roleDescriptions: this.role_name,
          permissionIdList:this.selectedPermissions+"",
        },
      }).then((response)=>{
        console.log(response)
        this.$router.push('/latradts_settings/users')
      })
      this.role_name=''
      this.$emit('close');
    },
    consoleMethod: function(){
      console.log(this.selectedPermissions)
      console.log(this.selectedPermissions+"".toString().replaceAll('c','a'))
    }
  },mounted(){
    this.$apollo.query({
      query:allPermissionGroups
    }).then((response)=>{
      response.data.allPermissionGroups.edges.forEach(element=>{
        let children = []
        element.node.permissionBelongingGroup.edges.forEach(child=>{
          children.push({'id':child.node.primaryKey,'name':child.node.permissionDescriptions})
        })
        let disabled =true
        if(children.length>0){
          disabled=false
        }
        this.groupRoles.push({'id':"p"+element.node.primaryKey,'name':element.node.groupDescriptions,'children':children, disabled:disabled})
        // console.log(this.groupRoles)
      });
    })
  },watch:{
    EditUserRoleDialog:function (){
      this.role_name=this.UserRoleItems.node.roleDescriptions
      this.role_identification=this.UserRoleItems.node.primaryKey
      this.selectedPermissions=[]
      this.UserRoleItems.node.templateRole.edges.forEach(templatePermission=>{
          this.selectedPermissions.push(templatePermission.node.templatePermission.primaryKey)
      })
      console.log(this.UserRoleItems.node.primaryKey)
    }
  }
}
</script>