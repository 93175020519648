<template>
  <v-dialog
      v-model="PreviewExaminationDialog"
      max-width="600"
      persistent
  >
    <v-card class="pt-8">
      <v-card-title>
        Examinations in this Timetable
      </v-card-title>
      <v-card-text>
        <v-chip v-for="examination in examinations" :key="'examination'+examination.unique_id" class="mx-1">
          {{examination.exam}}
        </v-chip>
      </v-card-text>
      <v-card-actions>
        <v-btn class="ml-auto px-5 rounded-lg" color="primary" @click="$emit('close')">
          Done
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PreviewExaminations",
  props:["PreviewExaminationDialog","PreviewExaminationItems"],
  data(){
    return{
      examinations:null,
      timetableSession:null
    }
  },
  watch:{
    PreviewExaminationDialog:function () {
      this.examinations=null
      this.examinations=this.PreviewExaminationItems.examinations
      this.timetableSession=this.PreviewExaminationItems.name
      console.log(this.PreviewExaminationItems.examinations)

    }
  },
}
</script>

<style scoped>

</style>