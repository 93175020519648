<template>
  <v-container class="mt-10">
    <LandingToolBar :Role="role" />
    <v-row v-if="!$store.getters.roleIsActive" justify="center">
      <v-col lg="6" md="6" sm="12">
        <v-alert
          border="top"
          color="error accent-4"
          colored-border
          elevation="2"
          icon="mdi-alert-circle"
          prominent
        >
          <div class="text-h6">
            You dont have permissions to visit this page. Please contact your
            administrator.
          </div>
        </v-alert>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-row dense align="center" justify="center" justify-md="center">
        <template v-for="card in dashboardCards">
          <v-card
            :key="card"
            v-if="hasAccess(card.permission)"
            :to="card.link"
            class="ma-1"
            width="344"
            outlined
            
          >
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title style="color:rgba(8, 51, 113, 0.85)" class="text-h6 mb-1">
                  {{ card.title }}
                </v-list-item-title>
                <v-list-item-subtitle>{{
                  card.text_content
                }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-avatar tile size="80" color="white">
                <v-img
                  :src="card.src"
                  class="white--text align-end rounded-lg"
                  height="100%"
                  max-width="100%"
                >
                </v-img>
              </v-list-item-avatar>
            </v-list-item>

            <v-card-actions>
              <v-btn color="rgba(8, 51, 113, 0.85)" class="text-capitalize" block outlined text>
                <v-icon small>{{ card.icon }}</v-icon> {{ card.title }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
import LandingToolBar from "./LandingToolBar.vue";
import Getters from "../../store/store";

export default {
  components: {
    LandingToolBar,
  },
  data() {
    return {
      permissions: [],
      role: "",
      userdata: Getters.state.user,
      dashboardCards: [
        {
          title: "Exam Registration",
          src: "/website.png",
          flex: 4,
          md: 6,
          link: "/latradts_canditate/booking",
          icon: "mdi-book-variant",
          text_content: "Register to take examination",
          permission: "can_book_exam",
        },
        {
          title: "Take Exam & Quiz",
          src: "/choose.png",
          flex: 4,
          md: 6,
          link: "latradts_canditate/exam",
          icon: "mdi-pen",
          text_content: "Take Exam/Quiz",
          permission: "can_take_exam",
        },

        {
          title: "Questions Bank",
          src: "/faqs.png",
          flex: 4,
          md: 6,
          link: "/latradts/question_bank",
          icon: "mdi-archive",
          text_content: "Questions Bank",
          permission: "can_view_question_bank",
        },
        {
          title: "Examination Reports",
          src: "/document.png",
          flex: 4,
          md: 6,
          link: "/latradts_settings/candidateReport",
          icon: "mdi-file-chart-outline",
          text_content: "Reports",
          permission: "can_view_examination_reports",
        },
        {
          title: "Examinations Setup",
          src: "/settings.png",
          flex: 4,
          md: 6,
          link: "/latradts_settings/examination_settings",
          icon: "mdi-archive-cog",
          text_content: "Examinations Setup",
          permission: "can_view_examination_settings",
        },
        {
          title: "Settings & Users ",
          src: "/computer.png",
          flex: 4,
          md: 6,
          link: "/latradts_settings/users",
          icon: "mdi-database-cog",
          text_content: "System Settings",
          permission: "can_view_system_settings",
        },
      ],
    };
  },
  methods: {
    navigateToPage(link) {
      this.$router.push({
        path: link,
      });
    },
  },
};
</script>

<style></style>
