<template>
  <div class="text-center">
    <v-dialog v-model="CreateBookingDialog" width="80%">
      <v-card>
        <v-card-title class="text-h5grey lighten-2">
          Register to take Examination or Quiz
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-autocomplete
                  v-model="SelectedRegion"
                  :items="regions"
                  item-text="node.regionName"
                  item-value="node.primaryKey"
                  label="Your Current Location *"
                  @change="regionChange"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="SelectedVenue"
                  :items="venuesPartial"
                  item-text="node.venuesName"
                  item-value="node.primaryKey"
                  label="Select Examination Place or Venue*"
                  @change="venueChange"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="selectedDate"
                  :items="timetablesPartial"
                  item-text="node.timetableDate"
                  item-value="node.primaryKey"
                  label="The Date of Examination*"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12">
                <v-autocomplete
                  v-model="selectedTimetablesSessions"
                  :items="timetablesSessionsPartial"
                  item-text="node.timetableTime"
                  item-value="node.primaryKey"
                  label="Examination Sessions*"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="createExaminationBooking">
            Book Exam
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import createExaminationBooking from "../../../graphql/CandidateBooking/createExaminationBooking.graphql";
import allVenues from "../../../graphql/VenuesAdmin/allVenues.gql";
import allRegions from "../../../graphql/VenuesAdmin/allRegions.gql";
import allExaminationTimetables from "../../../graphql/Examinations/allExaminationTimetables.gql";

export default {
  props: ["CreateBookingDialog", "CreateBookingItems"],
  data: () => ({
    venues: null,
    venuesPartial: null,
    regions: null,
    SelectedRegion: null,
    SelectedVenue: null,
    selectedDate: null,
    examinationName: null,
    timetables: null,
    timetablesPartial: null,
    timetablesSessions: null,
    selectedTimetablesSessions: null,
    timetablesSessionsPartial: null,
  }),
  methods: {
    regionChange() {
      this.venuesPartial = [];
      this.venues.forEach((element) => {
        if (element.node.venuesRegion.primaryKey === this.SelectedRegion) {
          this.venuesPartial.push(element);
        }
      });
    },
    venueChange() {
      this.timetablesPartial = [];
      this.timetables.forEach((element) => {
        if (element.node.timetableVenue.primaryKey === this.SelectedVenue) {
          this.timetablesPartial.push(element);
        }
      });
    },
    timetableChange() {
      this.timetablesSessionsPartial = [];
      this.timetablesSessions.forEach((element) => {
        if (element.node.timetablesDate === this.selectedDate) {
          this.timetablesSessionsPartial.push(element);
        }
      });
    },
    async createExaminationBooking() {
      await this.$apollo
        .mutate({
          mutation: createExaminationBooking,
          variables: {
            bookingExamination: this.selectedTimetablesSessions,
            applicationNumber: "",
          },
        })
        .then((response) => {
          if (response.data.createExaminationBooking.success) {
            this.selectedTimetablesSessions = null;
            this.$emit("closeBookingDialog");
          } else {
            this.$emit("closeBookingDialog");
          }

          this.$router.go("");
        });
    },
  },
  async mounted() {
    this.$apollo
      .query({
        fetchPolicy: "no-cache",
        query: allVenues,
      })
      .then((response) => {
        this.venues = response.data.allVenues.edges;
        this.venuesPartial = this.venues;
      });

    this.$apollo
      .query({
        fetchPolicy: "no-cache",
        query: allRegions,
      })
      .then((response) => {
        this.regions = response.data.allRegions.edges;
      });
    this.$apollo
      .query({
        fetchPolicy: "no-cache",
        query: allExaminationTimetables,
      })
      .then((response) => {
        this.timetables = response.data.allExaminationTimetables.edges;
        this.timetablesPartial = this.timetables;
        this.timetablesSessions = this.timetables;
        this.timetablesSessionsPartial = this.timetables;
      });
  },
  watch: {
    CreateBookingDialog: function () {},
  },
};
</script>

<style scoped></style>
