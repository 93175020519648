<template>
  <v-container>
    <v-card class="pa-3 mt-2" flat max-width="100%" >
      <v-card-text >
        <v-expansion-panels focusable>
          <v-expansion-panel
              v-for="(item,i) in roles"
              :key="i"
          >
            <v-expansion-panel-header>
              <v-row no-gutters>
                <v-col class="d-flex justify-space-between" cols="12">
                  <span class="d-flex align-items-center">{{item.node.roleDescriptions}}</span>
                  <span class="d-flex align-items-center">
                              <v-switch
                                  v-model="item.node.roleIsActive"
                                  style="margin-top: -2px"
                                  color="success"
                                  dense
                                  hide-details
                                  @change="changeRoleActiveStatus(item,$event)"
                                  @click.stop
                              ></v-switch>
                              <v-tooltip left>
                                  <template v-slot:activator="{ on, attrs }">
                                      <v-btn color="primary" fab small text  v-bind="attrs" v-on="on"
                                             @click.stop="openEditRoleDialog(item)"><v-icon small>mdi-pen</v-icon> </v-btn>
                                  </template>
                                  <span>Edit {{item.node.roleDescriptions}} Role </span>
                              </v-tooltip>
                              <v-tooltip left>
                                  <template v-slot:activator="{ on, attrs }">
                                      <v-btn color="error" fab small text  v-bind="attrs" v-on="on"
                                             @click.stop="DeleteRole(item.node.roleUniqueId)"><v-icon small>mdi-delete</v-icon> </v-btn>
                                  </template>
                                  <span>Delete {{item.node.roleDescriptions}} Role </span>
                              </v-tooltip>
                          </span>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row
                  flat
                  subheader
                  three-line
                  class="pa-2 pt-5"
              >
                  <v-chip v-for="item in item.node.templateRole.edges" :key="item.node.templatePermission.id"
                          class="mt-1 mr-1">{{item.node.templatePermission.permissionDescriptions}}</v-chip>



              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <EditUserRole :EditUserRoleDialog="editUserRoleDialog" :UserRoleItems="userRoleItems" @close="editUserRoleDialog=false"/>

    </v-card>
  </v-container>
</template>

<script>
import allUserRoles from '../../../../graphql/allUserRoles.gql'
import deleteUserRole from '../../../../graphql/deleteUserRole.gql'
// import allPermissionGroups from '../../../../graphql/allPermissionGroups.gql'
import updateUserRole from '../../../../graphql/updateUserRole.gql'
import activeUserRole from '../../../../graphql/activateUserRole.graphql'
import EditUserRole from "./EditUserRole";

export default {
    components:{
      EditUserRole
    },
    data(){
        return{
            editUserRoleDialog:false,
            userRoleItems:null,
            roleIsActive:false,
            activeBox:true,
            roles:[],
            permissionGroup:[],
            permissions:'',
            editRoleDialog:false,
            currentEditedRole:'',
            currentEditedRoleId:'',
            currentSelectedPermissionGroups:[],
            primaryKey:'',
        }
    },
   async mounted(){

      await this.$store.dispatch('LoadUserRoles')
      this.$apollo.query({
        query:allUserRoles,
      }).then((response)=>{
        this.roles=response.data.allRoles.edges
        // console.log(this.roles)
      })

      // this.$apollo.query({
      //   query:allPermissionGroups
      // }).then((response)=>{
      //   response.data.allPermissionGroups.edges.forEach(element=>{
      //       let children = []
      //       element.node.permissionBelongingGroup.edges.forEach(child=>{
      //           children.push({'id':child.node.primaryKey,'name':child.node.permissionDescriptions,})
      //       })
      //
      //       let disabled =false
      //       if(children.length<=0){
      //           disabled=true
      //       }
      //       this.permissionGroup.push({'id':element.node.primaryKey,'name':element.node.groupDescriptions,disabled:disabled,'children':children})
      //   })
      // })
    },methods:{
        DeleteRole:function(roleUniqueId){
            this.$apollo.mutate({
                mutation:deleteUserRole,
                variables:{
                    roleUniqueId:roleUniqueId
                }
            }).then((response)=>{
                console.log(response.data.deleteUserRole)
            })
        },
        openEditRoleDialog:function(role_items){
          this.editUserRoleDialog=true
          this.userRoleItems=role_items
        },
        submitNewPermission:function() {
            this.$apollo.mutate({
                mutation:updateUserRole,
                variables:{
                    primaryKey:this.currentEditedRoleId+"",
                    permissionGroupIdList:this.currentSelectedPermissionGroups+""
                }
            }).then((response)=>{
                console.log(response)
            })
            console.log(this.currentEditedRoleId+"")
            console.log(this.currentSelectedPermissionGroups+"")
        },
    changeRoleActiveStatus(item,value){
      console.log(item.node,value)
      this.$apollo.mutate({
        mutation:activeUserRole,
        variables:{
          roleIsActive:value,
          roleUniqueId:item.node.roleUniqueId
        }
      })

    },
        refreshPage:function() {
            this.$router.push('/')
        }
    },computed:{
      allUserRoles:function (){
        console.log(this.$store.state.RolesStore.allUserRoles)
        return this.$store.state.RolesStore.allUserRoles
      }
  }
}
</script>