<template>
  <v-row justify="center">
    <v-dialog
      v-model="AddNewRegionDialog"
      persistent
      max-width="60%"
    >
      <v-card>
        <v-card-title>
          <span class="text-h7"><v-icon>mdi-home-plus</v-icon> Add New Region</span>
        </v-card-title>
        <v-divider></v-divider>
            <v-card-text>
                    <v-container>
                    <v-row>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
                    <v-text-field
                        v-model="RegionName"
                        :rules="nameRules"
                        :counter="30"
                        label="Region Name *"
                        required
                        prepend-icon="mdi-domain"
                    >
                    </v-text-field>
                        </v-col>
                        </v-row>
                    </v-container>
                    <v-divider></v-divider>
            </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            fab
            small
            class="white--text"
            
           @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            color="rgba(48, 67, 194, 0.76)"
            fab
            small
            class="white--text"
           @click="createRegion"
          >
            <v-icon>mdi-check-all</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <LocationSettings :openLocationDialog="openLocationDialog" @close="openLocationDialog =! openLocationDialog"/> -->
  </v-row>
</template>


<script>
export default {
    components:{
        
    },
    data: () => ({
        checkbox:false,
        openLocationDialog:false,
        time: null,
        menu4:false,
        menu3: false,
        modal2: false,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        modal: false,
        menu2: false,
        timeCounter:0,
        picker: new Date().toISOString().substr(0, 7),
        dialog: false,   
        RegionName:'', 
        SeatCapacity:'',  
        nameRules: [
            v => !!v || 'Location name is required',
            v => v.length <= 30 || 'Location name must be less than 10 characters',
        ],
        Region: [],
        District: [],
    }),
    props: ["AddNewRegionDialog"],
    methods:{
      createRegion:function(){
        let data={
          regionName:this.RegionName
        }
        this.$store.dispatch('AddRegionsAction',data).finally(()=>{
          this.$emit('close')
        })
      }
    }
  }
</script>