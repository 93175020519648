<template>
  <v-row justify="center">
    <v-spacer></v-spacer>
    <v-dialog
      v-model="editCategoryVisible"
      max-width="500"
      persistent
    >
      <v-card class="pa-3">
        <v-card-title class="text-h5">
         Edit Question Catergory
        </v-card-title>

        <v-card-text>
          <v-text-field
          v-model="category_name"
            autofocus
            class="mt-5 mb-0"
            label="Category Name"
            outlined
            placeholder="Category Name"
            required
          ></v-text-field>
          <v-checkbox
            v-model="category_active"
            label="Active"
          ></v-checkbox>
        </v-card-text>

        <v-card-actions class="mb-3">
          <v-spacer></v-spacer>

          <v-btn
            color="red darken-1"
            text
            @click="$emit('closeEdit')"
          >
            <v-icon small>mdi-close</v-icon> Cancel
          </v-btn>

          <v-btn
            color="orange"
            dark
            @click="updateQuestionCategory()"
          >
            <v-icon small>mdi-checkbox-marked-circle-outline</v-icon> Submit Changes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// import updateQuestionCategory from '../../../graphql/updateQuestionCategory.gql'

export default {
    props:['editCategoryVisible','categoryIsActive','categoryUniqueId','categoryName'],
     data: () => ({
        category_name:'',
        category_uniqueId:'',
        category_active:false,
        responseFinal:false
    }),
   methods:{
       updateQuestionCategory:async function (){
         let data={
           'categoryName':this.category_name,
           'categoryUniqueId':this.category_uniqueId,
           'categoryIsActive':this.category_active,
         }

         await this.$store.dispatch('updateQuestionCategory',data).then((response)=>{
           console.log(response)
             this.$emit('closeEdit')
         })
       }
   },
   computed:{

    },watch:{
    editCategoryVisible:function () {
      this.category_name=this.categoryName
      this.category_uniqueId=this.categoryUniqueId
      this.category_active=this.categoryIsActive
      console.log(this.category_uniqueId)
    }
  }
}
</script>

<style>

</style>