<template>
  <v-container fluid>
    <v-row>
      <v-spacer></v-spacer>
      <v-tooltip bottom color="success">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="rounded-lg font-weight-bold px-4"
            color="success white--text"
            right
            style="margin: 5px"
            v-bind="attrs"
            @click="OpenNewAccessForm = !OpenNewAccessForm"
            v-on="on"
            ><v-icon small>mdi-lock-plus</v-icon>&nbsp; Create User Role</v-btn
          >
        </template>
        <span>Add New Access Level</span>
      </v-tooltip>
      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="rounded-lg font-weight-bold px-4"
            color="primary white--text px-4"
            right
            style="margin: 5px"
            v-bind="attrs"
            @click="memberDialog = !memberDialog"
            v-on="on"
            ><v-icon small>mdi-account-plus</v-icon>&nbsp; Create User</v-btn
          >
        </template>
        <span>Create New User</span>
      </v-tooltip>
    </v-row>

    <v-row>
      <v-col sm="12">
        <v-card elevation="6">
          <!-- tabs -->
          <v-tabs v-model="tab" show-arrows>
            <v-tab
              v-for="tab in tabs"
              :key="tab.title"
              @click="$store.dispatch(tab.action, tab.isStaff)"
            >
              <v-icon class="me-3" size="20">
                {{ tab.icon }}
              </v-icon>
              <span>{{ tab.title }}</span>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <staffs :boardMembers="items"></staffs>
            </v-tab-item>

            <v-tab-item>
              <candidates />
            </v-tab-item>

            <v-tab-item>
              <roles />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>

    <AddNewUser
      :OpenNewUserForm="OpenNewUserForm"
      @close="OpenNewUserForm = !OpenNewUserForm"
    />
    <AddNewRole
      :OpenNewAccessForm="OpenNewAccessForm"
      @close="OpenNewAccessForm = !OpenNewAccessForm"
    />

    <v-dialog v-model="memberDialog" width="60%" persistent>
      <v-card>
        <v-card-title>
          <v-icon>mdi-account-plus-outline</v-icon>
          <span> Add System Users</span>
        </v-card-title>
        <v-card-text>
          <span>You can create new users or load the users from RRIMS </span>
          <v-radio-group row>
            <v-col cols="6" md="6" sm="6">
              <v-card class="pa-5 rounded-lg" outlined>
                <v-radio
                  label="Upload Candidate From File"
                  value="radio-1"
                  @change="
                    inviteMember = !inviteMember;
                    disabled = true;
                  "
                ></v-radio>
              </v-card>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-card class="pa-5 rounded-lg" outlined>
                <v-radio
                  label="Create System / Institution User"
                  value="radio-2"
                  @change="
                    inviteMember = false;
                    disabled = false;
                  "
                ></v-radio>
              </v-card>
            </v-col>
          </v-radio-group>
          <p  v-if="inviteMember == true">Fields to be inclued in .CSV file are:  <strong>first_name, middle_name, last_name, email, phone_number, password</strong>. <span style="color:red">Names are case sensitive.</span></p>
          <v-file-input
            v-if="inviteMember == true"
            v-model="candFile"
            chips
            show-size
            small-chips
            truncate-length="28"
            label="Select CSV file with candidate list"
            @change="handleFileUpload($event)"
            accept=".csv"
          ></v-file-input>
          <v-simple-table>
            <template v-slot:default v-if="parsed" style="width: 90%">
              <thead>
                <tr>
                  <th
                    v-for="(header, key) in content.meta.fields"
                    v-bind:key="'header-' + key"
                  >
                    {{ header }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                
                  v-for="(row, rowKey) in content.data"
                  v-bind:key="'row-' + rowKey"
                >
                  <td
                    v-for="(column, columnKey) in content.meta.fields"
                    v-bind:key="'row-' + rowKey + '-column-' + columnKey"
                  >
                    <input v-model="content.data[rowKey][column]" />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div v-if="inviteMember == true">
            <v-checkbox
              v-for="member in inviteMemberList"
              :key="member.name"
              v-model="member.checked"
              :label="`${member.name}`"
            ></v-checkbox>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="rounded-lg pa-5"
            color="error"
            outlined
            right
            @click="memberDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="inviteMember && file"
            class="rounded-lg pa-5 text-capitalize"
            color="success"
            right
            @click="importCandidates(content.data)"
          >
            <v-icon>mdi-email-fast-outline</v-icon>  Save Records 
          </v-btn>
          <v-btn
            v-else
            :disabled="disabled"
            class="rounded-lg pa-5"
            color="primary"
            right
            @click="OpenNewUserFormWindow()"
          >
            <v-icon>mdi-plus</v-icon> Add User
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
        <v-overlay z-index="999" :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import Papa from "papaparse";
import AddNewUser from "./AddNewUser.vue";
import Staffs from "./Staffs.vue";
import Candidates from "./Candidates.vue";
import AddNewRole from "./AddNewRole.vue";
import Roles from "./Roles.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    AddNewUser,
    Staffs,
    Candidates,
    AddNewRole,
    Roles,
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },

  data: () => ({
    candFile:null,
    file: "",
    content: [],
    parsed: false,
    overlay: false,

    message: "",
    disabled: true,
    progressShown: true,
    invitationBtn: true,
    inviteMemberList: [],
    searchResults: "Eg. firstname.lastname@doamin.xx",
    loading: false,
    tab: "",
    inviteMember: false,
    memberDialog: false,
    OpenNewUserForm: false,
    OpenNewAccessForm: false,
    allUsersFromDB: {},
    tabs: [
      {
        title: "Staff Users",
        icon: "mdi-account-star-outline",
        action: "LoadStaff",
        isStaff: true,
      },
      {
        title: "Candidates",
        icon: "mdi-account-outline",
        action: "LoadCandidate",
        isStaff: false,
      },
      { title: "Access Levels", icon: "mdi-email-fast-outline" },
    ],
  }),
  methods: {
    handleFileUpload(file) {
      this.file = file;
      this.parseFile();
    },

    parseFile() {
      Papa.parse(this.file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          this.content = results;
          this.parsed = true;
        }.bind(this),
      });
      console.log(this.content);
    },

    async importCandidates(candidateList){

      this.overlay=true
      await  this.$store.dispatch('RegisterCandidates',candidateList)

      this.content.data=[]
      this.content.meta.fields=[]
      this.file=""
      this.candFile=null
      this.memberDialog=false
      this.overlay=false
    },

    clickMe() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.invitationBtn = false;
        this.inviteMemberList.push({
          name: "rachel.daniel@email.com",
          checked: true,
        });
        this.inviteMemberList.push({
          name: "john.smith@email.com",
          checked: false,
        });
        this.searchResults = `Your search results`;
      }, 2000);
    },
    OpenNewUserFormWindow() {
      this.OpenNewUserForm = true;
      this.memberDialog = false;
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  async mounted() {
    await this.$store.dispatch("LoadCandidate");
    await this.$store.dispatch("LoadStaff", true);
  },
};
</script>

<style></style>
