<template>
  <v-container fluid>
    <v-row>
      <v-col sm="12">
        <v-row dense cols="12">
          <v-btn
            color="rgba(48, 67, 194, 0.76)"
            dark
            fab
            small
            @click="backToCategory"
            class="mx-2"
            right
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="rounded-xl"
            color="primary"
            download
            href="https://osat.all.co.tz:9006/latradts_media/questions_bank.xlsx"
            ><v-icon>mdi-download</v-icon>Download Template</v-btn
          >
          <!--          <v-btn class="rounded-xl" color="primary" download href="https://osat.all.co.tz:9006/latradts_media/questions_bank.xlsx"><v-icon>mdi-download</v-icon>Download Template</v-btn>-->
          <v-btn
            class="mx-2"
            color="rgba(48, 67, 194, 0.76)"
            dark
            fab
            small
            @click="createDialog = true"
            right
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn
            style="float: right"
            color="rgba(48, 67, 194, 0.76)"
            dark
            fab
            small
            class="mx-2"
            right
            @click="uploadDialog = true"
          >
            <v-icon>mdi-file-upload</v-icon>
          </v-btn>
        </v-row>
        <v-card class="mt-4">
          <v-card-title>
            <v-toolbar-title color="orange " text>
              {{ categoryName }}
            </v-toolbar-title>
            <v-divider class="mx-4" color="orange " inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-col sm="12">
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="questions"
              class="elevation-1"
              item-key="name"
              show-select
            >
              <template v-slot:item.isActive="{ item }">
                <v-btn
                  v-if="item.isActive"
                  class="text-capitalize"
                  color="success"
                  depressed
                  rounded
                  x-small
                  >Active</v-btn
                >
                <v-btn
                  v-else
                  class="text-capitalize"
                  color="warning"
                  depressed
                  rounded
                  x-small
                  >In-Active</v-btn
                >
              </template>
              <template v-slot:item.hasAttachment="{ item }">
                <v-btn
                  v-if="item.hasAttachment"
                  class="text-capitalize"
                  color="success"
                  depressed
                  rounded
                  x-small
                  >Yes</v-btn
                >
                <v-btn
                  v-else
                  class="text-capitalize"
                  color="warning"
                  depressed
                  rounded
                  x-small
                  >No</v-btn
                >
              </template>
              <template v-slot:item.actions="{ item }">
                <v-list-item-action>
                  <v-list-item-action-text>
                    <v-menu app offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          slot="activator"
                          color="rgba(39, 166, 245, 0.53)"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          ><v-icon>mdi-dots-vertical</v-icon></v-btn
                        >
                      </template>
                      <v-list dense>
                        <v-list-item link>
                          <v-list-item-title
                            @click="openEditQuestionDialog(item)"
                            ><v-icon
                              color="orange"
                              style="height: 16px; font-size: 16px"
                              >mdi-pen
                            </v-icon>
                            Edit Question</v-list-item-title
                          ></v-list-item
                        >

                        <v-divider></v-divider>
                        <v-list-item
                          v-if="item.isActive"
                          link
                          @click="changeQuestionActiveStatus(item, false)"
                        >
                          <v-list-item-title
                            ><v-icon
                              color="error"
                              style="height: 16px; font-size: 16px; color: red"
                              >mdi-close-circle</v-icon
                            >
                            De-Activate</v-list-item-title
                          >
                        </v-list-item>
                        <v-list-item
                          v-else
                          link
                          @click="changeQuestionActiveStatus(item, true)"
                        >
                          <v-list-item-title
                            ><v-icon
                              color="success"
                              style="height: 16px; font-size: 16px"
                              >mdi-check-circle</v-icon
                            >
                            Activate</v-list-item-title
                          >
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action-text>
                </v-list-item-action>
              </template>
            </v-data-table>
          </v-col>
        </v-card>
      </v-col>
    </v-row>

    <QuestionUpload
      :categoryId="categoryId"
      :categoryRelayID="categoryRelayID"
      :uploadDialog="uploadDialog"
      @close="uploadDialog = false"
    />
    <NewQuestionForm
      :categoryId="categoryId"
      :categoryRelayID="categoryRelayID"
      :createDialog="createDialog"
      :questions="question"
      @close="createDialog = false"
    />
    <EditQuestion
      :EditQuestionDialog="editQuestionDialog"
      :questionDetails="questionsDetails"
      @close="editQuestionDialog = false"
    />

    <template v-slot:item.active_status="{ item }">
      <v-btn
        v-if="item.active_status"
        class="text-capitalize"
        color="success"
        dark
        depressed
        rounded
        x-small
      >
        {{ item.active_status }}
      </v-btn>
      <v-btn
        v-else
        class="text-capitalize"
        color="error"
        dark
        depressed
        rounded
        x-small
      >
        {{ item.active_status }}
      </v-btn>
    </template>
  </v-container>
</template>

<script>
import QuestionUpload from "./QuestionUpload.vue";
import NewQuestionForm from "./NewQuestionForm.vue";
import EditQuestion from "./EditQuestions.vue";

export default {
  data: () => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    question: "Add New Question",
    questionsDetails: null,
    optionData: null,
    chartValuesLoaded: false,
    answerOptions: [],
    hasAttachment: false,
    createDialog: false,
    editQuestionDialog: false,
    uploadDialog: false,
    dialog: false,
    dialogDelete: false,
    singleSelect: false,
    selecteQuestionCategory: null,
    category: null,
    chartsValues: [],
    questionCategories: [],
    AllQuestionsBank: [],
    selected: [],
    headers: [
      { text: "Question Descriptions", value: "description" },
      { text: "Has Attachment", value: "hasAttachment" },
      { text: "Difficult", value: "dificult" },
      { text: "Status", value: "isActive" },
      { text: "Question Category", value: "category" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  components: {
    QuestionUpload,
    NewQuestionForm,
    EditQuestion,
  },

  mounted() {
    let dataVariables = {
      questionCategory: this.categoryRelayID,
      limit: 1000,
      offset: 0,
    };
    this.$store.dispatch("loadCategoryQuestions", dataVariables);
    this.$store.dispatch("loadQuestionDifficulties");
  },

  computed: {
    questionCategores() {
      return this.$store.state.CategoriesStore.categorylist;
    },
    questions() {
      return this.$store.state.QuestionStore.questionList;
    },
    categoryName() {
      return this.$store.state.CategoriesStore.selectedCategory.categoryName;
    },
    categoryUniqueId() {
      return this.$store.state.CategoriesStore.selectedCategory
        .categoryUniqueId;
    },
    categoryId() {
      return this.$store.state.CategoriesStore.selectedCategory.categoryId;
    },
    categoryRelayID() {
      return this.$store.state.CategoriesStore.selectedCategory.categoryRelayID;
    },
  },

  methods: {
    backToCategory() {
      this.$router.push({ path: "/latradts/question_bank" });
    },
    openEditQuestionDialog: function (item) {
      this.editQuestionDialog = true;
      this.questionsDetails = item;
    },
    navigateToQuestions(categoryUniqueId, categoryName, categoryId, relayId) {
      var selected = {
        categoryUniqueId: categoryUniqueId,
        categoryName: categoryName,
        categoryId: categoryId,
        categoryRelayID: relayId,
      };
      this.$store.dispatch("updateSelectedCategory", selected);
      this.$router.push({ name: "QuestionsList" });
    },
    changeQuestionActiveStatus(item, booleanValue) {
      let data = {
        questionIsActive: booleanValue,
        questionUniqueId: item.questionUniqueId,
      };
      this.$store.dispatch("deactivateQuestion", data);
    },
  },
  watch: {
    page: function () {
      console.log(this.page);
      let dataVariables = {
        questionCategory: this.categoryRelayID,
        limit: 1000,
        offset: 10 * this.page,
      };
      this.$store.dispatch("loadCategoryQuestions", dataVariables);
    },
  },
  close() {
    this.dialog = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  },
};
</script>
