<template>
  <v-container fluid>
      <v-row>
        <v-col lg="3">
          <v-alert
            border="left"
            colored-border
            color="warning"
            elevation="2"
            class="rounded-lg">
            <div class="d-flex justify-content-between align-items-vertically-center pa-2">
              <v-icon x-large color="warning">
                mdi-history
              </v-icon>
              <span class="d-flex justify-content-center flex-column align-items-center">
                  <h1>32</h1>
                  <span>Total Exams</span>
              </span>
            </div>
          </v-alert>
        </v-col>
        <v-col lg="3">
          <v-alert
            border="left"
            colored-border
            color="success"
            elevation="2"
            class="rounded-lg">
            <div class="d-flex justify-content-between align-items-vertically-center pa-2">
                <v-icon x-large color="success">mdi-check-circle</v-icon>
                <span class="d-flex justify-content-center flex-column align-items-center">
                    <h1>28</h1>
                    <span >Passed Exams</span>
                </span>
            </div>
          </v-alert>
        </v-col>
        <v-col lg="3">
          <v-alert
            border="left"
            colored-border
            color="error"
            elevation="2"
            class="rounded-lg">
            <div class="d-flex justify-content-between align-items-vertically-center pa-2">
                <v-icon x-large color="error">mdi-check-circle</v-icon>
                <span class="d-flex justify-content-center flex-column align-items-center">
                    <h1>4</h1>
                    <span >Failed Exams</span>
                </span>
            </div>
          </v-alert>
        </v-col>
        <v-col lg="3">
          <v-alert
            border="left"
            colored-border
            color="info"
            elevation="2"
            class="rounded-lg">
            <div class="d-flex justify-content-between align-items-vertically-center flex-column pa-2">
                <span class="d-flex justify-content-center flex-column align-items-center">
                  
                  <v-btn v-if="false"
                    color="success"
                    raised
                    style="width:100%;height:50px"
                  ><v-icon>mdi-note-edit</v-icon> Take Exam</v-btn>
                <v-btn v-else-if="true" color="error"
                   style="height:50px"
                    raised>
                    <v-icon>mdi-close-circle</v-icon> 
                    Cancel Booking
                </v-btn>
                <v-btn v-else-if="value" color="error"
                   style="height:50px"
                    raised>
                    <v-icon>mdi-recycle-variante</v-icon> 
                    Rebook
                </v-btn>
                </span>
                <span class="d-flex justify-content-center flex-column align-items-center">
                    <span >Recent Booking: 21 Sept, 2021</span>
                </span>
            </div>
          </v-alert>
        </v-col>
  </v-row>
  </v-container>
</template>

<script>
  export default {
    
  }
</script>

<style>
.d-flex{
  display: flex;
}
.justify-content-center{
  justify-content: center;
} 
.flex-column{
flex-direction: column;
}
.align-items-center{
  align-items: center;
}
</style>