<template>
  <v-container fluid>
      <v-row>
   <v-col sm="12" md="6">
   <v-card
   class="mx-auto mt-5"
    max-width="100%"
    outlined
    elevation="2"
    shaped
    tile
    link
    
  >  

    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">
          <h1 style="color:#0e6499">{{$store.state.BookingStore.bookinglist.length}}</h1>
        </div>
        <v-list-item-title class="text-h5 mb-1 w-100">
           Total Booking
        </v-list-item-title>
        <!-- <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle> -->
      </v-list-item-content>

      <v-list-item-avatar
        tile
        size="100"
        color="white"
      ><v-img  link to="/" src="/booking.png"></v-img></v-list-item-avatar>
    </v-list-item>
  </v-card>
   </v-col>
   <v-col sm="12" md="6">
  <v-card
    class="mx-auto mt-5"
    max-width="100%"
    outlined
    elevation="2"
    shaped
    tile
    link
    
  >  
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">
           <h1 style="color:#0e6499">{{candidates}}</h1>
        </div>
        <v-list-item-title class="text-h5 mb-1">
          Total Candidate
        </v-list-item-title>
      </v-list-item-content>

      <v-list-item-avatar
        tile
        size="100"
        color="white"
      >
      <v-img  link to="/" src="/candidate.png"></v-img>
      </v-list-item-avatar>
    </v-list-item>

    
  </v-card>
   </v-col>
    <v-col sm="12" md="6">
   <v-card
   class="mx-auto mt-5"
    max-width="100%"
    outlined
    elevation="2"
    shaped
    tile
    link
    
  >  

    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">
          <h1 style="color:#0e6499">1</h1>
        </div>
        <v-list-item-title class="text-h5 mb-1">
          Candidate Pass 
        </v-list-item-title>
        <!-- <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle> -->
      </v-list-item-content>

      <v-list-item-avatar
        tile
        size="100"
        color="white"
      >
      <v-img  link to="/" src="/pass.png"></v-img>
      </v-list-item-avatar>
    </v-list-item>

    
  </v-card>
    </v-col>
     <v-col sm="12" md="6">
   <v-card
    class="mx-auto mt-5"
    max-width="100%"
    outlined
    elevation="2"
    shaped
    tile
    link
    
  >  

    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">
         <h1 style="color:#0e6499">1</h1>
        </div>
        <v-list-item-title class="text-h5 mb-1">
          Candidate Fail
        </v-list-item-title>
        <!-- <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle> -->
      </v-list-item-content>

      <v-list-item-avatar
        tile
        size="100"
        color="white"
      >
      <v-img  src="/fail.png"></v-img></v-list-item-avatar>
    </v-list-item>
  </v-card>
     </v-col>
</v-row>
<v-row>
    <v-col sm="6" cols="6" xs="6" md="6">
    <v-card shaped hover>
        
    <examinationResultPerformanceBarChart :dataLabels="dataLabels"/>
    </v-card>
    </v-col>
    <v-col sm="6" cols="6" xs="6" md="6">
    <v-card shaped hover>
    <examinationTypesPie :dataValues="dataValues"/>
    </v-card>
    </v-col>
</v-row>
  </v-container>
</template>

<script>
import examinationTypesPie from '../latradts_report/latradts_charts/examinationTypesPie.vue'
import examinationResultPerformanceBarChart from '../latradts_report/latradts_charts/examinationResultPerformanceBarChart.vue'
  export default {
    data () {
      return {

      }
    },
    components:{
        examinationTypesPie,
        examinationResultPerformanceBarChart
    },
    
    mounted () {
      this.$store.dispatch('getAllSessionsReports')
      this.$store.dispatch('AllresultPeformance')
      this.$store.dispatch('AllCandidateReport')

    },

    computed: {
       Bookings: function(){
          return this.$store.state.ReportStore.examinationSession.sessionBookings
        },
        candidates: function(){
            return this.$store.state.ReportStore.resultPerformance.ExaminationCandidates
        },

        dataLabels: function(){
            var labels=[]
            var examResultType = this.$store.state.ReportStore.resultPerformance.examinationResultsTypes
            examResultType.forEach(data => {
                labels.push({ value:data.percentage, name: data.resultType  } )
        });
        return labels;
        },
        
        dataValues: function(){
           var values=[]
            var examType = this.$store.state.ReportStore.resultPerformance.examTypes
            examType.forEach(data => {
                values.push({ value: data.count, name: data.examination })
        });
        return values;
        },

    },
  }
</script>