<template>
  <v-row justify="center">
    <v-spacer></v-spacer>
    <v-dialog
      v-model="editCategoryVisible"
      max-width="500"
      persistent
    >
      <v-card class="pa-3">
        <v-card-title class="text-h5">
         Edit Question Catergory
        </v-card-title>

        <v-card-text>
          <v-text-field
          class="mt-5 mb-0"
            v-model="category_name"
            label="Category Name"
            autofocus
            placeholder="Category Name"
            outlined
            required
          ></v-text-field>
          <v-checkbox
            v-model="category_active"
            label="Active"
          ></v-checkbox>
        </v-card-text>

        <v-card-actions class="mb-3">
          <v-spacer></v-spacer>

          <v-btn
            color="red darken-1"
            text
            @click="$emit('closeEdit')"
          >
            <v-icon small>mdi-close</v-icon> Cancel
          </v-btn>

          <v-btn
            color="orange"
            dark
            @click="updateQuestionCategory()"
          >
            <v-icon small>mdi-checkbox-marked-circle-outline</v-icon> Submit Changes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
    props:['editCategoryVisible','categoryIsActive','categoryUniqueId','categoryName'],
     data: () => ({
        category_name:'',
        category_uniqueId:'',
        category_active:false
    }),
   methods:{
       updateQuestionCategory:function(){
         this.category_uniqueId=this.uniqueId
         let data={
           categoryName:this.category_name,
           categoryUniqueId:this.category_uniqueId,
           categoryIsActive:this.category_active
         }
         this.$store.dispatch('updateQuestionCategory',data).then(()=>{
           this.$emit('closeEdit')
         })
          // this.category_name=this.name
          // this.category_uniqueId=this.uniqueId
          // this.category_active=this.active
          // this.category_name=this.name
          //  this.$apollo.mutate({
          //      mutation:updateQuestionCategory,
          //      variables:
          //  }).then((response)=>{
          //      console.log(response)
          //       if(response){
          //         this.$emit('closeEdit')
          //         this.$swal({
          //             toast:true,
          //             position:'top-right',
          //             title:'Category Updated Succesfully!',
          //             timerProgressBar:true,
          //             timer:2000,
          //             icon:'success',
          //             showConfirmButton:false,
          //             animation:false,
          //           });
          //       }
          //  })
       }
   },computed:{

    },watch:{
    editCategoryVisible:function () {
      this.category_name=this.categoryName
      this.category_uniqueId=this.categoryUniqueId
      this.category_active=this.categoryIsActive
    }
  }
}
</script>

<style>

</style>