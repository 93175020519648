<template>
  <v-container fluid class="my-5">
    <v-row>
      <v-col cols="6" md="6" sm="6">
        <div class="spinner-box23">
          <v-text-field
            label="Put your email to recieve set password link"
            name="Email"
            v-model="email"
            append-icon="mdi-email"
            type="email"
            color="#3f51b5"
          />
          <v-layout justify-center wrap>
            <v-btn style="margin: 20px" link to="/" rounded color="#3f51b5" dark
              ><v-icon>mdi-arrow-left</v-icon> GO TO HOME PAGE</v-btn
            >
            <v-btn
              style="margin: 20px"
              @click="sendPasswordResetEmail"
              rounded
              color="#3f51b5"
              dark
              >SEND LINK <v-icon>mdi-email-fast</v-icon></v-btn
            >
          </v-layout>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      userToken: null,
      absolute: true,
      overlay: true,
      email: null,
    };
  },
  methods: {
    async sendPasswordResetEmail() {
      this.$store.dispatch("showLoadingPinner", true);
      await this.$store.dispatch("sendPasswordResetEmail", this.email);
      this.email = null;
      this.$store.dispatch("showLoadingPinner", false);
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.spinner-box23 {
  width: 50%;
  padding: 20px;
  position: fixed;
  margin: auto;
  left: 0px;
  top: 40%;
  right: 0px;
  background: white;
  box-shadow: 0px 0px 9px 0px #888cbf;
  text-align: center;
}
</style>
