<template>
   <v-app id="inspire">
      <v-main>
         <v-container fluid fill-height>
            <v-layout align-center justify-center>
               <v-flex xs12 sm8 md5>
                  <v-card class="elevation-12 pb-5">
                     <v-toolbar dark color="rgba(48, 67, 194, 0.76)">
                        <v-toolbar-title dense>LATRA - Change Password</v-toolbar-title>
                     </v-toolbar>
                     <v-card-text>
                        <v-form @submit.prevent="loginUser" class="pa-5">
                           <v-text-field
                              prepend-inner-icon="mdi-account"
                              outlined
                              class="rounded-lg mx-5 mt-5"
                              v-if="!authDetails.username"
                              label="Email"
                              type="text"
                              v-model="authDetails.username"
                           ></v-text-field>
                           <v-text-field
                              id="password"
                              prepend-inner-icon="mdi-lock"
                              outlined
                              class="rounded-lg mx-5"
                              v-model="password1"
                              @keyup="confirmPassword"
                              label="Password"
                              type="password"
                           ></v-text-field>
                           <v-text-field
                              prepend-inner-icon="mdi-lock"
                              outlined
                              class="rounded-lg mx-5"
                              @keyup="confirmPassword"
                              v-model="password2"
                              label="Confirm Password"
                              type="password"
                           ></v-text-field>
                           <p v-if="passwordMatch&&passwordErrorMessage" class="px-5" style="color:green;"> <v-icon color="green" >mdi-check-circle</v-icon> Passwords Matched</p>
                           <p v-else-if="!passwordMatch&&passwordErrorMessage" class="px-5" style="color:red;font-weight:bold"><v-icon color=red>mdi-close-circle</v-icon> Passwords fields do not match</p>
                     <v-card-actions class="px-1 justify-center">
                        <v-btn class="white--text rounded-lg mb-3 mx-5 auth-submit pa-4" color="rgba(48, 67, 194, 0.76)" @click="updatePassword" ><v-icon small>mdi-lock-open-outline</v-icon> Change Password</v-btn>
                     </v-card-actions>
                        </v-form>
                     </v-card-text>
                  </v-card>
               </v-flex>
            </v-layout>
         </v-container>
      </v-main>
   </v-app>
</template>

<script>
// import { mapActions } from 'vuex'
import updateUserPassword from '../../../graphql/updateUserPassword.graphql'

export default {
   name: 'Login',
   props: {
      source: String,
   },
   data () {
    return {
      password1:'',
      password2: '',
      passwordMatch:false,
      passwordErrorMessage:false,
      authDetails: {
        username: '',
        password: ''
      }
    }
   },
   methods: {
       confirmPassword:function() {
         if(this.password1.length>0&&this.password2.length>0){
             this.passwordErrorMessage=true
             if(this.password1==this.password2){
                this.passwordMatch=true;
             }else{
                 this.passwordMatch=false
             }
         }else{
             this.passwordErrorMessage=false
         } 
       },
    // ...mapActions(['login']),
    updatePassword: function () {
         this.authDetails.password=this.password2
      this.$apollo.mutate({
        mutation:updateUserPassword,
        variables:{
          email:this.authDetails.username,
          newPassword:this.password2
        }
      }).then((response)=>{

        if(response.data.updateUserPassword.success){
          this.$store.dispatch('login',this.authDetails)
        }
      })


    }
  },mounted() {
    this.authDetails.username=this.$route.params.email
  }
};
</script>

<style></style>
