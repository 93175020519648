import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../modules/latradts_system/latradts_authentication/Login.vue";
import PasswordReset from "../modules/latradts_system/latradts_authentication/PasswordReset.vue";
import ForgotPassorwd from "../modules/latradts_system/latradts_authentication/ForgotPassorwd.vue";
import PasswordResetAccount from "../modules/latradts_system/latradts_authentication/PasswordResetAccount.vue";
import SuccessfulActivation from "../modules/latradts_system/latradts_authentication/SuccessfulActivation.vue";
import VerifyUser from "../modules/latradts_system/latradts_authentication/VerifyUser";
import resetPasswordEmail from "../modules/latradts_system/latradts_authentication/resetPasswordEmail.vue";
import cordinators from "../modules/latradts_cordinators/cordinators.js";
import MainLanding from "../modules/latradts_landings/MainLanding.vue";
import candidate from "../modules/latradts_candidate/candidate.js";
import invigilator from "../modules/latradts_invigilator/invigilator.js";
import settings from "../modules/latradts_system/settings.js";
import store from "../store";



require("dotenv").config();
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: MainLanding,
    meta: { requiresAuth: true }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/password_reset/:email/",
    name: "PasswordReset",
    component: PasswordReset,
  },
  {
    path: "/successful_activation",
    name: "SuccessfulActivation",
    component: SuccessfulActivation,
  },
  {
    path: "/reset_password_email",
    name: "resetPasswordEmail",
    component: resetPasswordEmail,
  },
  {
    path: "/forgot_password",
    name: "ForgotPassorwd",
    component: ForgotPassorwd,
  },
  {
    path: '/password-reset/:userToken',
    name: 'PasswordResetAccount',
    component: PasswordResetAccount,
    meta: { requiresGuest: true }
  },
  {
    path: "/activate/:uuid/:email/",
    name: "SuccessfulActivation",
    component: SuccessfulActivation,
  },
  {
    path: "/verifyUser/:authToken/",
    name: "VerifyUser",
    component: VerifyUser,
  },
  ...cordinators,
  ...candidate,
  ...settings,
  ...invigilator

];



const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Global Route Guards
router.beforeEach((to, from, next) => {
  // Check if the user is logged in
  const isUserLoggedIn = store.getters.isAuthenticated
  // console.log(store.getters.isAuthenticated)
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isUserLoggedIn) {
      store.dispatch('logOut')
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })

    } else {
      next()
    }
  } else {
    next()
  }
})

export default router;
