import {apolloClient} from '@/vue-apollo'
import allVenues from '../../graphql/VenuesAdmin/allVenues.gql'
import addNewExmainationVenue from "../../graphql/VenuesAdmin/addNewExmainationVenue.gql";
import updateExaminationVenue from "../../graphql/VenuesAdmin/updateExaminationVenue.graphql"
import changeVenueActiveStatus from "../../graphql/VenuesAdmin/changeVenueActiveStatus.graphql"
import Vue from "vue";

export default {
    state: {
        allVenues:[]
    },
    mutations: {
        LoadVenues(state,data){
            state.allVenues.push(data)
        },
        editExaminationVenues(state,data){
            if(state.allVenues.find(({primaryKey}) => primaryKey === data.primaryKey)){
                state.allVenues.find(({primaryKey}) => primaryKey === data.primaryKey).venuesCapacity=data.venuesCapacity
                state.allVenues.find(({primaryKey}) => primaryKey === data.primaryKey).nextExamination=data.nextExamination
                state.allVenues.find(({primaryKey}) => primaryKey === data.primaryKey).venueName=data.venueName
                state.allVenues.find(({primaryKey}) => primaryKey === data.primaryKey).region=data.region
                state.allVenues.find(({primaryKey}) => primaryKey === data.primaryKey).capacity=data.capacity
                state.allVenues.find(({primaryKey}) => primaryKey === data.primaryKey).available=data.available
                state.allVenues.find(({primaryKey}) => primaryKey === data.primaryKey).isActive=data.isActive
                // state.allVenues.find(({primaryKey}) => primaryKey === data.primaryKey).replace(data)
            }
        },
        updateVenueActiveStatus(state,data){
            if(state.allVenues.find(({primaryKey}) => primaryKey === data.primaryKey)){
                state.allVenues.find(({primaryKey}) => primaryKey === data.primaryKey).isActive=data.isActive
            }
        },
        clearVenues(state){
            state.allVenues=[]
        }
    },
    actions: {
        async LoadVenues(context){
            context.commit('clearVenues')

            apolloClient.query({
                fetchPolicy:"no-cache",
                query:allVenues
            }).then((response)=>{
                // console.log(response.data.allVenues.edges)
                response.data.allVenues.edges.forEach(element=>{
                    let timetableExamination={
                        date:"",
                        time:""
                    }
                    if(element.node.timetableVenue.edges.length>0){
                        let timeTemporary=element.node.timetableVenue.edges[0].node.timetableTime
                        let tempDate=new Date(element.node.timetableVenue.edges[0].node.timetableDate+' '+timeTemporary)

                        element.node.timetableVenue.edges.forEach(timetableSession=>{
                            if (new Date(timetableSession.node.timetableDate+' '+timetableSession.node.timetableTime)>new Date()){
                                if(tempDate>=new Date(timetableSession.node.timetableDate+' '+timetableSession.node.timetableTime)){
                                    tempDate=new Date(timetableSession.node.timetableDate+' '+timetableSession.node.timetableTime)
                                }
                            }
                        })

                        element.node.timetableVenue.edges.forEach(timetableSession=>{
                            if (tempDate.toString()===new Date(timetableSession.node.timetableDate+' '+timetableSession.node.timetableTime).toString()&&tempDate>new Date()){
                                timetableExamination={
                                    date:timetableSession.node.timetableDate,
                                    time:timetableSession.node.timetableTime
                                }
                            }
                        })
                    }

                    let data={
                        primaryKey:element.node.primaryKey,
                        venuesUniqueId:element.node.venuesUniqueId,
                        venuesCapacity:element.node.venuesCapacity,
                        nextExamination:timetableExamination,
                        venueName:element.node.venuesName,
                        region:element.node.venuesRegion,
                        capacity:element.node.venuesCapacity,
                        available:element.node.venuesAvailableSeats,
                        isActive:element.node.venuesIsActive
                    }
                    context.commit('LoadVenues',data)
                })

            })

        },
        async addVenue(context,data){
            apolloClient.mutate({
                mutation:addNewExmainationVenue,
                variables:data
            }).then((response)=>{
                if(response.data){
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Venue Added Successfully!',
                        timerProgressBar:true,
                        timer:10000,
                        icon:'success',
                        showConfirmButton:false,
                        animation:false,
                    })
                }else{
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Oops!, Looks like an error occurred',
                        timerProgressBar:true,
                        timer:10000,
                        icon:'success',
                        showConfirmButton:false,
                        animation:false,
                    })
                }
                console.log(response)
                let timetableExamination={
                    date:"",
                    time:""
                }
                let tempDate;
                let tempTime;
                response.data.addNewExmainationVenue.venue.timetableVenue.edges.forEach(timetableSession=>{
                    if (new Date(timetableSession.node.timetableDate)>new Date()){
                        tempDate=new Date()
                        if(tempDate>new Date(timetableSession.node.timetableDate)){
                            tempDate=new Date(timetableSession.node.timetableDate)
                        }
                    }
                    if (new Date(timetableSession.node.timetableTime)>new Date()){
                        tempTime=new Date()
                        if(tempTime>new Date(timetableSession.node.timetableTime)){
                            tempTime=new Date(timetableSession.node.timetableTime)
                        }
                    }
                })
                response.data.addNewExmainationVenue.venue.timetableVenue.edges.forEach(timetableSession=>{
                    if (tempDate===new Date(timetableSession.node.timetableDate)&& tempTime===new Date(timetableSession.node.timetableTime)){
                        timetableExamination={
                            date:timetableSession.node.timetableDate,
                            time:timetableSession.node.timetableTime
                        }
                    }
                })
                // console.log(response.data.addNewExmainationVenue.venue.timetableVenue.edges.find(({timetableSession})=> timetableSession.node.timetableDate ===tempDate))
                let data={
                    primaryKey:response.data.addNewExmainationVenue.venue.primaryKey,
                    venuesUniqueId:response.data.addNewExmainationVenue.venue.venuesUniqueId,
                    venuesCapacity:response.data.addNewExmainationVenue.venue.venuesCapacity,
                    nextExamination:timetableExamination,
                    venueName:response.data.addNewExmainationVenue.venue.venuesName,
                    region:response.data.addNewExmainationVenue.venue.venuesRegion,
                    capacity:response.data.addNewExmainationVenue.venue.venuesCapacity,
                    available:response.data.addNewExmainationVenue.venue.venuesAvailableSeats,
                    isActive:response.data.addNewExmainationVenue.venue.venuesIsActive
                }
                context.commit('LoadVenues',data)
            })
        },
        async EditVenue(context,data){
            apolloClient.mutate({
                mutation:updateExaminationVenue,
                variables:data
            }).then((response)=>{
                if(response.data){
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Venue Updated Successfully!',
                        timerProgressBar:true,
                        timer:10000,
                        icon:'success',
                        showConfirmButton:false,
                        animation:false,
                    })
                }else{
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Oops!, Looks like an error occurred',
                        timerProgressBar:true,
                        timer:10000,
                        icon:'success',
                        showConfirmButton:false,
                        animation:false,
                    })
                }
                console.log(response)
                let timetableExamination={
                    date:"",
                    time:""
                }
                response.data.updateExmainationVenue.updatedVenue.timetableVenue.edges.forEach(timetableSession=>{
                    if (new Date(timetableSession.node.timetableDate)>new Date()){
                        timetableExamination={
                            date:timetableSession.node.timetableDate,
                            time:timetableSession.node.timetableTime
                        }
                        return true;
                    }
                })
                let data={
                    primaryKey:response.data.updateExmainationVenue.updatedVenue.primaryKey,
                    venuesUniqueId:response.data.updateExmainationVenue.updatedVenue.venuesUniqueId,
                    venuesCapacity:response.data.updateExmainationVenue.updatedVenue.venuesCapacity,
                    nextExamination:timetableExamination,
                    venueName:response.data.updateExmainationVenue.updatedVenue.venuesName,
                    region:response.data.updateExmainationVenue.updatedVenue.venuesRegion,
                    capacity:response.data.updateExmainationVenue.updatedVenue.venuesCapacity,
                    available:response.data.updateExmainationVenue.updatedVenue.venuesAvailableSeats,
                    isActive:response.data.updateExmainationVenue.updatedVenue.venuesIsActive
                }
                context.commit('editExaminationVenues',data)
            })
        },
        async ChangeVenueActiveStatus(context,data){
            apolloClient.mutate({
                mutation:changeVenueActiveStatus,
                variables:data
            }).then((response)=>{
                if(response.data){
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Active Status Updated Successfully!',
                        timerProgressBar:true,
                        timer:10000,
                        icon:'success',
                        showConfirmButton:false,
                        animation:false,
                    })
                }else{
                    Vue.swal({
                        toast:true,
                        position:'top-right',
                        title:'Oops!, Looks like an error occurred',
                        timerProgressBar:true,
                        timer:10000,
                        icon:'success',
                        showConfirmButton:false,
                        animation:false,
                    })
                }
                console.log(response)
                let data={
                    primaryKey:response.data.changeVenueActiveStatus.updatedVenue.primaryKey,
                    venuesUniqueId:response.data.changeVenueActiveStatus.updatedVenue.venuesUniqueId,
                    isActive:response.data.changeVenueActiveStatus.updatedVenue.venuesIsActive
                }
                context.commit('updateVenueActiveStatus',data)
            })
        },
    }
}