import SystemMain from './SystemMain.vue'
import UsersList from './system_settings/users/UsersList.vue'
import VenuesManagements from './system_settings/venues/VenuesManagements.vue'
import ExaminationSettings from './system_settings/examination_settings/ExaminationSettings.vue'
import ExaminationSections from './system_settings/ExaminationSections/ExaminationSectionMain.vue'
import QuestionSettings from './system_settings/question/QuestionSettings.vue'
import TimetableSettings from './system_settings/timetable/TimetableSettings.vue'
import ExaminationManagement from './system_settings/examination/ExaminationManagement.vue'
import BookingManagement from './system_settings/booking/BookingManagement.vue'
import ExaminationBookingList from './system_settings/booking/ExaminationBookingList.vue'
import TestCenterConfiguration from './system_settings/fingerprint_device_configuration/FingerprintDeviceConfiguration'
import sessionReport from '../latradts_report/sessionReport.vue'
import testCenterReport from '../latradts_report/testCenterReport.vue'
import candidateReport from '../latradts_report/candidateReport.vue'
import resultreport from '../latradts_report/resultreport.vue'

export default [
    {
        path: '/latradts_settings',
        name:'CandidateMain',
        component: SystemMain,
        meta: { requiresAuth: true },
        children:[
            {
                path: 'users',
                name:'Users',
                component: UsersList,
            },
            {
                path: 'venues',
                name:'ExaminationVenues',
                component: VenuesManagements,
            },
            {
                path: 'examination_settings',
                name:'Examination',
                component: ExaminationSettings,
            },
            {
                path: 'examination_sections',
                name:'ExaminationSections',
                component: ExaminationSections,
            },
            {
                path: 'examination',
                name:'Examination',
                component: ExaminationManagement,
            },
            {
                path: 'questions',
                name:'Question',
                component: QuestionSettings,
            },
            {
                path: 'timetable',
                name:'TimetableSettings',
                component: TimetableSettings,
            },
            {
                path: 'timetable/:venueName',
                name:'TimetableSettings',
                component: TimetableSettings,
            },
            {
                path: 'booking',
                name:'BookingManagement',
                component: BookingManagement,
            },
            {
                path: 'examination_booking_list',
                name:'ExaminationBookingList',
                component: ExaminationBookingList,
            },
            {
                path: 'fingerprint_device_configuration',
                name:'TestCenterConfiguration',
                component: TestCenterConfiguration,
            },

            {
                path: 'sessionReport',
                name:'sessionReport',
                component: sessionReport,
            },

            {
                path: 'testCenterReport',
                name:'testCenterReport',
                component: testCenterReport,
            },
            {
                path: 'candidateReport',
                name:'candidateReport',
                component: candidateReport,
            },
            {
                path: 'resultreport',
                name:'resultreport',
                component: resultreport,
            },
        ]
     },    
]