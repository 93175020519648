<template>
  <v-container fluid class="pa-0" style="background: url('~@/assets/flag_login.png') no-repeat center center fixed !important">
      <SystemToolBar/>
      <router-view/>
  </v-container>
</template>

<script>
import SystemToolBar from './system_settings/shared/SystemToolBar.vue'
export default {
  components:{
    SystemToolBar
  }
}
</script>

<style>

</style>