import CandidateMain from './CandidateMain.vue'
import CandidateExamination from './latradts_candidate_examination/CandidateExamination.vue'
import CandidateDashboard from './latradts_candidate_dashboard/CandidateDashboard.vue'
import CandidateBooking from './latradts_candidate_booking/CandidateBooking.vue'
import resultreport from '../latradts_report/resultreport.vue'
import candidateReport from '../latradts_report/candidateReport.vue'
import sessionReport from '../latradts_report/sessionReport.vue'
import testCenterReport from '../latradts_report/testCenterReport.vue'
import testPerformanceReport from '../latradts_report/testPerformanceReport.vue'

export default [
    {
        path: '/latradts_canditate',
        name:'CandidateMain',
        component: CandidateMain,
        meta: { requiresAuth: true },
        children:[
            {
                path: 'exam',
                name:'Examination',
                component: CandidateExamination,
            },
            {
                path: 'resultreport',
                name:'resultreport',
                component: resultreport,
            },
            {
                path: 'candidateReport',
                name:'candidateReport',
                component: candidateReport,
            },
            {
                path: 'sessionReport',
                name:'sessionReport',
                component: sessionReport,
            },
            {
                path: 'testCenterReport',
                name:'testCenterReport',
                component: testCenterReport,
            },
            {
                path: 'testPerformanceReport',
                name:'testPerformanceReport',
                component: testPerformanceReport,
            },
            
            {
                path: 'dashboard',
                name:'CandidateDashboard',
                component: CandidateDashboard,
            } ,
            {
                path: 'booking',
                name:'CandidateBooking',
                component: CandidateBooking,
            }
            
        ]
     },    
]