<template>
  <v-dialog
      v-model="confirmationDialog"
      max-width="400"
      persistent
  >
    <v-card>
      <v-toolbar
          color="error"
          dark
      >Onyo!</v-toolbar>
      <v-card-text class="mt-5">Unakaribia kufunga Mtihani huu, Kuendelea bonyeza Funga Mtihani.</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            class="px-5"
            color="green darken-1"
            text
            @click="$emit('close')"
        >
        Sitisha
        </v-btn>
        <v-btn
            class="px-5 rounded-lg"
            color="error"
            @click="confirmationDialog = false"
        >
          Funga Mtihani
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "confirmationDialog",
  data(){
    return{
      confirmationDialog:false
    }
  },props:['ConfirmationDialog'],
  watch:{
    ConfirmationDialog:function (){
      this.confirmationDialog=this.ConfirmationDialog
    }
  }
}
</script>

<style scoped>

</style>