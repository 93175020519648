var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""},scopedSlots:_vm._u([{key:"item.active_status",fn:function(ref){
var item = ref.item;
return [(item.active_status)?_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"success","dark":"","depressed":"","rounded":"","x-small":""}},[_vm._v(" "+_vm._s(item.active_status)+" ")]):_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"error","dark":"","depressed":"","rounded":"","x-small":""}},[_vm._v(" "+_vm._s(item.active_status)+" ")])]}}])},[_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('v-row',{attrs:{"dense":"","cols":"12"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"rgba(48, 67, 194, 0.76)","dark":"","fab":"","small":"","right":""},on:{"click":_vm.backToCategory}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-spacer'),_c('v-btn',{staticClass:"rounded-xl",attrs:{"color":"primary","download":"","href":"https://osat.all.co.tz:9006/latradts_media/questions_bank.xlsx"}},[_c('v-icon',[_vm._v("mdi-download")]),_vm._v("Download Template")],1),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"rgba(48, 67, 194, 0.76)","dark":"","fab":"","small":"","right":""},on:{"click":function($event){_vm.createDialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-btn',{staticClass:"mx-2",staticStyle:{"float":"right"},attrs:{"color":"rgba(48, 67, 194, 0.76)","dark":"","fab":"","small":"","right":""},on:{"click":function($event){_vm.uploadDialog = true}}},[_c('v-icon',[_vm._v("mdi-file-upload")])],1)],1),_c('v-card',{staticClass:"mt-4"},[_c('v-card-title',[_c('v-toolbar-title',{attrs:{"color":"orange ","text":""}},[_vm._v(" "+_vm._s(_vm.categoryName)+" ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"color":"orange ","inset":"","vertical":""}}),_c('v-spacer')],1),_c('v-col',{attrs:{"sm":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.questions,"item-key":"name","show-select":""},scopedSlots:_vm._u([{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [(item.isActive)?_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"success","depressed":"","rounded":"","x-small":""}},[_vm._v("Active")]):_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"warning","depressed":"","rounded":"","x-small":""}},[_vm._v("In-Active")])]}},{key:"item.hasAttachment",fn:function(ref){
var item = ref.item;
return [(item.hasAttachment)?_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"success","depressed":"","rounded":"","x-small":""}},[_vm._v("Yes")]):_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"warning","depressed":"","rounded":"","x-small":""}},[_vm._v("No")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-action',[_c('v-list-item-action-text',[_c('v-menu',{attrs:{"app":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"slot":"activator","color":"rgba(39, 166, 245, 0.53)","icon":""},slot:"activator"},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.openEditQuestionDialog(item)}}},[_c('v-icon',{staticStyle:{"height":"16px","font-size":"16px"},attrs:{"color":"orange"}},[_vm._v("mdi-pen ")]),_vm._v(" Edit Question")],1)],1),_c('v-divider'),(item.isActive)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.changeQuestionActiveStatus(item, false)}}},[_c('v-list-item-title',[_c('v-icon',{staticStyle:{"height":"16px","font-size":"16px","color":"red"},attrs:{"color":"error"}},[_vm._v("mdi-close-circle")]),_vm._v(" De-Activate")],1)],1):_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.changeQuestionActiveStatus(item, true)}}},[_c('v-list-item-title',[_c('v-icon',{staticStyle:{"height":"16px","font-size":"16px"},attrs:{"color":"success"}},[_vm._v("mdi-check-circle")]),_vm._v(" Activate")],1)],1)],1)],1)],1)],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)],1),_c('QuestionUpload',{attrs:{"categoryId":_vm.categoryId,"categoryRelayID":_vm.categoryRelayID,"uploadDialog":_vm.uploadDialog},on:{"close":function($event){_vm.uploadDialog = false}}}),_c('NewQuestionForm',{attrs:{"categoryId":_vm.categoryId,"categoryRelayID":_vm.categoryRelayID,"createDialog":_vm.createDialog,"questions":_vm.question},on:{"close":function($event){_vm.createDialog = false}}}),_c('EditQuestion',{attrs:{"EditQuestionDialog":_vm.editQuestionDialog,"questionDetails":_vm.questionsDetails},on:{"close":function($event){_vm.editQuestionDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }