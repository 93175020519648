import {apolloClient} from '@/vue-apollo'
import allExaminationBookings from '../../graphql/CandidateBooking/allExaminationBookings.graphql' 


export default {
state: {
    bookinglist: [],
},

mutations: {
    addBookingList(state, booking){
        state.bookinglist.push(booking)
    },
    clearBookingList(state){
        state.bookinglist=[]
    }

},

actions: {
    async listAllBookingCandidate(context, relayId){
        context.commit('clearBookingList')

        apolloClient.query({
            fetchPolicy:"no-cache",
            query:allExaminationBookings,
            variables: {
                bookingExamination: relayId
            }

        }).then((response) => {
            let index=0
            let bookingList=response.data.allExaminationBookings.edges
            bookingList.forEach(element => {
                context.commit('addBookingList',
                    {
                        'serial_number':index+1,
                        'key':element.node.primaryKey,
                        'number':element.node.bookingNumber,
                        'booking_date':new Date(element.node.bookingCreateddate).toString().substr(0,16),
                        'time':element.node.bookingExamination.timetableTime,
                        'actions':element.node.bookingUniqueId,
                        'relayId':element.node.bookingExamination.id,
                        'candidateName':element.node.bookingCandidate.firstName+' ' +element.node.bookingCandidate.lastName,
                        'slots':element.node.bookingExamination.timetableSlots,

                    })
                index++;
            });
        })
    }
}
}