<template>
  <v-card outlined style="">
    <v-card class="pa-5" outlined width="100%">
      <span class="d-flex">
        <v-icon color="info" large left>mdi-trophy</v-icon>
        <h2>
          {{ StatisticsValues.totalAnsweredQuestions }}/{{
            StatisticsValues.totalQuestions
          }}
        </h2>
      </span>
      <p>Maswali Yaliyojibiwa</p>
    </v-card>
    <span
      v-if="
        StatisticsValues.totalAnsweredQuestions !==
        StatisticsValues.totalQuestions
      "
    >
    </span>
    <v-card v-else class="pa-5" outlined>
      <span class="d-flex"
        ><v-icon color="success" large left>mdi-checkbox-marked-circle</v-icon>
        <h1 color="info">Umemaliza</h1></span
      >
      <p>Unaweza ukapitia kisha ukawasilisha Mtihani.</p>
    </v-card>
  </v-card>
</template>

<script>
export default {
  name: "CandidateExaminationStatistics",
  props: ["ExaminationLoaded", "StatisticsValues"],
  data() {
    return {
      totalQuestion: 0,
    };
  },
};
</script>

<style scoped></style>
