<template>
  <v-dialog
      v-model="EditGeneralSettingsDialog"
      max-width="290"
      persistent
  >
    <v-card>
      <v-card-title class="text-h5">
        Edit General Settings
      </v-card-title>

      <v-card-text>
          <v-text-field
              v-model="duration"
              hint="Enter Duration in Hours"
              label="Duration"
              persistent-hint
              required
              type="number"
          >
          </v-text-field>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            color="error"
            text
            @click="$emit('close')"
        >
          Cancel
        </v-btn>

        <v-btn
            color="green darken-1"
            text
            @click="updateGeneralSettings"
        >
          Update
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EditGeneralSettings",
  props:['EditGeneralSettingsDialog','GeneralSettingItems'],
  data(){
    return{
      FormValidStatus: false,
      dialog:false,
      duration:0,
      generalSettingUniqueId:null,
    }
  },
  watch:{
    EditGeneralSettingsDialog:function (){
      this.generalSettingUniqueId=this.GeneralSettingItems.generalSettingUniqueId
      this.duration=this.GeneralSettingItems.generalExaminationDuration
    }
    },
    methods:{
      updateGeneralSettings () {

        let data={
          generalSettingUniqueId:this.generalSettingUniqueId,
          generalExaminationDuration:this.duration
        }
        this.$store.dispatch('updateGeneralSettings',data).finally(()=>{
          this.generalSettingUniqueId=null
          this.duration=null
          this.$emit('close')
        })
      }
    }
}
</script>

<style scoped>

</style>