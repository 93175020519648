<template>
    <v-container>
        
        <CreateCategory class="mb-5 ml-0"/>
        <v-spacer></v-spacer>
        <v-card>
            <v-card-title>
                Question Categories
                <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
            </v-card-title>
            <v-data-table
            :headers="headers"
            :items="questionCategores"
            :search="search"
            >
            <template v-slot:item.actions="{ item }">
                <v-btn dark fab small color="primary" @click="showEditCategoryForm=true;categoryName=item.name;categoryIsActive=item.active_status;categoryUniqueId=item.actions">
                    <v-icon small>mdi-pen</v-icon>
                </v-btn>
            </template>
            <template v-slot:item.active_status="{ item }">
                <v-btn dark 
                v-if="item.active_status"
                        color="success"
                        depressed
                        rounded
                        x-small
                        class="text-capitalize">
                    {{ item.active_status }}
                </v-btn>
                <v-btn dark 
                v-else
                        color="error"
                        depressed
                        rounded
                        x-small
                        class="text-capitalize">
                    {{ item.active_status }}
                </v-btn>
            </template>
            </v-data-table>
        </v-card>
        <EditCategory :editCategoryVisible="showEditCategoryForm" :categoryIsActive="categoryIsActive" @closeEdit="showEditCategoryForm=false" :categoryUniqueId="categoryUniqueId" :categoryName="categoryName"/>
    </v-container>
</template>



<script>
import CreateCategory from './CreateCategory.vue'
import EditCategory from './EditQuestionCategory.vue'
import allQuestionCategories from '../../../../graphql/allQuestionCategories.gql'
export default {
    data:()=>({
        search: '',
        categoryIsActive:false,
        categoryUniqueId:'',
        categoryName:'',
        showEditCategoryForm:false,
        headers: [
        {
            text: 'Category Name',
            align: 'start',
            value: 'name',
        },
        { text: 'Active Status', value: 'active_status' },
        { text: 'Created Date', value: 'created_date' },
        { text: 'Actions', value: 'actions' },
        ],
        questionCategores: []
    }),
    components:{
        CreateCategory,
        EditCategory
    },mounted(){
        this.$apollo.query({
            query:allQuestionCategories
        }).then((response)=>{
            let questionCategories=response.data.allQuestionCategories.edges
            questionCategories.forEach(element => {
                this.questionCategores.push({'name':element.node.categoryName,'active_status':element.node.categoryIsActive,'created_date':new Date(element.node.categoryCreateddate).toString().substr(0,21),'actions':element.node.categoryUniqueId})

            });
        })
    }
}
</script>

<style>

</style>