<template>
<v-container fluid >
  
<v-row>
   <v-col sm="12" md="4" lg="3">
  <v-card
    class="mx-auto mt-5"
    max-width="100%"
    outlined
    elevation="2"
    shaped
    tile
    link to="">   
    <v-list-item three-line href="/latradts/question_bank">
      <v-list-item-content>
        <div class="text-overline h5 mb-4">
          <h1 style="color:#0e6499" >{{questionCategores}}</h1>
        </div>
        <v-list-item-title class="text-h5 mb-1">
          Total Category
        </v-list-item-title>
      </v-list-item-content>

      <v-list-item-avatar
        tile
        size="100"
        color="white">
      <v-img  link to="/" src="/chart.png"></v-img>
      </v-list-item-avatar>
    </v-list-item>

  </v-card>
   </v-col>
    <v-col sm="12" md="4" lg="3">
   <v-card
   class="mx-auto mt-5"
    max-width="100%"
    outlined
    elevation="2"
    shaped
    tile
    link>  
    <v-list-item three-line href="">
      <v-list-item-content>
        <div class="text-overline mb-4">
             <h1 style="color:#0e6499">{{questions}}</h1>
        </div>
        <v-list-item-title class="text-h5 mb-1">
            Questions
        </v-list-item-title>
      </v-list-item-content>

      <v-list-item-avatar
        tile
        size="100"
        color="white"
      ><v-img  link to="/" src="/pie.png"></v-img></v-list-item-avatar>
    </v-list-item>
</v-card>
    </v-col>
     <v-col sm="12" md="4" lg="3">
   <v-card
   class="mx-auto mt-5"
    max-width="100%"
    outlined
    elevation="2"
    shaped
    tile
    link>  
    <v-list-item three-line >
      <v-list-item-content>
        <div class="text-overline mb-4"> 
            <h1 style="color:#0e6499">{{askedquestions}}</h1>

        </div>
        <v-list-item-title class="text-h5 mb-1">
          Question Asked
        </v-list-item-title>
      </v-list-item-content>
       <v-col cols="auto">
      <v-dialog
        transition="dialog-top-transition"
        max-width="1000"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="white"
            v-bind="attrs"
            v-on="on"
            fab
            small
            href=""
          ></v-btn>
        </template>
        <template v-slot:default="dialog">
         <v-card>
            <v-toolbar
              color="orange"
              dark
            >Asked Questions</v-toolbar>
                <v-simple-table
                fixed-header
                height="400px"
                width="400px"
                >
                    <template v-slot:default>
                    <thead style="color:primary">
                        <tr >
                          <th>S/NO</th>
                        <th class="text-left">
                            Question Description
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="allquestions in allAskedQuestions" :key="allquestions" >
                          <td>{{serialNumber}}</td>
                        <td>{{allquestions.question}}</td>
                        </tr>

                    </tbody>
                    </template>
                </v-simple-table>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="dialog.value = false"
                fab
                shaped
                color="orange"
              ><v-icon>mdi-close-thick</v-icon></v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>

      <v-list-item-avatar
        tile
        size="100"
        color="white"
      >
      <v-img  link to="/" src="/charts.png"></v-img>
      </v-list-item-avatar>
    </v-list-item>

  </v-card>
     </v-col>
      <v-col sm="12" md="4" lg="3">
   <v-card
    class="mx-auto mt-5"
    max-width="100%"
    outlined
    elevation="2"
    shaped
    tile
    link
    
  >  
    <v-list-item three-line href="">
      <v-list-item-content>
        <div class="text-overline mb-4">
             <h1 style="color:#0e6499">{{questionNotAsked}}</h1>
        </div>
        <v-list-item-title class="text-h5 mb-1">
          Question Not Asked
        </v-list-item-title>
      </v-list-item-content>

      <v-list-item-avatar
        tile
        size="100"
        color="white"
      >
      <v-img  src="/growth.png"></v-img></v-list-item-avatar>
    </v-list-item>
  </v-card>
      </v-col>
</v-row>

  <v-row >
      <v-col sm="4" cols="4" xs="4" md="4">
      <v-card shaped hover>
          
        <questionReportCharts  :dataLabels="dataLabels" :dataValues="dataValues"/>
      </v-card>
      </v-col>
     <v-col sm="4" cols="4" xs="4" md="4">
         <v-card>
             <v-card-title class="mx-5" color="primary">Top 10 Appeared Questions<v-divider class="mx-2" color="orange" vertical></v-divider></v-card-title>
                <v-simple-table
                fixed-header
                height="400px"
                >
                    <template v-slot:default>
                    <thead style="color:primary">
                        <tr >
                        <th class="text-left">
                            Question Description
                        </th>
                        <th class="text-left">
                            Most Appeared
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="questions in mostappearedQuestions" :key="questions" >
                        <td>{{questions.question}}</td>
                        <td> 
                        {{questions.count}}
                        </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
             </v-card>
             
      </v-col>
        <v-col sm="4" cols="4" xs="4" md="4">
         <v-card shaped hover>
             <v-card-title class="mx-5"> Top 10 questions answered correctly <v-divider class="mx-2" color="orange" vertical></v-divider></v-card-title>
                <v-simple-table
                fixed-header
                height="400px"
                >
                    <template v-slot:default>
                    <thead>
                        <tr>
                        <th class="text-left">
                            Question Description
                        </th>
                        <th class="text-left">
                            Most Passed
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr
                      v-for="Correctquestions in mostCorrectQuestion" :key="Correctquestions"
                        >
                        <td>{{Correctquestions.question}}</td>
                        <td>  <v-chip
                        class="ma-2"
                        color="orange"
                        text-color="white"
                        filter
                        >
                        {{Correctquestions.count}}
                        </v-chip>
                        </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
             </v-card>
             
      </v-col>
  </v-row> 
      <v-row>

        <v-col sm="12" cols="12" xs="12" md="12">
         <v-card shaped hover>
             <v-card-title class="mx-5"> Top 10 questions answered Incorrectly  <v-divider class="mx-2" color="orange" vertical></v-divider></v-card-title>
                <v-simple-table fixed-header height="400px">
                    <template v-slot:default>
                    <thead>
                        <tr>
                        <th class="text-left">
                            Question Description
                        </th>
                        <th class="text-left">
                            Most Missed
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr
                       v-for="Missedquestions in mostMissedQuestion" :key="Missedquestions"
                        >
                        <td>{{Missedquestions.question}}</td>
                        <td>  <v-chip
                        class="ma-2"
                        color="orange"
                        text-color="white"
                        filter
                        >
                        {{Missedquestions.count}}
                        </v-chip>
                        </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
             </v-card>
             
      </v-col>
      </v-row>
      
</v-container>
</template>

<script>

import questionReportCharts from './latradts_charts/categoryWithMostQuestionsReport.vue'
  export default {
      data () {
      return {

      }
      },

    components: {
      questionReportCharts,
    },
    
    mounted(){
        this.$store.dispatch('Allquestion')
    },
    computed:{
      questionCategores: function(){
          return this.$store.state.ReportStore.questionCount.totalQuestionsCategories;
      },
       questions: function(){
            return this.$store.state.ReportStore.questionCount.totalQuestion;
        },
        
        askedquestions: function(){
          return this.$store.state.ReportStore.questionCount.totalAskedQuestions
        },
       serialNumber: function(){
          return this.$store.state.ReportStore.questionCount.serial_number
        },
        allAskedQuestions: function(){
              var allAskedQuestions = this.$store.state.ReportStore.questionCount.AskedQuestions
              return allAskedQuestions;
          },

        questionNotAsked: function() {
          return this.questions-this.askedquestions
        },

        dataLabels: function(){
            var labels=[]
            var mostQuestions = this.$store.state.ReportStore.questionCount.categoryWithMostQuestions
            mostQuestions.forEach(data => {
              labels.push(data.category)
            });
            return labels;
        },
        dataValues: function(){
            var values=[]
            var mostQuestions = this.$store.state.ReportStore.questionCount.categoryWithMostQuestions
            mostQuestions.forEach(data => {
              values.push(data.count)
            });
            return values;
        },
        mostappearedQuestions: function(){
              var mostappearedQuestions = this.$store.state.ReportStore.questionCount.mostAppearedQuestions
              return mostappearedQuestions;
          },  
        mostCorrectQuestion: function(){
          var mostCorrectQuestion = this.$store.state.ReportStore.questionCount.mostCorrectQuestions
          return mostCorrectQuestion;
        }, 
        mostMissedQuestion: function(){
          var mostMissedQuestion = this.$store.state.ReportStore.questionCount.mostMissedQuestions
          return mostMissedQuestion;
        }, 
      },
  }

</script>
